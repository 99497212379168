import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { LinkTo } from '@ember/routing';
export default class InventoryBagItem extends Component<InventoryBagItemComponentSignature> {
    static{
        template(`
    <div class='inventory-item-preview'>
      <div class='product-preview small'>
        <div class='product-preview-img-wrap'>
          <LinkTo @route='news.post' @model={{@slug}}>
            <figure class='product-preview-img liquid'>
              <img alt='{{@title}}' src='{{@image}}' />
            </figure>
          </LinkTo>
        </div>
        <div class='bubble-ornament small red remove'>
          <svg class='cross-cb-icon small'>
            <use href='#svg-cross-cb-small'></use>
          </svg>
        </div>
        <div class='tag-quantity'>
          <p class='tag-quantity-text'>x <span class='value'>{{@quantity}}</span></p>
        </div>
        <LinkTo @route='news.post' @model={{@slug}} class='product-preview-title'>
          {{@title}}
        </LinkTo>
        <div class='product-preview-info'>
          <div class='rating-ornament small void yellow'>
            <div class='rating-ornament-item'>
              <svg class='rating-ornament-icon'>
                <use href='#svg-star'></use>
              </svg>
            </div>
            <div class='rating-ornament-item'>
              <svg class='rating-ornament-icon'>
                <use href='#svg-star'></use>
              </svg>
            </div>
            <div class='rating-ornament-item'>
              <svg class='rating-ornament-icon'>
                <use href='#svg-star'></use>
              </svg>
            </div>
            <div class='rating-ornament-item'>
              <svg class='rating-ornament-icon'>
                <use href='#svg-star'></use>
              </svg>
            </div>
            <div class='rating-ornament-item'>
              <svg class='rating-ornament-icon'>
                <use href='#svg-star'></use>
              </svg>
            </div>
          </div>
        </div>
        <div class='product-price-wrap'>
          <p class='product-preview-price current'><span class='currency'>\$</span>{{@price}}</p>
        </div>
      </div>
    </div>
    <div class='inventory-item-preview empty'>
      <svg class='inventory-icon'>
        <use href='#svg-inventory-light'></use>
      </svg>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
interface InventoryBagItemComponentSignature {
    Element: HTMLDivElement;
    Args: {
        slug: string;
        title: string;
        rating: number;
        price: number;
        image: string;
        quantity: number;
    };
    Blocks: {
    };
}
