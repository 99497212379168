import { template } from "@ember/template-compiler";
export default template(`
  <div class='live-news-widget-wrap'>
    <div class='live-news-widget grid-limit'>
      <div class='live-news-widget-stairs left red'>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
      </div>
      <div class='live-news-widget-stairs right blue'>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
        <div class='live-news-widget-stair'></div>
      </div>
      <div class='live-news-widget-title-wrap'><img
          class='live-news-widget-icon'
          src='/img/icons/live-news-icon.png'
          alt='live-news-icon'
        />
        <!-- <p class='live-news-widget-title'>Live News</p> -->
      </div>
      <div id='lineslide-wrap1' class='live-news-widget-text-wrap'><p class='live-news-widget-text'></p></div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
