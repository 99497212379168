import { createClient } from '@supabase/supabase-js';
import type { SupabaseClient } from '@supabase/supabase-js';
import config from 'embroil/config/environment';

const supabase: SupabaseClient = createClient(
  config.supabaseUrl,
  config.supabaseKey,
  { auth: { flowType: 'pkce', storageKey: config.authStorageKey } },
);

supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_OUT') {
    window.location.href = config.baseUrl;
  }
});

export default supabase;

declare module '@supabase/supabase-js' {
  interface Registry {
    supabase: SupabaseClient;
  }
}
