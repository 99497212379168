import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import EsportsNewsComponent from '../../components/layout/news/esports-news';
import LiveMatchPlayer from '../../components/esports/tournament/live-match-player';
import RecentMatchPlayer from '../../components/esports/tournament/recent-match-player';
import UpcomingMatches from '../../components/esports/tournament/upcoming-matches';
import TournamentStandings from '../../components/esports/tournament/standings';
import PlayDaysOverview from '../../components/esports/tournament/play-days-overview';
import TournamentSidebar from '../../components/esports/tournament/sidebar';
import TournamentBracket from '../../components/esports/tournament/bracket';
import EsportsMatchResultsBanner from '../../components/layout/banner/esports-match-results';
import { pageTitle } from 'ember-page-title';
let EsportsTournamentComponent = class EsportsTournamentComponent extends Component {
    get upcomingMatches() {
        return this.args.model.matches.items.filter((match1)=>{
            return match1.isLive === false && match1.isFinal === false;
        });
    }
    get liveMatch() {
        return this.args.model.isLive;
    }
    get recentMatches() {
        return this.args.model.matches.items.filter((match1)=>{
            return match1.isFinal === true;
        });
    }
    get recentMatch() {
        return this.recentMatches[0];
    }
    static{
        template(`
    {{pageTitle @model.title}}
    <BannerComponent @theme='e-sport' @title={{@model.title}} />
    <LiveNewsTicker />
    <EsportsMatchResultsBanner />
    <div class='layout-content-1 layout-item grid-limit no-padding-bottom'>
      <div class='layout-body layout-item gutter-big'>
        {{#if this.liveMatch}}
          <LiveMatchPlayer @match={{@model}} />
        {{else if this.recentMatches}}
          <RecentMatchPlayer @match={{this.recentMatch}} />
        {{/if}}
        {{#if @model.isFinal}}
          <PlayDaysOverview />
        {{else}}
          <UpcomingMatches @matches={{this.upcomingMatches}} />
        {{/if}}
        <TournamentStandings @tournament={{@model}} />
      </div>
      <TournamentSidebar @tournament={{@model}} />
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsTournamentComponent);
