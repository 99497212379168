import { modifier } from 'ember-modifier';
import XMSlider from '../helpers/xm-slider';

export default modifier(
  (element, [], { stopAtEnd = false, slideGap = 0, slideCount = 1 }) => {
    let id = `#${element.id}`;
    let itemsContainer = new XMSlider({
      sliderContainer: id,
      itemsContainer: `${id}-items`,
      rosterContainer: false,
      autoSlide: false,
      animation: {
        type: 'carousel',
        orientation: 'horizontal',
        transition: 'direct',
        slideDelay: 7000,
        slideTransition: 1000,
        slideGap,
        slideCount,
      },
      controls: {
        container: `${id}-controls`,
        disabledClass: 'disabled',
      },
      stopAtEnd,
    });

    return () => {};
  },
);
