import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_TEAM_QUERY } from '../../gql/queries/esports-team';
export default class Tournament extends Route {
    async model({ team_slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_TEAM_QUERY,
                {
                    variables: {
                        slug: team_slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod.team.items[0];
    }
}
