import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../../helpers/format-date';
import MatchScore from './match-score';
export default template(`
  <div class='tab-wrap'>
    <div class='tab-body'>
      <div class='tab-item'>
        <div class='widget-match-box'>
          <div class='widget-title-wrap full'>
            <h6 class='widget-title'>5v5</h6>
            <p class='widget-text'>{{formatDate @match.scheduledDate}}</p>
          </div>
          <div class='widget-team-vs v2 medium centered'>
            <div class='widget-team-vs-item'>
              <LinkTo @route='esports.team' @model='{{@teamA.slug}}'>

                <div class='team-info-wrap medium'>
                  <img class='team-logo medium' src={{@teamA.logo.url}} alt='{{@teamA.name}}' />

                  <div class='team-info'>
                    <p class='team-name'>{{@teamA.name}}</p>
                    <p class='team-country'>{{@teamA.country}}</p>
                  </div>
                </div>
              </LinkTo>
            </div>
            <div class='match-stats'>
              <!-- TODO: make match stats component and a watch query to keep it live updated -->
              <p class='match-score medium'>
                <MatchScore @teamA={{@teamA}} @teamB={{@teamB}} @rounds={{@match.rounds.items}} />
              </p>
              {{#if @match.isFinal}}
                <p class='match-time medium'>Final</p>
              {{/if}}
            </div>
            <div class='widget-team-vs-item'>
              <LinkTo @route='esports.team' @model='{{@teamB.slug}}'>

                <div class='team-info-wrap medium inverted'>
                  <img class='team-logo medium' src={{@teamB.logo.url}} alt='{{@teamB.name}}' />
                  <div class='team-info'>
                    <p class='team-name'>{{@teamB.name}}</p>
                    <p class='team-country'>{{@teamB.country}}</p>
                  </div>
                </div>
              </LinkTo>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
