import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import EsportsPostComponent from '../../components/layout/news/esports-post';
import { pageTitle } from 'ember-page-title';
let EsportsNewsPostComponent = class EsportsNewsPostComponent extends Component {
    static{
        template(`
    {{pageTitle @model.title}}
    <LiveNewsTicker />
    <EsportsPostComponent @post={{@model}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsNewsPostComponent);
