import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { formatDate } from '../../../helpers/format-date';
import breev from '../../../helpers/breev';
import MatchScore from '../../esports/match/match-score';
export default class RelatedMatchComponent extends Component {
    get teamA() {
        return this.args.match.teams.items[0];
    }
    get teamB() {
        return this.args.match.teams.items[1];
    }
    static{
        template(`
    <div class='section-title-wrap violet'>
      <h2 class='section-title medium'>{{breev @match.title}}</h2>
      <div class='section-title-separator'></div>
      <LinkTo @route='esports.matches.match' @model={{@match.slug}} class='decorated-link'>
        <svg class='bars-icon'>
          <use href='#svg-bars'></use>
        </svg>
        Full Stats
      </LinkTo>
    </div>
    <div class='widget-match-box multiple-items inverted'>
      <div class='widget-team-vs v2 medium'>
        <div class='widget-team-vs-item'>
          <div class='team-info-wrap medium'>
            <img class='team-logo medium' src='{{this.teamA.logo.url}}' alt='{{this.teamA.name}}' />
            <div class='team-info'>
              <p class='team-name'>{{this.teamA.name}}</p>
              <p class='team-country'>{{this.teamA.country}}</p>
            </div>
          </div>
        </div>
        <div class='match-stats'>
          <p class='match-score medium solo'>
            <MatchScore @rounds={{@match.rounds.items}} @teamA={{this.teamA}} @teamB={{this.teamB}} />
          </p>
        </div>
        <div class='widget-team-vs-item'>
          <div class='team-info-wrap medium inverted'>
            <img class='team-logo medium' src='{{this.teamB.logo.url}}' alt='{{this.teamB.name}}' />
            <div class='team-info'>
              <p class='team-name'>{{this.teamB.name}}</p>
              <p class='team-country'>{{this.teamB.country}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-info-box'>
        <div class='widget-info-box-item'>
          <p class='widget-info-title'>{{breev @match.title}}</p>
          <p class='widget-info-text'>{{formatDate @match.scheduledDate}}</p>
        </div>
        <div class='widget-info-box-item'>
          <p class='widget-info-title'>{{@match.victor.name}}</p>
          <p class='widget-info-text'>Victor</p>
        </div>
        <div class='widget-info-box-item'>
          <p class='button-replay popup-watch-video-trigger decorated-link'>
            <LinkTo @route='esports.matches.match' @model={{@match.slug}}>
              <svg class='play-button-icon tiny'>
                <use href='#svg-play'></use>
              </svg>
              Watch Replay
            </LinkTo>
          </p>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
