import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
export default class MatchScore extends Component {
    get currentScore() {
        const victories1 = this.args.rounds.map((round1)=>{
            return round1?.victor?.slug;
        });
        let teamAScore1 = 0;
        let teamBScore1 = 0;
        victories1.forEach((victor1)=>{
            if (victor1 === this.args.teamA.slug) {
                teamAScore1++;
            } else if (victor1 === this.args.teamB.slug) {
                teamBScore1++;
            }
        });
        return {
            a: teamAScore1,
            b: teamBScore1
        };
    }
    static{
        template(`
    {{this.currentScore.a}} - {{this.currentScore.b}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
