import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_TOURNAMENT_QUERY } from '../../gql/queries/esports-tournament';
export default class Tournament extends Route {
    async model({ tournament_slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_TOURNAMENT_QUERY,
                {
                    variables: {
                        slug: tournament_slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod?.tournament?.items[0];
    }
}
