import { template } from "@ember/template-compiler";
import SmiteTeamLineup from '../smite/team-lineup';
import SmitePlayerBuilds from '../smite/player-builds';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
const eq = (a1, b1)=>a1 === b1;
export default class MatchDetails extends Component {
    @tracked
    selected = 0;
    @action
    setSelected(idx1) {
        this.selected = idx1;
    }
    static{
        template(`
    <div class='layout-content-full v2 grid-limit layout-item gutter-big'>
      <div class='tab-wrap'>
        <div class='tab-header items-2'>
          <div class='tab-header-item {{if (eq this.selected 0) "selected"}}' {{on 'click' (fn this.setSelected 0)}}>
            <p class='tab-header-item-text'>Team Lineups</p>
          </div>
          <div class='tab-header-item {{if (eq this.selected 1) "selected"}}' {{on 'click' (fn this.setSelected 1)}}>
            <p class='tab-header-item-text'>Player Builds</p>
          </div>
        </div>
      </div>
      {{#if (eq this.selected 0)}}
        <SmiteTeamLineup @match={{@match}} @teamA={{@teamA}} @teamB={{@teamB}} />
      {{/if}}
      {{#if (eq this.selected 1)}}
        <SmitePlayerBuilds @match={{@match}} @teamA={{@teamA}} @teamB={{@teamB}} />
      {{/if}}
      <div class='section-title-separator'></div>

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
