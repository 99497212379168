import { modifier } from 'ember-modifier';
import XMDropdown from '../packages/xm-dropdown';

export default modifier((element, [selector]) => {
  let id = `#${element.id}`;
  const dropdown = new XMDropdown({
    dropdownSelector: id,
    dropdownHandler: `${id}-trigger`,
    offset: {
      top: 34,
      left: -10,
    },
    selectable: true,
    options: {
      container: '.dp-option',
      current: `${id}-option-value`,
    },
  });

  return () => {
    element.remove();
  };
});
