import { template } from "@ember/template-compiler";
import { pageTitle } from 'ember-page-title';
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
let EsportsRouteComponent = class EsportsRouteComponent extends Component {
    static{
        template(`
    {{pageTitle 'eSports'}}
    {{outlet}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsRouteComponent);
