import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap cyan'><h2 class='section-title medium'>Featured Video</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='post-preview-showcase grid-1col centered'>
      <div class='post-preview video'><a href='/ggtv/video-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'><img src='/img/posts/03.jpg' alt='post-03' /></figure>
            <div class='post-preview-overlay'>
              <div class='play-button'>
                <svg class='play-button-icon'>
                  <use href='#svg-play'></use>
                </svg>
              </div>
              <div class='post-preview-overlay-info'><p class='post-preview-title'>"The Sandbenders II" break the bad
                  sequel spell with a...</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod.</p></div>
            </div>
          </div>
        </a></div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
