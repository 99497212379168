import { modifier } from 'ember-modifier';
import XMDropdown from '../packages/xm-dropdown';

export default modifier((element, [selector]) => {
  const mobileMenu = document.querySelector('.mobile-menu-wrap'),
    mobileMenuOpen = document.querySelector('.mobile-menu-open'),
    mobileMenuClose = document.querySelector('.mobile-menu-close');

  if (mobileMenu && mobileMenuOpen && mobileMenuClose) {
    const openMobileMenu = function () {
      mobileMenu.classList.add('open');
    };
    const closeMobileMenu = function () {
      mobileMenu.classList.remove('open');
    };
    mobileMenuOpen.addEventListener('click', openMobileMenu);
    mobileMenuClose.addEventListener('click', closeMobileMenu);
    /**
     *  MOBILE MENU DROPDOWNS
     */
    // $('.pd-dropdown-handler').on('click', function () {
    //   $(this).parent().toggleClass('active');
    //   $(this).siblings('.pd-dropdown').slideToggle(600);
    // });
  }
});
