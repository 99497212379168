export const articleType = (type: string): string => {
  if (type === 'ESportsArticle') {
    return 'esports';
  }
  if (type === 'GameArticle') {
    return 'news';
  }
  if (type === 'ReviewArticle') {
    return 'reviews';
  }
  return '';
};

export default articleType;
export const eq = (a: any, b: any) => a === b;
