import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import arc from '../../../modifiers/arc';
import { LinkTo } from '@ember/routing';
import { articleType, eq } from '../../../helpers/article-type';
export default class TopNewsBox extends Component {
    get first() {
        return this.args.articles[0];
    }
    get remaining() {
        let articles1 = Array.from(this.args.articles);
        articles1.shift();
        return articles1;
    }
    static{
        template(`
    <div class='layout-item'>
      <div class='post-preview-set'>
        <div class='post-preview-set-left'>
          <div
            class='post-preview picture big
              {{if (eq (articleType this.first.__typename) "esports") "e-sport"}}
              {{if (eq (articleType this.first.__typename) "reviews") "game-review"}}
              {{if (eq (articleType this.first.__typename) "news") "gaming-news"}}'
          >
            <LinkTo @route='{{articleType this.first.__typename}}.post' @model={{this.first.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{this.first.coverImage.url}}' alt='{{this.first.title}}' />
                </figure>
                <div class='post-preview-overlay'>
                  <span class='tag-ornament'>
                    {{if (eq (articleType this.first.__typename) 'esports') 'eSports'}}
                    {{if (eq (articleType this.first.__typename) 'reviews') 'Game Reviews'}}
                    {{if (eq (articleType this.first.__typename) 'news') 'Gaming News'}}
                  </span>
                  <p class='post-preview-title'>{{this.first.title}}</p>
                  <p class='post-preview-text'>{{this.first.shortDescription}}</p>
                </div>
                {{#if (eq (articleType this.first.__typename) 'reviews')}}
                  <div class='review-rating'>
                    <div id='content-news-rate-1' class='arc medium' {{arc 8.7 'lg'}}></div>
                  </div>
                {{/if}}
              </div>
            </LinkTo>
          </div>
        </div>
        <div class='post-preview-set-right'>
          {{#each this.remaining as |article|}}
            <div
              class='post-preview picture
                {{if (eq (articleType article.__typename) "esports") "e-sport"}}
                {{if (eq (articleType article.__typename) "reviews") "game-review"}}
                {{if (eq (articleType article.__typename) "news") "gaming-news"}}'
            >
              <LinkTo @route='{{articleType article.__typename}}.post' @model={{article.slug}}>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='{{article.coverImage.url}}' alt='{{article.title}}' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <p class='post-preview-title'>{{article.title}}</p>
                  </div>
                  <span class='tag-ornament'>
                    {{if (eq (articleType article.__typename) 'esports') 'eSports'}}
                    {{if (eq (articleType article.__typename) 'news') 'Gaming News'}}
                  </span>
                </div>
              </LinkTo>
            </div>
          {{/each}}

        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const old = template(`
  <div class='post-preview picture game-review'>
    <a href='/reviews/review-article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/25.webp' alt='post-25' />
        </figure>
        <div class='post-preview-overlay'>
          <p class='post-preview-title'>"Legend of Kenshi II" is a bit green for now</p>
        </div>
        <span class='tag-ornament'>Game reviews</span>
        <div class='review-rating'>
          <div id='content-news-rate-2' class='arc small' {{arc 6.4 'sm'}}></div>
        </div>
      </div>
    </a></div>
  <div class='post-preview picture geeky-news'>
    <a href='post-v4.html'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/18.webp' alt='post-18' /></figure>
        <div class='post-preview-overlay'><p class='post-preview-title'>"Ichigo Idol" anime will have a new season in
            April</p></div>
        <span class='tag-ornament'>Geeky news</span></div>
    </a></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
