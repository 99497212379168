const breev = (longName: string = ''): string => {
  return longName
    .split(' ')
    .map((word: string) => {
      // @ts-ignore
      return word ? word.split('')[0].toUpperCase() : '';
    })
    .join('')
    .trim();
};
export default breev;
