import { template } from "@ember/template-compiler";
import { formatDate } from '../../../helpers/format-date';
import { articleType, eq } from '../../../helpers/article-type';
import { LinkTo } from '@ember/routing';
export default template(`
  <div
    class='slider-item'
    ...attributes
    style='background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("{{@article.coverImage.url}}") no-repeat center;
  background-size: cover'
  >
    <div class='slider-item-wrap'>
      <div
        class='post-preview huge centered
          {{if (eq (articleType @article.__typename) "esports") "e-sport"}}
          {{if (eq (articleType @article.__typename) "news") "gaming-news"}}'
      >
        <a href='/news/article-slug' class='tag-ornament'>Gaming news</a>
        <LinkTo @route='{{articleType @article.__typename}}.post' @model={{@article.slug}} class='post-preview-title'>
          {{@article.title}}
        </LinkTo>
        <div class='post-author-info-wrap'>
          <a href='/search'>
            <figure class='user-avatar tiny liquid'>
              <img src='{{@article.author.profilePicture.url}}' alt='{{@article.author.displayName}}' />
            </figure>
          </a>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>{{@article.author.displayName}}</a>
            <span class='separator'>|</span>
            {{formatDate @article.publishedDate}}
          </p>
        </div>
        <div class='break'></div>
        <LinkTo
          @route='{{articleType @article.__typename}}.post'
          @model={{@article.slug}}
          class='button
            {{if (eq (articleType @article.__typename) "esports") "violet"}}
            {{if (eq (articleType @article.__typename) "news") "blue"}}'
        >
          Go to the article
          <div class='button-ornament'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <svg class='cross-icon small'>
              <use href='#svg-cross-small'></use>
            </svg>
          </div>
        </LinkTo>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
