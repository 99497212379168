import Route from '@ember/routing/route';
import { service } from '@ember/service';
import * as Swetrix from 'swetrix';

export default class Application extends Route {
  @service headData;
  @service metrics;
  @service router;

  constructor() {
    super(...arguments);

    this.metrics.trackViews();

    // this.router.on('routeDidChange', () => {
    //   const page = this.router.currentURL;
    //   const title = this.router.currentRouteName || 'unknown';
    //
    //   this.metrics.trackPage({ page, title });
    // });
  }

  afterModel() {
    this.headData.title = '1vX.gg';
    this.headData.description = 'The Best Way To Watch eSports.';
    this.headData.image =
      'https://images.ctfassets.net/g4p7iq2vydf4/2VYmtDpsYdXCQLgbqgallM/234186ae8459a9bacfba5d99b5af88a9/logo.png';
    this.headData.canonicalURL = 'https://1vx.gg/';
    this.headData.pageType = 'website';
    this.headData.keywords =
      'esports,games,gaming,streaming,streamers,smite,league of legends';
  }
}
