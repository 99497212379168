import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import arc from '../../modifiers/arc';
import PlayerSocialLinks from '../../components/esports/player/player-social-links';
import CareerStats from '../../components/esports/player/career-stats';
import breev from '../../helpers/breev';
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../helpers/format-date';
import { pageTitle } from 'ember-page-title';
const eq = (a1, b1)=>{
    return a1 === b1;
};
let EsportsPlayerComponent = class EsportsPlayerComponent extends Component {
    get recentMatch() {
        return this.args.model?.currentTeam?.recentMatch?.items[0];
    }
    get teamA() {
        return this.recentMatch.teams.items[0];
    }
    get teamB() {
        return this.recentMatch.teams.items[1];
    }
    get victor() {
        return this.args.model?.currentTeam?.recentMatch?.items[0] ? this.args.model?.currentTeam?.recentMatch?.items[0].victor?.slug : '';
    }
    victoryLabel = (slug1)=>{
        if (this.victor) {
            return eq(slug1, this.victor) ? 'Victory' : 'Defeat';
        }
        return '';
    };
    get currentScore() {
        const recentMatch1 = this.args.model?.currentTeam?.recentMatch?.items[0] || [];
        const victories1 = recentMatch1.rounds?.items.map((round1)=>{
            return round1?.victor?.slug;
        }) || [];
        let teamAScore1 = 0;
        let teamBScore1 = 0;
        victories1.map((victor1)=>{
            if (victor1 === this.teamA.slug) {
                teamAScore1++;
            } else if (victor1 === this.teamB.slug) {
                teamBScore1++;
            }
        });
        return {
            a: teamAScore1,
            b: teamBScore1
        };
    }
    static{
        template(`
    {{pageTitle @model.gamertag}}
    <BannerComponent @theme='e-sport' @title='{{@model.gamertag}}' />
    <LiveNewsTicker />
    <div class='layout-content-full v2 grid-limit layout-item gutter-big'>
      <div class='widget-item'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Player Overview</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='widget-player-overview'>
          <div class='player-overview'>
            <div class='player-overview-img-wrap'>
              <img class='player-overview-img' src='{{@model.photo.url}}?w=300' alt='player-photo' />
            </div>
            <div class='overview-info-wrap'>
              <div class='overview-info'>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Nickname</p>
                  <p class='overview-info-item-text'>{{@model.gamertag}}</p>
                </div>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Team</p>
                  <p class='overview-info-item-text centered'>
                    <img class='team-logo' src='{{@model.currentTeam.logo.url}}' alt='logo-01' />
                    {{@model.currentTeam.name}}
                  </p>
                </div>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Real Name</p>
                  <p class='overview-info-item-text'>{{@model.irlName}}</p>
                </div>
              </div>
              <div class='overview-info'>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Games Played</p>
                  <p class='overview-info-item-text'>557 games</p>
                </div>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Games Won</p>
                  <p class='overview-info-item-text'>82 Victories</p>
                </div>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Games Lost</p>
                  <p class='overview-info-item-text'>12 Losses</p>
                </div>
              </div>
              <div class='overview-info'>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>Tournaments</p>
                  <p class='overview-info-item-text'>24</p>
                </div>
                <div class='overview-info-item'>
                  <p class='overview-info-item-title'>MVP's Awarded</p>
                  <p class='overview-info-item-text'>37</p>
                </div>
                <div class='overview-info-item'>
                  <div class='social-links'>
                    <PlayerSocialLinks @links={{@model.socialLinks.items}} />
                  </div>
                </div>
              </div>
              <div class='stats-info'>
                <div class='stats-info-item'>
                  <p class='stats-info-item-value'>78.43<span class='percent'>%</span></p>
                  <div class='stats-info-item-description'>
                    <p class='stats-info-item-description-title'>Win Rate</p>
                    <p class='stats-info-item-description-text'>Competitive</p>
                  </div>
                </div>
                <div class='stats-info-item'>
                  <p class='stats-info-item-value'><span class='highlight'>90</span>
                    /
                    <span class='highlight'>30</span>
                    /
                    <span class='highlight'>6</span></p>
                  <div class='stats-info-item-description'>
                    <p class='stats-info-item-description-title'>Record</p>
                    <p class='stats-info-item-description-text'>Won/Lost/Draw</p>
                  </div>
                </div>
                <div class='stats-info-item'>
                  <p class='stats-info-item-value'>4790</p>
                  <div class='stats-info-item-description'>
                    <p class='stats-info-item-description-title'>Skill Rating</p>
                    <p class='stats-info-item-description-text'>Competitive</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='tab-wrap'>
          <div class='tab-header items-5'>
            <div class='tab-header-item selected'>
              <p class='tab-header-item-text'>All Streams</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>First Plays</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Multiplayer</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Story Mode</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Retro Games</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='layout-content-1 layout-item-3-1 grid-limit no-padding-bottom'>
      <CareerStats />
      <div class='layout-sidebar layout-item gutter-medium'>
        {{#if this.recentMatch}}
          <div class='widget-sidebar'>
            <div class='section-title-wrap violet small-space'>
              <h2 class='section-title medium'>Latest Match</h2>
              <div class='section-title-separator'></div>
            </div>
            <div class='widget-match-box'>
              <div class='widget-title-wrap'>
                <h6 class='widget-title'>
                  <LinkTo @route='esports.matches.match' @model={{this.recentMatch.slug}} style='color: #7442ce'>
                    {{breev this.recentMatch.title}}
                  </LinkTo>
                </h6>
                <p class='widget-text'>{{formatDate this.recentMatch.scheduledDate}}</p>
              </div>
              <div class='widget-team-vs'>
                <div class='widget-team-vs-item'>
                  <div class='team-info-wrap medium'>
                    <LinkTo @route='esports.team' @model={{this.teamA.slug}}>
                      <img class='team-logo' src='{{this.teamA.logo.url}}' alt='{{this.teamA.name}}' />
                    </LinkTo>
                    <div class='team-info'>
                      <p class='team-name'>{{this.teamA.name}}</p>
                      <p class='team-country'>{{this.victoryLabel this.teamA.slug}}</p>
                    </div>
                  </div>
                  <p class='team-score'>{{this.currentScore.a}}</p>
                </div>
                <div class='widget-team-vs-item'>
                  <div class='team-info-wrap medium'>
                    <LinkTo @route='esports.team' @model={{this.teamB.slug}}>
                      <img class='team-logo' src='{{this.teamB.logo.url}}' alt='{{this.teamB.name}}' />
                    </LinkTo>
                    <div class='team-info'>
                      <p class='team-name'>{{this.teamB.name}}</p>
                      <p class='team-country'>{{this.victoryLabel this.teamB.slug}}</p>
                    </div>
                  </div>
                  <p class='team-score'>{{this.currentScore.b}}</p>
                </div>
              </div>
            </div>
            <div class='widget-match-box'>
              <div class='widget-simple-stats v2 small'>
                <div class='widget-simple-stat'>
                  <div class='widget-simple-stat-header'>
                    <p class='widget-simple-stat-header-title'>{{@model.gamertag}}'s Stats</p>
                  </div>
                  <div class='widget-simple-stat-rows'>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Damage Inflicted</p>
                      <p class='widget-simple-stat-value'>56.364</p>
                    </div>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Damage Received</p>
                      <p class='widget-simple-stat-value'>38.204</p>
                    </div>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Eliminations</p>
                      <p class='widget-simple-stat-value'>47</p>
                    </div>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Deaths</p>
                      <p class='widget-simple-stat-value'>9</p>
                    </div>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Team Damage</p>
                      <p class='widget-simple-stat-value'>43%</p>
                    </div>
                    <div class='widget-simple-stat-row'>
                      <p class='widget-simple-stat-title'>Most Played Hero</p>
                      <div class='team-info-wrap small inverted'>
                        <div class='hexagon hexagon-wrap small light'>
                          <div class='hexagon-in1'>
                            <div class='hexagon-in2 hexagon-wrap'>
                              <div class='hexagon inner-hexagon'>
                                <div class='hexagon-in1'>
                                  <div class='hexagon-in2 hexagon-area liquid'>
                                    <img src='/img/characters/05.png' alt='character-05' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {{/if}}
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Medals Awarded</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-medals multiple-items'>
            <img class='widget-medals-img' src='/img/teams/awards/medal-all.png' alt='medal-all' />
            <div class='widget-medals-info'>
              <p class='widget-medals-title'>447</p>
              <p class='widget-medals-text'>Total medals</p>
            </div>
            <div class='widget-medals-info'>
              <p class='widget-medals-title'>3.15</p>
              <p class='widget-medals-text'>Average</p>
            </div>
          </div>
          <div class='widget-medals'>
            <div class='medal-list'>
              <div class='medal-list-item'>
                <img class='medal-list-item-img' src='/img/teams/awards/medal-gold-big.png' alt='medal-gold' />
                <div class='medal-list-item-info'>
                  <p class='medal-list-item-title'>228</p>
                  <p class='medal-list-item-text'>1.61 avg</p>
                </div>
              </div>
              <div class='medal-list-item'>
                <img class='medal-list-item-img' src='/img/teams/awards/medal-silver-big.png' alt='medal-silver' />
                <div class='medal-list-item-info'>
                  <p class='medal-list-item-title'>115</p>
                  <p class='medal-list-item-text'>0.81 avg</p>
                </div>
              </div>
              <div class='medal-list-item'>
                <img class='medal-list-item-img' src='/img/teams/awards/medal-bronze-big.png' alt='medal-bronze' />
                <div class='medal-list-item-info'>
                  <p class='medal-list-item-title'>104</p>
                  <p class='medal-list-item-text'>0.73 avg</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Team Bio</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='bio-items'>
            <div class='bio-item'>
              <p class='bio-item-title'>Founding The Lone Wolves</p>
              <p class='bio-item-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna. Sed ut perspiciatis unde omnis iste natus error sit
                voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
                veritatis et quasi architecto beatae vitae dicta sunt explicabo en der lorem ipsum dolorem.</p>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Heroes Win Rate</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='table champion-rates'>
            <div class='table-row-header'>
              <div class='table-row-header-item left'>
                <p class='table-row-header-title'>Hero/Role</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Win Rate</p>
              </div>
            </div>
            <div class='table-rows no-color'>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <div class='hexagon hexagon-wrap small violet'>
                      <div class='hexagon-in1'>
                        <div class='hexagon-in2 hexagon-wrap'>
                          <div class='hexagon inner-hexagon'>
                            <div class='hexagon-in1'>
                              <div class='hexagon-in2 hexagon-area liquid'>
                                <img src='/img/characters/04.png' alt='character-04' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='team-info'>
                      <p class='team-name'>Vanstus</p>
                      <p class='team-country'>TANK</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div id='t-cr-pgb-01' class='progress-bar link-left'></div>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <div class='hexagon hexagon-wrap small red'>
                      <div class='hexagon-in1'>
                        <div class='hexagon-in2 hexagon-wrap'>
                          <div class='hexagon inner-hexagon'>
                            <div class='hexagon-in1'>
                              <div class='hexagon-in2 hexagon-area liquid'>
                                <img src='/img/characters/05.png' alt='character-05' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='team-info'>
                      <p class='team-name'>Galium</p>
                      <p class='team-country'>OFFENSE</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div id='t-cr-pgb-02' class='progress-bar link-left'></div>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <div class='hexagon hexagon-wrap small blue'>
                      <div class='hexagon-in1'>
                        <div class='hexagon-in2 hexagon-wrap'>
                          <div class='hexagon inner-hexagon'>
                            <div class='hexagon-in1'>
                              <div class='hexagon-in2 hexagon-area liquid'>
                                <img src='/img/characters/07.png' alt='character-07' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='team-info'>
                      <p class='team-name'>Rekson</p>
                      <p class='team-country'>DEFENSE</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div id='t-cr-pgb-03' class='progress-bar link-left'></div>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <div class='hexagon hexagon-wrap small cyan'>
                      <div class='hexagon-in1'>
                        <div class='hexagon-in2 hexagon-wrap'>
                          <div class='hexagon inner-hexagon'>
                            <div class='hexagon-in1'>
                              <div class='hexagon-in2 hexagon-area liquid'>
                                <img src='/img/characters/10.png' alt='character-10' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='team-info'>
                      <p class='team-name'>Magina</p>
                      <p class='team-country'>SUPPORT</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div id='t-cr-pgb-04' class='progress-bar link-left'></div>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <div class='hexagon hexagon-wrap small cyan'>
                      <div class='hexagon-in1'>
                        <div class='hexagon-in2 hexagon-wrap'>
                          <div class='hexagon inner-hexagon'>
                            <div class='hexagon-in1'>
                              <div class='hexagon-in2 hexagon-area liquid'>
                                <img src='/img/characters/12.png' alt='character-12' />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='team-info'>
                      <p class='team-name'>Duvida</p>
                      <p class='team-country'>SUPPORT</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div id='t-cr-pgb-05' class='progress-bar link-left'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsPlayerComponent);
