import { template } from "@ember/template-compiler";
import DynamicTag from './dynamic-tag';
import Component from '@glimmer/component';
import { element } from 'ember-element-helper';
const log = (...args1)=>console.log(...args1);
const eq = (a1, b1)=>a1 === b1;
const nodeTypeMap = {
    paragraph: 'p',
    'heading-1': 'h1',
    'heading-2': 'h2',
    'heading-3': 'h3',
    'heading-4': 'h4',
    'heading-5': 'h5'
};
const classNameMap = {
    p: 'post-open-text',
    h1: 'post-open-subtitle',
    h2: 'post-open-subtitle',
    h3: 'post-open-subtitle',
    h4: 'post-open-subtitle',
    h5: 'post-open-subtitle',
    blockquote: 'quote-text'
};
export default class ContentfulBody extends Component {
    get tags() {
        const content1 = this.args.content.json.content;
        return content1.map((x1)=>{
            console.log(x1);
            if (x1.content) {
                if (x1.nodeType in nodeTypeMap) {
                    if (x1.content.length === 1) {
                        return {
                            text: x1.content[0].value,
                            tag: nodeTypeMap[x1.nodeType]
                        };
                    } else {
                        let text1 = '';
                        x1.content.forEach((y1)=>{
                            if (y1.nodeType === 'text') {
                                text1 += y1.value;
                            } else if (y1.nodeType === 'hyperlink') {
                                text1 += `<a class="" href="${y1.data.uri}">${y1.content[0].value}</a>`;
                            }
                        });
                        return {
                            text: text1,
                            tag: nodeTypeMap[x1.nodeType]
                        };
                    }
                }
                if (x1.nodeType === 'blockquote') {
                    if (x1.content[0].nodeType === 'paragraph') {
                        return {
                            text: x1.content[0].content[0].value,
                            tag: 'blockquote'
                        };
                    }
                }
                if (x1.nodeType === 'unordered-list') {
                    const elements1 = x1.content.map((y1)=>{
                        if (y1.nodeType === 'list-item') {
                            return {
                                text: y1.content[0].content[0].value,
                                tag: 'li'
                            };
                        }
                    });
                    return {
                        text: '',
                        tag: 'ul',
                        elements: elements1
                    };
                }
            }
        });
    }
    cssClassForTag(tag1) {
        return classNameMap[tag1] || '';
    }
    static{
        template(`
    {{#each this.tags as |node|}}
      {{#if (eq node.tag 'blockquote')}}
        <div class='post-open-quote'>
          <DynamicTag @tagName={{node.tag}} class='{{this.cssClassForTag node.tag}}' @text={{node.text}} />
        </div>
      {{else if (eq node.tag 'ul')}}
        <div class='bullet-list'>
          {{#each node.elements as |element|}}
            <div class='bullet-list-item'>
              <div class='bullet-list-item-icon'>
                <svg class='check-icon'>
                  <use xlink:href='#svg-check'></use>
                </svg>
              </div>
              <p class='bullet-list-item-text'>
                {{element.text}}
              </p>
            </div>
          {{/each}}
        </div>
      {{else}}
        <DynamicTag @tagName={{node.tag}} class='{{this.cssClassForTag node.tag}}' @text={{node.text}} />
      {{/if}}
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const CONTENT = {
    json: {
        nodeType: 'document',
        data: {},
        content: [
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'The highly anticipated Smite 2 has been officially revealed, promising players a true sequel that goes beyond just a larger season or a balance pass. Developed from the ground up in Unreal Engine 5, Smite 2 represents a two-generation leap from the original Smite, which first debuted in 2006.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Next-Gen Features and Visual Enhancements',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Smite 2 boasts numerous improvements, leveraging dynamic lighting, physically based rendering, cloth simulation, higher polygon counts, and more. The goal is to enhance visuals across the board while preserving the core feel of the beloved Smite experience. Players can expect improvements in animation effects, environment art, and hundreds of subtle touches to create a more immersive world.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Diverse Roster and All-New Gods',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: "The game continues to feature a diverse roster of gods, updated for Unreal Engine 5. Some gods, like Loki, maintain their essence while receiving visual enhancements, while others like Anubis showcase new animations and abilities. Smite 2 introduces a new god, Hecate's, the Greek goddess of sorcery, fulfilling a long-standing community request. Players can expect an elegant play style with Hecate's ranged magical abilities and unique effects.",
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Technical Advancements and Full Crossplay',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Smite 2 promises technical superiority over its predecessor, with a new backend supporting millions of concurrent users. It will support full crossplay across platforms, including PC, Steam Deck, Xbox Series X and S, and PlayStation 5.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Enhanced Combat Experience',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'The combat experience in Smite 2 is described as strictly better than ever. Weapon movements leave trails, attacks are more visible, and animations are more synchronized. Clear communication of crowd control status and immunity is integrated into name plates, ensuring players are fully engaged in the flow of combat.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Overhauled Item System',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Smite 2 introduces a revamped item system, doing away with relics in their traditional form. Each god now has two default buttons, purification beads for crowd control immunity, and a warding slot for vision control. The item store is redesigned for easier navigation, allowing more creativity and experimentation in building items.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Divine Legacy and Legacy Gems',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'While content from Smite 1 will not directly transfer to Smite 2 due to the significant leap in technology, players will be rewarded through the Divine Legacy system. Legacy gems earned in Smite 1 can be used to pay for 50% of in-game purchases in Smite 2, including events, skins, and battle passes. Smite 2 Founders Packs will offer double legacy gems, giving players additional purchasing power.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'Esports Transition and Smite 2 Founders Series',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'The transition to Smite 2 in esports includes the Smite 2 Founders Series, featuring live Majors and region-based rivalries. Year one of Smite 2 Esports will witness at least one major in the United States and a return of competitive Smite to Europe and the UK.',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'heading-2',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: ' Community Engagement and Testing',
                        marks: [
                            {
                                type: 'bold'
                            }
                        ],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: "The developers emphasize community involvement, seeking feedback from professionals, content creators, and players through early testing. The alpha testing phase is set to begin in spring, with players encouraged to register for alpha codes on Smite 2's official website.",
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: 'In conclusion, Smite 2 appears to be a comprehensive evolution of the beloved Smite franchise, offering next-gen features, improved visuals, enhanced combat, and a reimagined item system. With a commitment to community engagement and rewards for loyal players, Smite 2 is poised to usher in a new era for this iconic ',
                        marks: [],
                        data: {}
                    },
                    {
                        nodeType: 'hyperlink',
                        data: {
                            uri: 'https://1vX.gg/'
                        },
                        content: [
                            {
                                nodeType: 'text',
                                value: 'MOBA.',
                                marks: [],
                                data: {}
                            }
                        ]
                    },
                    {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {}
                    }
                ]
            },
            {
                nodeType: 'blockquote',
                data: {},
                content: [
                    {
                        nodeType: 'paragraph',
                        data: {},
                        content: [
                            {
                                nodeType: 'text',
                                value: 'We are very proud to be one of the largest gaming industries in the world and we can assure that the new game will release before 2016',
                                marks: [],
                                data: {}
                            }
                        ]
                    }
                ]
            },
            {
                nodeType: 'unordered-list',
                data: {},
                content: [
                    {
                        nodeType: 'list-item',
                        data: {},
                        content: [
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'Ul ',
                                        marks: [],
                                        data: {}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        nodeType: 'list-item',
                        data: {},
                        content: [
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'with',
                                        marks: [],
                                        data: {}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        nodeType: 'list-item',
                        data: {},
                        content: [
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'some ',
                                        marks: [],
                                        data: {}
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        nodeType: 'list-item',
                        data: {},
                        content: [
                            {
                                nodeType: 'paragraph',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'text',
                                        value: 'lis',
                                        marks: [],
                                        data: {}
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                nodeType: 'table',
                data: {},
                content: [
                    {
                        nodeType: 'table-row',
                        data: {},
                        content: [
                            {
                                nodeType: 'table-header-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'pros',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                nodeType: 'table-header-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'cons',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        nodeType: 'table-row',
                        data: {},
                        content: [
                            {
                                nodeType: 'table-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'yes',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                nodeType: 'table-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'I dont know',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        nodeType: 'table-row',
                        data: {},
                        content: [
                            {
                                nodeType: 'table-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'No',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                nodeType: 'table-cell',
                                data: {},
                                content: [
                                    {
                                        nodeType: 'paragraph',
                                        data: {},
                                        content: [
                                            {
                                                nodeType: 'text',
                                                value: 'probably',
                                                marks: [],
                                                data: {}
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                nodeType: 'paragraph',
                data: {},
                content: [
                    {
                        nodeType: 'text',
                        value: '',
                        marks: [],
                        data: {}
                    }
                ]
            }
        ]
    }
};
