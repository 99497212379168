import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { service } from '@ember/service';
import NewsSubmenu from './news-submenu';
import EsportsSubmenu from './esports-submenu';
import AccountHeader from './account-header';
import { LinkTo } from '@ember/routing';
import MobileNav from './mobile-nav';
export default class TopNavComponent extends Component {
    @service
    router;
    get isHomePage() {
        return this.router.currentRouteName === 'index';
    }
    get isStoreOpen() {
        return false;
    }
    get cartQuantity() {
        return 0;
    }
    get isReviewsOpen() {
        return false;
    }
    get isGGtvOpen() {
        return false;
    }
    static{
        template(`
    <AccountHeader />
    <MobileNav @isMobileMenuOpen={{@isMobileMenuOpen}} @openSearch={{@openSearch}} />
    <nav class='navigation-wrap {{if this.isHomePage "stick-on-top"}}'>
      <div class='navigation grid-limit'>
        <div class='logo'>
          <div class='logo-text'>
            <LinkTo @route='application'>
              <h2 class='logo-title'>1vX.<span class='highlight'>gg</span></h2>
            </LinkTo>
            <p class='logo-info'>Gaming and eSports News</p>
          </div>
        </div>
        {{#if this.isStoreOpen}}
          <div class='inventory-button' {{on 'click' (fn @openBag true)}}>
            <svg class='inventory-icon'>
              <use href='#svg-inventory'></use>
            </svg>
            {{#if this.cartQuantity}}
              <div class='inventory-item-quantity-wrap'>
                <p class='inventory-item-quantity'>2</p>
              </div>
            {{/if}}
          </div>
        {{/if}}
        <div class='search-button search-popup-open' {{on 'click' (fn @openSearch true)}}>
          <svg class='search-icon'>
            <use href='#svg-search'></use>
          </svg>
        </div>
        <ul class='main-menu'>
          <li class='main-menu-item'>
            <LinkTo @route='index' class='main-menu-item-link'>Home</LinkTo>
          </li>
          <li class='main-menu-item'>
            <LinkTo @route='news' class='main-menu-item-link'>
              News
              <!-- <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg> -->
            </LinkTo>
            <!-- <NewsSubmenu /> -->
          </li>
          {{#if this.isReviewsOpen}}
            <li class='main-menu-item'>
              <LinkTo @route='reviews' class='main-menu-item-link'>Reviews</LinkTo>
            </li>
          {{/if}}
          <li class='main-menu-item'>
            <LinkTo @route='esports' class='main-menu-item-link'>
              eSports
              <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg>
            </LinkTo>
            <EsportsSubmenu />
          </li>
          {{#if this.isGGtvOpen}}
            <li class='main-menu-item'>
              <LinkTo @route='ggtv' class='main-menu-item-link'>GG<span class='highlight'>tv</span></LinkTo>
            </li>
          {{/if}}
        </ul>
      </div>
    </nav>
    <div class='mobile-menu-pull mobile-menu-open' {{on 'click' (fn @openMobileMenu true)}}>
      <svg class='menu-pull-icon'>
        <use href='#svg-menu-pull'></use>
      </svg>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
