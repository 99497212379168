import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import NewsSliderMain from '../components/layout/news-slider/main';
import LiveNewsTicker from '../components/layout/live-news-ticker';
import SubscribeBanner from '../components/layout/subscribe-banner';
import SocialComponent from '../components/widget/sidebar/social';
import PopularPosts from '../components/widget/sidebar/popular-posts';
import LatestReviews from '../components/widget/sidebar/latest-reviews';
import LiveStreams from '../components/widget/sidebar/live-streams';
import LatestComments from '../components/widget/sidebar/latest-comments';
import PixelPoll from '../components/widget/sidebar/pixel-poll';
import FeaturedVideo from '../components/widget/sidebar/featured-video';
import TagCloud from '../components/widget/sidebar/tag-cloud';
import Calendar from '../components/widget/sidebar/calendar';
import TopNewsBox from '../components/widget/main/top-news-box';
import ArticlesList from '../components/widget/main/articles-list';
import LiveStreamCountdown from '../components/widget/main/live-stream-countdown';
import LatestVideos from '../components/widget/main/latest-videos';
import ArticlesByCategoryList from '../components/widget/main/article-category-list';
import Search from '../components/widget/sidebar/search';
let IndexRouteComponent = class IndexRouteComponent extends Component {
    static{
        template(`
    <NewsSliderMain @articles={{@model.newsSlider}} />
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 search-pad grid-limit'>
      <div class='layout-body layout-item centered'>
        <TopNewsBox @articles={{@model.topNews}} />
        <ArticlesList @articles={{@model.articles}} />
        <!--<ArticlesByCategoryList /> -->
        <!-- <LiveStreamCountdown /> -->
        <!-- <LatestVideos /> -->
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <Search />
        <PopularPosts @articles={{@model.popularPosts}} />
        <LiveStreams />
      </div>
    </div>
    <SubscribeBanner />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(IndexRouteComponent);
