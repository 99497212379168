import { modifier } from 'ember-modifier';
import XMProgressBar from '../packages/xm-progress';

export default modifier((element, [percent], { barText, type }) => {
  new XMProgressBar({
    container: `#${element.id}`,
    width: 300,
    height: 22,
  });
  new XMProgressBar({
    container: `#${element.id}`,
    width: 300,
    height: 22,
    scale: {
      start: 0,
      end: 10,
      stop: percent,
    },
    speed: 30,
    gradient: {
      colors: ['#5216fd', '#ff055d'],
    },
    animateOnScroll: true,
    decimalPoints: 1,
    linkUnits: false,
    linkText: true,
    barText,
  });
});

// new XMProgressBar({
//   container: '#po-pbg-6',
//   width: 300,
//   height: 22,
// });
// new XMProgressBar({
//   container: '#po-pbg-6',
//   width: 300,
//   height: 22,
//   scale: {
//     start: 0,
//     end: 10,
//     stop: 9.2,
//   },
//   speed: 30,
//   gradient: {
//     colors: ['#5216fd', '#ff055d'],
//   },
//   animateOnScroll: true,
//   decimalPoints: 1,
//   linkUnits: false,
//   linkText: true,
//   barText: 'OVERALL STORY',
// });
// new XMProgressBar({
//   container: '#po-pbg-7',
//   width: 300,
//   height: 22,
// });
// new XMProgressBar({
//   container: '#po-pbg-7',
//   width: 300,
//   height: 22,
//   scale: {
//     start: 0,
//     end: 10,
//     stop: 5.8,
//   },
//   speed: 30,
//   gradient: {
//     colors: ['#5216fd', '#ff055d'],
//   },
//   animateOnScroll: true,
//   decimalPoints: 1,
//   linkUnits: false,
//   linkText: true,
//   barText: 'ORIGINALITY',
// });
// new XMProgressBar({
//   container: '#po-pbg-8',
//   width: 300,
//   height: 22,
// });
// new XMProgressBar({
//   container: '#po-pbg-8',
//   width: 300,
//   height: 22,
//   scale: {
//     start: 0,
//     end: 10,
//     stop: 3.4,
//   },
//   speed: 30,
//   gradient: {
//     colors: ['#5216fd', '#ff055d'],
//   },
//   animateOnScroll: true,
//   decimalPoints: 1,
//   linkUnits: false,
//   linkText: true,
//   barText: 'MULTIPLAYER & ONLINE',
// });
