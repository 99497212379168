import { template } from "@ember/template-compiler";
export default template(`
  <div class='footer-top-brand'>
    <div class='logo negative'>
      <!-- <figure class='logo-img'>
        <img src='/img/star.png' alt='Logo' />
      </figure>-->
      <div class='logo-text'>
        <h2 class='logo-title'>
          1vX.<span class='highlight'>gg</span>
        </h2>
        <p class='logo-info'>Gaming and eSports News</p></div>
    </div>
    <style>
      .bubble-ornament.discord { background-color: #5865F2; } .bubble-ornament.discord:after { border-top-color:
      #5865F2; } .bubble-ornament.discord > .discord-icon {position: relative; top: 9px; }
    </style>
    <div class='social-links'>
      <a href='https://discord.1vx.gg/' target='_blank' class='bubble-ornament discord youtube'>
        <svg class='discord-icon'>
          <use href='#svg-discord'></use>
        </svg>
      </a>
      <a href='https://twitter.com/1vxgg' target='_blank' class='bubble-ornament twt'>
        <svg class='twitter-icon'>
          <use href='#svg-twitter'></use>
        </svg>
      </a>
      <a href='https://www.instagram.com/1vx.gg/' target='_blank' class='bubble-ornament insta'>
        <svg class='instagram-icon'>
          <use href='#svg-instagram'></use>
        </svg>
      </a>
      <a href='https://twitch.tv/1vXgg' target='_blank' class='bubble-ornament twitch'>
        <svg class='twitch-icon'>
          <use href='#svg-twitch'></use>
        </svg>
      </a>
      <a href='https://www.youtube.com/@1vXgg' target='_blank' class='bubble-ornament youtube'>
        <svg class='youtube-icon'>
          <use href='#svg-youtube'></use>
        </svg>
      </a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
