import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-item'>
    <div class='section-title-wrap violet small-space'>
      <h2 class='section-title medium'>Play Days Overview</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='tab-wrap'>
      <div class='tab-header items-4'>
        <div class='tab-header-item'>
          <p class='tab-header-item-text'>Play Day 1</p>
        </div>
        <div class='tab-header-item selected'>
          <p class='tab-header-item-text'>Play Day 2</p>
        </div>
        <div class='tab-header-item'>
          <p class='tab-header-item-text'>Play Day 3</p>
        </div>
        <div class='tab-header-item'>
          <p class='tab-header-item-text'>Play Day 4</p>
        </div>
      </div>
      <div class='tab-body'>
        <div class='tab-item'>
          <div class='grid1col-2col centered'>
            <div class='table standings'>
              <div class='table-row-header'>
                <div class='table-row-header-item position'>
                  <p class='table-row-header-title'>Standings</p>
                </div>
                <div class='table-row-header-item padded'>
                  <p class='table-row-header-title'>W</p>
                </div>
                <div class='table-row-header-item padded'>
                  <p class='table-row-header-title'>L</p>
                </div>
              </div>
              <div class='table-rows'>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>01</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                      <div class='team-info'>
                        <p class='team-name'>The Lone Wolves</p>
                        <p class='team-country'>United States</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>02</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                      <div class='team-info'>
                        <p class='team-name'>Raging Rhinos</p>
                        <p class='team-country'>South Africa</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>03</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                      <div class='team-info'>
                        <p class='team-name'>Los Toreros</p>
                        <p class='team-country'>Spain</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>04</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                      <div class='team-info'>
                        <p class='team-name'>Siberian Tigers</p>
                        <p class='team-country'>Russia</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>05</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                      <div class='team-info'>
                        <p class='team-name'>Crimson Pack</p>
                        <p class='team-country'>Japan</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>06</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/06.png' alt='logo-06' />
                      <div class='team-info'>
                        <p class='team-name'>Toxic Bulls</p>
                        <p class='team-country'>Brazil</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>07</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/08.png' alt='logo-08' />
                      <div class='team-info'>
                        <p class='team-name'>Maple Wolves</p>
                        <p class='team-country'>Canada</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item position'>
                    <p class='table-text'>08</p>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/07.png' alt='logo-07' />
                      <div class='team-info'>
                        <p class='team-name'>Bengal Tigers</p>
                        <p class='team-country'>India</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>0</p>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text bold'>1</p>
                  </div>
                </div>
              </div>
            </div>
            <div class='table matches hoverable'>
              <div class='table-row-header'>
                <div class='table-row-header-item left'>
                  <p class='table-row-header-title'>Results</p>
                </div>
                <div class='table-row-header-item'></div>
                <div class='table-row-header-item'></div>
                <div class='table-row-header-item'>
                  <p class='table-row-header-title'>Video</p>
                </div>
              </div>
              <div class='table-rows'>
                <div class='table-row'>
                  <div class='table-row-item'>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                      <div class='team-info'>
                        <p class='team-name'>Siberian Tigers</p>
                        <p class='team-country'>Russia</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='match-stats'>
                      <p class='match-score small'>3-0</p>
                      <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='team-info-wrap inverted'>
                      <img class='team-logo small' src='/img/teams/logos/06.png' alt='logo-06' />
                      <div class='team-info'>
                        <p class='team-name'>Toxic Bulls</p>
                        <p class='team-country'>Brazil</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text-link popup-watch-video-trigger'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                      <span class='link-text'>Watch replay</span>
                    </p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item'>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                      <div class='team-info'>
                        <p class='team-name'>The Lone Wolves</p>
                        <p class='team-country'>United States</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='match-stats'>
                      <p class='match-score small'>2-1</p>
                      <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='team-info-wrap inverted'>
                      <img class='team-logo small' src='/img/teams/logos/07.png' alt='logo-07' />
                      <div class='team-info'>
                        <p class='team-name'>Bengal Tigers</p>
                        <p class='team-country'>India</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text-link popup-watch-video-trigger'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                      <span class='link-text'>Watch replay</span>
                    </p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item'>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                      <div class='team-info'>
                        <p class='team-name'>Los Toreros</p>
                        <p class='team-country'>Spain</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='match-stats'>
                      <p class='match-score small'>1-4</p>
                      <a href='#' class='match-link'>Full Stats</a>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='team-info-wrap inverted'>
                      <img class='team-logo small' src='/img/teams/logos/08.png' alt='logo-08' />
                      <div class='team-info'>
                        <p class='team-name'>Maple Wolves</p>
                        <p class='team-country'>Canada</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text-link popup-watch-video-trigger'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                      <span class='link-text'>Watch replay</span>
                    </p>
                  </div>
                </div>
                <div class='table-row'>
                  <div class='table-row-item'>
                    <div class='team-info-wrap'>
                      <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                      <div class='team-info'>
                        <p class='team-name'>Crimson Pack</p>
                        <p class='team-country'>Japan</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='match-stats'>
                      <p class='match-score small'>0-3</p>
                      <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <div class='team-info-wrap inverted'>
                      <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                      <div class='team-info'>
                        <p class='team-name'>Raging Rhinos</p>
                        <p class='team-country'>South Africa</p>
                      </div>
                    </div>
                  </div>
                  <div class='table-row-item'>
                    <p class='table-text-link popup-watch-video-trigger'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                      <span class='link-text'>Watch replay</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
