import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import EsportsBanner from '../../components/layout/banner/esports';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import EsportsNewsComponent from '../../components/layout/news/esports-news';
import EsportsMatchResultsBanner from '../../components/layout/banner/esports-match-results';
let EsportsIndexComponent = class EsportsIndexComponent extends Component {
    static{
        template(`
    <EsportsBanner />
    <LiveNewsTicker />
    <EsportsMatchResultsBanner />
    <EsportsNewsComponent @articles={{@model}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsIndexComponent);
