import { template } from "@ember/template-compiler";
import arc from '../../modifiers/arc';
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../helpers/format-date';
const eq = (a1, b1)=>a1 === b1;
const sum = (a1, b1)=>a1 + b1;
export default template(`
  {{#if (eq @type 'gaming-news')}}
    <div class='post-preview large gaming-news'>
      <LinkTo @route='news.post' @model={{@post.slug}}>
        <div class='post-preview-img-wrap'>
          <figure class='post-preview-img liquid'>
            <img src='{{@post.coverImage.url}}' alt='{{@post.title}}' />
          </figure>
        </div>
      </LinkTo>
      <a href='/news/article-slug' class='tag-ornament'>Gaming news</a>
      <LinkTo @route='news.post' @model={{@post.slug}} class='post-preview-title'>{{@post.title}}</LinkTo>
      <div class='post-author-info-wrap'>
        <a href='/search'>
          <figure class='user-avatar tiny liquid'>
            <img src='{{@post.author.profilePicture.url}}' alt='{{@post.author.displayName}}' />
          </figure>
        </a>
        <p class='post-author-info small light'>By
          <a href='/search' class='post-author'>{{@post.author.displayName}}</a>
          <span class='separator'>|</span>{{formatDate @post.publishedDate}}
        </p>
      </div>
      <p class='post-preview-text'>{{@post.shortDescription}}</p>
    </div>
  {{/if}}
  {{#if (eq @type 'game-review')}}
    <div class='post-preview landscape big game-review'>
      <a href='/reviews/review-article-slug'>
        <div class='post-preview-img-wrap'>
          <figure
            class='post-preview-img liquid'
            style='background-image: url("/img/posts/16.webp"); background-size: cover; background-position: center center; background-repeat: no-repeat;'
          >
            <img src='/img/posts/16.webp' alt='post-16' style='display:none' />
          </figure>
          <div class='review-rating'>
            <div id='content-news-rate-{{@index}}' class='arc' {{arc (sum 3.7 @index) 'md' shadow=false}}></div>
          </div>
        </div>
      </a>
      <a href='/reviews' class='tag-ornament'>Game Reviews</a>
      <a href='/reviews/review-article-slug' class='post-preview-title'>We reviewed the new Magimons game</a>
      <div class='post-author-info-wrap'>
        <a href='/search'>
          <figure class='user-avatar tiny liquid'>
            <img src='/img/users/04.jpg' alt='user-04' />
          </figure>
        </a>
        <p class='post-author-info small light'>By
          <a href='/search' class='post-author'>Vellatrix</a>
          <span class='separator'>|</span>December 15th, 2018
          <span class='separator'>|</span>
          <a href='/reviews/review-slug#op-comments' class='post-comment-count'>258 Comments</a>
        </p>
      </div>
      <p class='post-preview-text'>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt dutor et dolore magna
        aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris tempora aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore...
      </p>
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
