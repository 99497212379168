import { template } from "@ember/template-compiler";
const log = (...args1)=>{
    console.log(...args1);
};
const eq = (a1, b1)=>{
    return a1 === b1;
};
export default template(`
  {{#each @links as |link|}}
    {{#if (eq link.platform 'YouTube')}}
      <a href='{{link.url}}' target='_blank' class='bubble-ornament youtube'>
        <svg class='youtube-icon'>
          <use href='#svg-youtube'></use>
        </svg>
      </a>
    {{/if}}
    {{#if (eq link.platform 'Twitch')}}
      <a href='{{link.url}}' target='_blank' class='bubble-ornament twitch'>
        <svg class='twitch-icon'>
          <use href='#svg-twitch'></use>
        </svg>
      </a>
    {{/if}}
    {{#if (eq link.platform 'Twitter')}}
      <a href='{{link.url}}' target='_blank' class='bubble-ornament twt'>
        <svg class='twitter-icon'>
          <use href='#svg-twitter'></use>
        </svg>
      </a>
    {{/if}}
    {{#if (eq link.platform 'Instagram')}}
      <a href='{{link.url}}' target='_blank' class='bubble-ornament insta'>
        <svg class='instagram-icon'>
          <use href='#svg-instagram'></use>
        </svg>
      </a>
    {{/if}}
    {{#if (eq link.platform 'Facebook')}}
      <a href='{{link.url}}' target='_blank' class='bubble-ornament fb'>
        <svg class='facebook-icon'>
          <use href='#svg-facebook'></use>
        </svg>
      </a>
    {{/if}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
