import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import breev from '../../../helpers/breev';
import { formatDate } from '../../../helpers/format-date';
import { LinkTo } from '@ember/routing';
export default class MatchResult extends Component {
    get teamA() {
        return this.args.match.teams.items[0];
    }
    get teamB() {
        return this.args.match.teams.items[1];
    }
    get currentScore() {
        const victories1 = this.args.match.rounds.items.map((round1)=>{
            return round1?.victor?.slug;
        });
        let teamAScore1 = 0;
        let teamBScore1 = 0;
        victories1.forEach((victor1)=>{
            if (victor1 === this.teamA.slug) {
                teamAScore1++;
            } else if (victor1 === this.teamB.slug) {
                teamBScore1++;
            }
        });
        return {
            a: teamAScore1,
            b: teamBScore1
        };
    }
    static{
        template(`
    <div class='carousel-match-result-item'>
      <div class='widget-match-result hoverable'>
        <div class='widget-match-result-item'>
          <div class='team-info-wrap'>
            <img class='team-logo small' src='{{this.teamA.logo.url}}' alt='logo-03' />
            <div class='team-info'>
              <p class='team-name'>{{breev this.teamA.name}} </p>
              <p class='team-country'></p>
            </div>
          </div>
          <p class='match-score'>{{this.currentScore.a}}</p>
          {{#if @match.isLive}}
            <p class='match-datetime live'>LIVE</p>
          {{else if @match.isFinal}}
            <p class='match-datetime'>FINAL</p>
          {{else}}
            <p class='match-datetime'>{{formatDate @match.scheduledDate 'MM/DD'}}</p>
          {{/if}}
        </div>
        <div class='widget-match-result-item'>
          <div class='team-info-wrap'>
            <img class='team-logo small' src='{{this.teamB.logo.url}}' alt='logo-04' />
            <div class='team-info'>
              <p class='team-name'>{{breev this.teamB.name}}</p>
              <p class='team-country'></p>
            </div>
          </div>
          <p class='match-score'>{{this.currentScore.b}}</p>

          {{#if @match.isLive}}
            <p class='match-datetime light'>{{formatDate @match.scheduledDate 'hA'}}</p>

          {{else if @match.isFinal}}
            <p class='match-datetime light'>{{formatDate @match.scheduledDate 'MM/DD'}}</p>
          {{else}}
            <p class='match-datetime'>{{formatDate @match.scheduledDate 'hA'}}</p>
          {{/if}}
        </div>
        <div class='widget-match-result-actions'>
          <LinkTo @route='esports.matches.match' @model={{@match.slug}} class='widget-match-result-action'>
            <svg class='play-button-icon tiny'>
              <use href='#svg-play'></use>
            </svg>
            {{#if @match.isLive}}
              Watch Live
            {{else if @match.isFinal}}
              Replay
            {{else}}
              Watch Live
            {{/if}}
          </LinkTo>
          <!--<LinkTo @route='esports.matches.match' @model={{@match.slug}} class='widget-match-result-action'>
            <p class='widget-match-result-action popup-watch-video-trigger'>
              <svg class='bars-icon'>
                <use href='#svg-bars'></use>
              </svg>
              Stats
            </p>
          </LinkTo>-->
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
