import { template } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
const eq = (a1, b1)=>{
    return a1 === b1;
};
export default template(`
  {{#let (element @tagName) as |Tag|}}
    <Tag ...attributes>
      {{#if (eq @tagName 'li')}}
        <svg class='check-icon'>
          <use xlink:href='#svg-check'></use>
        </svg>
      {{/if}}
      {{{@text}}}
      {{yield}}
    </Tag>
  {{/let}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const other = template(`
  <DynamicTag @tagName='p' class='post-open-text'>
    <DynamicTag @tagName='ul' class='bullet-list'>
      <DynamicTag
        @tagName='li'
        @text='Dolorem ipsum quia dolor sit ametoru consectetur adipisicing elit, sed do eiusmod'
        class='bullet-list-item-text'
      />
      <DynamicTag
        @tagName='li'
        @text='Dolorem ipsum quia dolor sit ametoru consectetur adipisicing elit, sed do eiusmod'
        class='bullet-list-item'
      ><DynamicTag @tagName='p' class='bullet-list-item-text' /> </DynamicTag>
      <DynamicTag
        @tagName='li'
        @text='Dolorem ipsum quia dolor sit ametoru consectetur adipisicing elit, sed do eiusmod'
        class='bullet-list-item-text'
      />
    </DynamicTag>
  </DynamicTag>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
