import { gql } from 'glimmer-apollo';

export const REVIEWS_INDEX_QUERY = gql`
  query {
    articles: reviewArticleCollection(limit: 10, order: [publishedDate_DESC]) {
      items {
        title
        slug
        shortDescription
        publishedDate
        body {
          json
        }
        coverImage {
          url
        }
        author {
          displayName
          profilePicture {
            url
          }
        }
      }
    }
  }
`;
