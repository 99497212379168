import { modifier } from 'ember-modifier';
import XMProgressBar from '../packages/xm-progress';

export default modifier(
  (element, [leftNumber, rightNumber], { leftColor, rightColor }) => {
    let elementId = `#${element.id}`;
    let total = leftNumber + rightNumber;

    new XMProgressBar({
      container: elementId,
      width: 210,
      height: 6,
      lineColor: '#107df8',
      scale: {
        start: 0,
        end: total,
        stop: leftNumber,
      },
      linkText: true,
      linkUnits: false,
      linkTo: `${elementId}-left-stat`,
      buttEnd: true,
    });

    new XMProgressBar({
      container: elementId,
      width: 210,
      height: 6,
      lineColor: '#dee807',
      scale: {
        start: 0,
        end: total,
        stop: rightNumber,
      },
      linkText: true,
      linkUnits: false,
      linkTo: `${elementId}-right-stat`,
      reverse: true,
      buttEnd: true,
    });
  },
);
