import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { LinkTo } from '@ember/routing';
let StreamersRouteComponent = class StreamersRouteComponent extends Component {
    static{
        template(`
    <div class='layout-content-full grid-limit layout-item gutter-big'>
      <div class='widget-item'>
        <div class='widget-title-wrap full'>
          <h6 class='widget-title'>Choose your streamer!</h6>
          <p class='widget-text'>Check live, featured, and past streams</p>
        </div>
        <div class='streamer-avatars centered'>
          <div class='streamer-avatar'>
            <a href='#'>
              <div class='pp-hexagon'>
                <svg class='hexagon-icon hexagon-border'>
                  <use href='#svg-hexagon'></use>
                </svg>
                <svg class='hexagon-icon hexagon-fill'>
                  <use href='#svg-hexagon'></use>
                </svg>
              </div>
              <div class='streamer-avatar-img-wrap'>
                <img class='streamer-avatar-img' src='/img/streamers/streamer-hex-01.png' alt='st-avatar-01' />
              </div>
            </a>
          </div>
          <div class='streamer-avatar'>
            <a href='#'>
              <div class='pp-hexagon'>
                <svg class='hexagon-icon hexagon-border'>
                  <use href='#svg-hexagon'></use>
                </svg>
                <svg class='hexagon-icon hexagon-fill'>
                  <use href='#svg-hexagon'></use>
                </svg>
              </div>
              <div class='streamer-avatar-img-wrap'>
                <img class='streamer-avatar-img' src='/img/streamers/streamer-hex-02.png' alt='st-avatar-02' />
              </div>
            </a>
          </div>
          <div class='streamer-avatar'>
            <a href='#'>
              <div class='pp-hexagon active'>
                <svg class='hexagon-icon hexagon-border'>
                  <use href='#svg-hexagon'></use>
                </svg>
                <svg class='hexagon-icon hexagon-fill'>
                  <use href='#svg-hexagon'></use>
                </svg>
              </div>
              <div class='streamer-avatar-img-wrap'>
                <img class='streamer-avatar-img' src='/img/streamers/streamer-hex-03.png' alt='st-avatar-03' />
              </div>
            </a>
          </div>
          <div class='streamer-avatar'>
            <a href='#'>
              <div class='pp-hexagon'>
                <svg class='hexagon-icon hexagon-border'>
                  <use href='#svg-hexagon'></use>
                </svg>
                <svg class='hexagon-icon hexagon-fill'>
                  <use href='#svg-hexagon'></use>
                </svg>
              </div>
              <div class='streamer-avatar-img-wrap'>
                <img class='streamer-avatar-img' src='/img/streamers/streamer-hex-04.png' alt='st-avatar-04' />
              </div>
            </a>
          </div>
          <div class='streamer-avatar'>
            <a href='#'>
              <div class='pp-hexagon'>
                <svg class='hexagon-icon hexagon-border'>
                  <use href='#svg-hexagon'></use>
                </svg>
                <svg class='hexagon-icon hexagon-fill'>
                  <use href='#svg-hexagon'></use>
                </svg>
              </div>
              <div class='streamer-avatar-img-wrap'>
                <img class='streamer-avatar-img' src='/img/streamers/streamer-hex-05.png' alt='st-avatar-05' />
              </div>
            </a>
          </div>
        </div>
        <div class='widget-streamer-overview'>
          <div class='streamer-overview'>
            <div class='streamer-overview-img-wrap liquid'>
              <img class='streamer-overview-img' src='/img/streamers/streamer-main-image.jpg' alt='streamer-img' />
            </div>
            <div class='streamer-info'>
              <p class='streamer-info-name'>Game-Huntress</p>
              <p class='streamer-info-description'>Hunting the best games and consoles for your entertainment</p>
              <div class='social-links'>
                <a href='#' class='bubble-ornament fb'>
                  <svg class='facebook-icon'>
                    <use href='#svg-facebook'></use>
                  </svg>
                </a>
                <a href='#' class='bubble-ornament twitch'>
                  <svg class='twitch-icon'>
                    <use href='#svg-twitch'></use>
                  </svg>
                </a>
                <a href='#' class='bubble-ornament insta'>
                  <svg class='instagram-icon'>
                    <use href='#svg-instagram'></use>
                  </svg>
                </a>
              </div>
              <div class='overview-info-wrap'>
                <div class='overview-info'>
                  <div class='overview-info-item'>
                    <p class='overview-info-item-title'>Country</p>
                    <p class='overview-info-item-text'>
                      <img src='/img/flags/flag-us.png' alt='us-flag' />
                      United States
                    </p>
                  </div>
                </div>
                <div class='overview-info'>
                  <div class='overview-info-item'>
                    <p class='overview-info-item-title'>Favourite Genre</p>
                    <p class='overview-info-item-text'>Action / Adventure</p>
                  </div>
                </div>
                <div class='overview-info'>
                  <div class='overview-info-item'>
                    <p class='overview-info-item-title'>Favourite Game</p>
                    <p class='overview-info-item-text'>League of Heroes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='tab-wrap'>
          <div class='tab-header items-5'>
            <div class='tab-header-item selected'>
              <p class='tab-header-item-text'>All Streams</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>First Plays</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Multiplayer</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Story Mode</p>
            </div>
            <div class='tab-header-item'>
              <p class='tab-header-item-text'>Retro Games</p>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-item'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Featured Stream</h2>
          <div class='section-title-separator'></div>
        </div>
        <div id='streamer-twitch-channel-01'></div>
      </div>
      <div class='widget-item'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Browse Streams</h2>
          <div class='section-title-separator'></div>
          <div id='st-videos-slider-controls' class='carousel-controls slider-controls violet'>
            <div class='slider-control control-previous'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
            <div class='slider-control control-next'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
          </div>
        </div>
        <div id='st-videos-slider' class='carousel big st-video'>
          <div class='carousel-items'>
            <div class='carousel-item'>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=01h03m30s&video=v417567785'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>I'm playing the new "Dragons and Knights" game</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Story Mode</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=03h15m50s&video=v417077870'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>"Rizen Riders" arcade emulator nostalgic party!</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Retro Games</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=01h01m43s&video=v415202048'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>"Olympus" Multiplayer with Davikinger90!</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Multiplayer</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>I play chapter 2 of the "Mecha Cyborg" story mode</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Story Mode</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>"Guardians of the Universe" story - first part</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Story Mode</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>Trying the new "Flag Hunters" multiplayer mode</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Multiplayer</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>I take the first steps in the "Sandbenders" game</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>First Plays</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>Let's start playing "The Legend of Kenji" together!</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>First Plays</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
            </div>
            <div class='carousel-item'>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>"Legend of Kenji" walkthrough part 1</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Walkthroughs</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview e-sport'>
                <div class='post-preview-st-video'>
                  <iframe
                    src='https://player.twitch.tv/?autoplay=false&muted=true&t=05h53m27s&video=v414377682'
                    allowfullscreen
                  ></iframe>
                </div>
                <p class='post-preview-title'>"Legend of Kenji" walkthrough part 2</p>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'><a href='/search' class='post-author'>Walkthroughs</a><span
                      class='separator'
                    >|</span>December 15th, 2018</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(StreamersRouteComponent);
