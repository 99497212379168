import { gql } from 'glimmer-apollo';

export const ESPORTS_TEAM_QUERY = gql`
  query ($slug: String) {
    team: teamCollection(where: { slug: $slug }) {
      items {
        name
        country
        bioBlurb
        establishedDate
        logo {
          url
        }
        players: playersCollection(limit: 5) {
          items {
            gamertag
            irlName
            role
            slug
            photo {
              url(transform: { width: 220 })
              description
            }
            socialLinks: socialLinksCollection(limit: 5) {
              items {
                accessibilityDescription
                platform
                url
              }
            }
          }
        }
        tournaments: tournamentsCollection(limit: 5) {
          total
          items {
            title
            image {
              url
            }
            victor {
              slug
            }
          }
        }
      }
    }
  }
`;
