import { gql } from 'glimmer-apollo';

export const GET_HOME_PAGE = gql`
  query {
    homePage: featuredBlockCollection(where: { slug: "home-page" }, limit: 1) {
      items {
        articles: articlesCollection(limit: 8) {
          items {
            __typename
            ... on ESportsArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
    topNews: featuredBlockCollection(
      where: { slug: "top-news-box" }
      limit: 1
    ) {
      items {
        articles: articlesCollection(limit: 4) {
          items {
            __typename
            ... on ESportsArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
    newsSlider: featuredBlockCollection(
      where: { slug: "news-slider" }
      limit: 1
    ) {
      items {
        articles: articlesCollection(limit: 4) {
          items {
            __typename
            ... on ESportsArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
    popularPosts: featuredBlockCollection(
      where: { slug: "popular-posts" }
      limit: 1
    ) {
      items {
        articles: articlesCollection(limit: 4) {
          items {
            __typename
            ... on ESportsArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
