import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { fn } from '@ember/helper';
import intensify from '../../../modifiers/intensify';
export default class AccountHeaderComponent extends Component {
    @service
    auth;
    @service
    currentUser;
    @action
    async login(provider1, event1) {
        event1.preventDefault();
        try {
            await this.auth.signInWithProvider(provider1);
        } catch (err1) {
            console.log(err1);
        }
    }
    @action
    async logout() {
        await this.auth.logout();
    }
    static{
        template(`
    <div class='header-wrap'>
      <div class='header grid-limit'>
        <div class='widget-selectables'>
          <div class='widget-options-wrap'>
            <div class='current-option' id='lang-dropdown-trigger'>
              <div class='current-option-value' id='lang-dropdown-option-value'>
                <img alt='flag-us' class='widget-option-img' src='/img/flags/flag-us.png' />
                <p class='widget-option-text'>English</p>
              </div>
              <!-- <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg> -->
            </div>
            <div class='widget-options small' id='lang-dropdown'>
              <div class='widget-option'>
                <img alt='flag-us' class='widget-option-img' src='/img/flags/flag-us.png' />
                <p class='widget-option-text'>English</p>
              </div>
              <div class='widget-option'>
                <img alt='flag-es' class='widget-option-img' src='/img/flags/flag-es.png' />
                <p class='widget-option-text'>Spanish</p>
              </div>
              <div class='widget-option'>
                <img alt='flag-fr' class='widget-option-img' src='/img/flags/flag-fr.png' />
                <p class='widget-option-text'>French</p>
              </div>
              <div class='widget-option'>
                <img alt='flag-jp' class='widget-option-img' src='/img/flags/flag-jp.png' />
                <p class='widget-option-text'>Japanese</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-selectables'>
          {{#if this.currentUser.isLoggedIn}}
            <div class='widget-options-wrap'>
              <div class='current-option' id='account-dropdown-trigger'>
                <div class='current-option-value'>
                  <img
                    alt='avatar-01'
                    class='widget-option-img user-avatar micro'
                    src='{{this.currentUser.profilePhoto}}'
                  />
                  <p class='widget-option-text'>{{this.currentUser.displayName}}</p>
                </div>
                <svg class='arrow-icon'>
                  <use href='#svg-arrow'></use>
                </svg>
              </div>
              <div class='widget-options short linkable' id='account-dropdown'>
                <div class='widget-option-heading blue'>
                  <p class='widget-option-text'>Main Account</p>
                </div>
                <a class='widget-option' href='account-settings.html'>
                  <p class='widget-option-text'>Account Settings</p>
                </a>
                <a class='widget-option' href='profile-activity.html'>
                  <p class='widget-option-text'>Activity</p>
                </a>
                <a class='widget-option' href='profile-notifications.html'>
                  <p class='widget-option-text'>Notifications</p>
                </a>
                <a class='widget-option' href='profile-badges.html'>
                  <p class='widget-option-text'>Profile Badges</p>
                </a>
                <a class='widget-option' href='profile-settings.html'>
                  <p class='widget-option-text'>Settings</p>
                </a>
              </div>
            </div>
            <a class='button tiny blue log-button' {{on 'click' this.logout}}>
              Logout
              <div class='button-ornament'>
                <svg class='arrow-icon'>
                  <use href='#svg-arrow'></use>
                </svg>
              </div>
            </a>
          {{else}}
            <button class='button tiny twitch log-button' {{on 'click' (fn this.login 'twitch')}}>
              Login with Twitch
              <div class='button-ornament twitch'>
                <svg class='twitch-icon'>
                  <use href='#svg-twitch'></use>
                </svg>
              </div>
            </button>
            <button class='button tiny discord log-button' {{on 'click' (fn this.login 'discord')}}>
              Login with Discord
              <div class='button-ornament discord'>
                <svg class='discord-icon'>
                  <use href='#svg-discord'></use>
                </svg>
              </div>
            </button>
          {{/if}}
        </div>
      </div>
    </div>

    <style>
      button.tiny.twitch {background-color:#7442ce; margin-right: 5px;} .button-ornament.twitch { background-color:
      #7442ce;padding-right:10px; } button.tiny.discord {background-color:#5865F2;} .button-ornament.discord {
      background-color: #5865F2; padding-right:10px;}
    </style>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
