import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import mobileMenu from '../../../packages/mobile-menu';
import MobileMenuToggle from '../../../modifiers/mobile-menu-toggle';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
export default template(`
  <style>
    .mobile-dropdown.active { display: block; }
  </style>
  <div class='mobile-menu-wrap {{if @isMobileMenuOpen "open"}}' {{mobileMenu}}>
    <svg class='cross-icon big mobile-menu-close'>
      <use href='#svg-cross-big'></use>
    </svg>
    <svg class='search-popup-open search-icon' {{on 'click' @openSearch}}>
      <use href='#svg-search'></use>
    </svg>
    <figure class='logo-img'>
      <img alt='Logo' src='/img/brand/logo.png' />
    </figure>
    <ul class='mobile-menu'>
      <li class='mobile-menu-item'>
        <LinkTo @route='application' class='mobile-menu-item-link'>Home</LinkTo>
      </li>
      <li class='mobile-menu-item'>
        <LinkTo @route='news' class='mobile-menu-item-link'>News</LinkTo>
      </li>
      <li class='mobile-menu-item'>
        <LinkTo @route='reviews' class='mobile-menu-item-link'>Reviews</LinkTo>
      </li>
      <li class='mobile-menu-item'>
        <p class='mobile-menu-item-link pd-dropdown-handler' {{MobileMenuToggle}}>eSports</p>
        <svg class='arrow-icon medium'>
          <use href='#svg-arrow-medium'></use>
        </svg>
        <ul class='mobile-dropdown pd-dropdown'>
          <li class='mobile-dropdown-item'>
            <p class='mobile-dropdown-item-link pd-dropdown-handler' {{MobileMenuToggle}}>Main Pages</p>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <ul class='mobile-dropdown pd-dropdown'>
              <li class='mobile-dropdown-item'>
                <LinkTo @route='esports' class='mobile-dropdown-item-link'>eSports Home</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo @route='esports.game' @model='smite' class='mobile-dropdown-item-link'>Smite eSports News</LinkTo>
              </li>
            </ul>
          </li>
          <li class='mobile-dropdown-item'>
            <p class='mobile-dropdown-item-link pd-dropdown-handler' {{MobileMenuToggle}}>Teams &amp; Players</p>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <ul class='mobile-dropdown pd-dropdown'>
              <li class='mobile-dropdown-item'>
                <LinkTo @route='esports.team' @model='oni-warriors' class='mobile-dropdown-item-link'>Oni Warriors</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo @route='esports.team' @model='jade-dragons' class='mobile-dropdown-item-link'>Jade Dragons</LinkTo>
              </li>
            </ul>
          </li>
          <li class='mobile-dropdown-item'>
            <p class='mobile-dropdown-item-link pd-dropdown-handler' {{MobileMenuToggle}}>Recent Tournaments</p>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <ul class='mobile-dropdown pd-dropdown'>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.tournament'
                  @model='smite-world-championship-season-x'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX</LinkTo>
              </li>
            </ul>
          </li>
          <li class='mobile-dropdown-item'>
            <p class='mobile-dropdown-item-link pd-dropdown-handler' {{MobileMenuToggle}}>Recent Matches</p>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <ul class='mobile-dropdown pd-dropdown'>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-grand-finals'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: Grand Finals</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-semifinals-match-2'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: SF Match 2</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-semifinals-match-1'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: SF Match 1</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-4'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: QF Match 4</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-3'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: QF Match 3</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-2'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: QF Match 2</LinkTo>
              </li>
              <li class='mobile-dropdown-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-1'
                  class='mobile-dropdown-item-link'
                >Smite - SWCX: QF Match 1</LinkTo>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
