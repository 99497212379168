import { template } from "@ember/template-compiler";
import SlideItem from './slide-item';
import SlidePreview from './slide-preview';
import Component from '@glimmer/component';
import carousel from '../../../modifiers/carousel';
export default class NewsSliderComponent extends Component {
    get articles() {
        return this.args.articles;
    }
    static{
        template(`
    <style>
      .user-avatar.tiny.liquid { overflow: hidden; }
    </style>
    <div id='banner-slider-2' {{carousel}} class='banner-slider v2'>
      <div class='slider-items'>
        {{#each this.articles as |article index|}}
          <SlideItem @article={{article}} />
        {{/each}}
      </div>
      <div class='banner-slider-preview-wrap'>
        <div id='sliderb2-controls' class='banner-slider-controls'>
          <div class='control-previous'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
          </div>
          <div class='control-next'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
          </div>
        </div>
        <div id='banner-slider-2-thumbs' class='banner-slider-preview'>
          <div class='banner-slider-preview-roster'>
            {{#each this.articles as |article index|}}
              <SlidePreview @article={{article}} />
            {{/each}}
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
