import { setClient } from 'glimmer-apollo';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import supabase from './supabase';
const apiKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFwdGFleXFseXlqd29zdGlsaHZ5Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDI1MjI4NDQsImV4cCI6MjAxODA5ODg0NH0.4kV8iJTK632djAgq3qd499dE9k6Oki5ZyCgRhKTpxqk';

export default function setupApolloClient(context: object): void {
  const httpLink = createHttpLink({
    uri: 'https://graphql.contentful.com/content/v1/spaces/beelv7tx0ngc?access_token=AlIEb0kBjGQ3SGwmD1nj-P6GB6hosZCCAm4LmsISNv0',
  });
  const cache = new InMemoryCache();
  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
  });
  setClient(context, apolloClient);

  const supabaseHttpLink = createHttpLink({
    uri: 'https://qptaeyqlyyjwostilhvy.supabase.co/graphql/v1',
  });
  const authLink = setContext(async (_, { headers }) => {
    const accessToken = (await supabase.auth.getSession()).data.session
      ?.access_token;
    const bearer = `Bearer ${accessToken}`;
    return {
      headers: {
        ...headers,
        Authorization: accessToken ? bearer : '',
        apiKey: apiKey,
      },
    };
  });
  const supabaseApolloClient = new ApolloClient({
    link: authLink.concat(supabaseHttpLink),
    cache,
  });
  setClient(context, supabaseApolloClient, 'supabase-client');
}
