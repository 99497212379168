import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import vsProgress from '../../../modifiers/vs-progress';
export default template(`
  <ul class='submenu'>
    <li class='submenu-item grid-limit'>
      <div class='link-sections-wrap'>
        <div class='link-sections'>
          <div class='link-section'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Recent Articles</h2>
              <div class='section-title-separator'></div>
            </div>
            <ul class='link-list medium'>
              <li class='link-list-item'>
                <LinkTo @route='esports'>
                  eSports Home
                </LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo @route='esports.game' @model='Smite'>
                  Smite News
                </LinkTo>
              </li>
            </ul>
          </div>
          <div class='link-section'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Featured Teams</h2>
              <div class='section-title-separator'></div>
            </div>
            <ul class='link-list medium'>
              <li class='link-list-item'>
                <LinkTo @route='esports.team' @model='oni-warriors'>Oni Warriors</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo @route='esports.team' @model='jade-dragons'>Jade Dragons</LinkTo>
              </li>
            </ul>
            <!-- TODO: fix this asap -->
            <div class='section-title-separator'></div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Recent Tournaments</h2>
              <div class='section-title-separator'></div>
            </div>
            <ul class='link-list medium'>
              <li class='link-list-item'>
                <LinkTo @route='esports.tournament' @model='smite-world-championship-season-x'>Smite - SWCX</LinkTo>
              </li>
            </ul>
          </div>
          <div class='link-section'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Recent Matches</h2>
              <div class='section-title-separator'></div>
            </div>
            <ul class='link-list medium'>
              <li class='link-list-item'>
                <LinkTo @route='esports.matches.match' @model='smite-world-championship-season-x-grand-finals'>Smite -
                  SWCX: Grand Finals</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-semifinals-match-2'
                >Smite - SWCX: SF Match 2</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-semifinals-match-1'
                >Smite - SWCX: SF Match 1</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-4'
                >Smite - SWCX: QF Match 4</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-3'
                >Smite - SWCX: QF Match 3</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-2'
                >Smite - SWCX: QF Match 2</LinkTo>
              </li>
              <li class='link-list-item'>
                <LinkTo
                  @route='esports.matches.match'
                  @model='smite-world-championship-season-x-quarterfinals-match-1'
                >Smite - SWCX: QF Match 1</LinkTo>
              </li>
            </ul>
          </div>
        </div>
        <div class='link-sections'>
          <div class='link-section full'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Featured Players</h2>
              <div class='section-title-separator'></div>
            </div>
            <div class='streamer-avatars'>
              <div class='streamer-avatar'>
                <LinkTo @route='esports.player' @model='paul'>
                  <div class='pp-hexagon'>
                    <svg class='hexagon-icon hexagon-border'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                    <svg class='hexagon-icon hexagon-fill'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                  </div>
                  <div class='streamer-avatar-img-wrap'>
                    <img
                      alt='st-avatar-01'
                      class='streamer-avatar-img'
                      src='https://images.ctfassets.net/beelv7tx0ngc/12XDRu8VHbPQ2afjW5lnow/9cfa0bf4ea707e9ebb555302617cac1e/paul.png?w=68&h=89&r=100'
                    />
                  </div>
                </LinkTo>
              </div>
              <div class='streamer-avatar'>
                <LinkTo @route='esports.player' @model='adapting'>
                  <div class='pp-hexagon'>
                    <svg class='hexagon-icon hexagon-border'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                    <svg class='hexagon-icon hexagon-fill'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                  </div>
                  <div class='streamer-avatar-img-wrap'>
                    <img
                      alt='st-avatar-02'
                      class='streamer-avatar-img'
                      src='https://images.ctfassets.net/beelv7tx0ngc/HxDIPizwDlkwimI0TMb2V/174f2a96d8934b4126eddd80bc585a91/adapting.png?w=68&h=89&r=100'
                    />
                  </div>
                </LinkTo>
              </div>
              <div class='streamer-avatar'>
                <LinkTo @route='esports.player' @model='pandacat'>
                  <div class='pp-hexagon'>
                    <svg class='hexagon-icon hexagon-border'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                    <svg class='hexagon-icon hexagon-fill'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                  </div>
                  <div class='streamer-avatar-img-wrap'>
                    <img
                      alt='st-avatar-03'
                      class='streamer-avatar-img'
                      src='https://images.ctfassets.net/beelv7tx0ngc/5TC6UEyX8cqb1HZLGPwhhQ/88fb298fbdff152a26589390c815183f/pandacat.png?w=68&h=89&r=100'
                    />
                  </div>
                </LinkTo>
              </div>
              <div class='streamer-avatar'>
                <LinkTo @route='esports.player' @model='nika'>
                  <div class='pp-hexagon'>
                    <svg class='hexagon-icon hexagon-border'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                    <svg class='hexagon-icon hexagon-fill'>
                      <use href='#svg-hexagon'></use>
                    </svg>
                  </div>
                  <div class='streamer-avatar-img-wrap'>
                    <img
                      alt='st-avatar-05'
                      class='streamer-avatar-img'
                      src='https://images.ctfassets.net/beelv7tx0ngc/J1muoXvKTdR6LI55EjAfz/278a6bc94a271cb3eaae5f3948c1b033/nika.png?w=68&h=89&r=100'
                    />
                  </div>
                </LinkTo>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='link-sections-wrap'>
        <div class='link-sections'>
          <div class='link-section'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Featured Match</h2>
              <div class='section-title-separator'></div>
            </div>
            <div class='widget-match-box'>
              <div class='widget-title-wrap'>
                <h6 class='widget-title'>Smite - SWCX</h6>
                <p class='widget-text'>January 12th, 2024</p>
              </div>
              <div class='widget-team-vs'>
                <div class='widget-team-vs-item'>
                  <div class='team-info-wrap medium'>
                    <LinkTo @route='esports.team' @model='styx-ferrymen'>
                      <img
                        alt='logo-01'
                        class='team-logo'
                        src='https://images.ctfassets.net/beelv7tx0ngc/2cYTsNKNgj0C1Ufa2064Ux/d2f2c8a586e7a925ceb686256dff53b2/STYX.png'
                      />
                    </LinkTo>
                    <div class='team-info'>
                      <p class='team-name'>Styx Ferrymen</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                  <p class='team-score'>12</p>
                </div>
                <div class='widget-team-vs-item'>
                  <div class='team-info-wrap medium'>
                    <LinkTo @route='esports.team' @model='atlantis-leviathans'>
                      <img
                        alt='logo-02'
                        class='team-logo'
                        src='https://images.ctfassets.net/beelv7tx0ngc/2ew9guiOe37sjJSAVFBo0X/d405ff5fc237b796f0c6ea8bf5629958/LVTHN.png'
                      />
                    </LinkTo>
                    <div class='team-info'>
                      <p class='team-name'>Atlantis Leviathans</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                  <p class='team-score'>10</p>
                </div>
              </div>
            </div>
            <div class='widget-match-box'>
              <div class='widget-title-wrap'>
                <h6 class='widget-title'>Match Stats</h6>
                <div class='stats-reference'>
                  <div class='stat-reference blue'>
                    <p class='stat-reference-text'>SF</p>
                  </div>
                  <div class='stat-reference yellow'>
                    <p class='stat-reference-text'>AL</p>
                  </div>
                </div>
              </div>
              <div class='widget-team-stats'>
                <div class='progress-stat-wrap'>
                  <p class='bar-progress-info'>Total Kills</p>
                  <div class='progress-bar-wrap' id='mn-pg-1' {{vsProgress 34 28}}>
                    <div class='bar-progress-wrap left'>
                      <p class='bar-progress-text' id='mn-pg-1-left-stat'></p>
                    </div>
                    <div class='bar-progress-wrap right'>
                      <p class='bar-progress-text' id='mn-pg-1-right-stat'></p>
                    </div>
                  </div>
                </div>
                <div class='progress-stat-wrap'>
                  <p class='bar-progress-info'>Total Deaths</p>
                  <div class='progress-bar-wrap' id='mn-pg-2' {{vsProgress 15 21}}>
                    <div class='bar-progress-wrap left'>
                      <p class='bar-progress-text' id='mn-pg-2-left-stat'></p>
                    </div>
                    <div class='bar-progress-wrap right'>
                      <p class='bar-progress-text' id='mn-pg-2-right-stat'></p>
                    </div>
                  </div>
                </div>
                <div class='progress-stat-wrap'>
                  <p class='bar-progress-info'>Total Assists</p>
                  <div class='progress-bar-wrap' id='mn-pg-3' {{vsProgress 26 13}}>
                    <div class='bar-progress-wrap left'>
                      <p class='bar-progress-text' id='mn-pg-3-left-stat'></p>
                    </div>
                    <div class='bar-progress-wrap right'>
                      <p class='bar-progress-text' id='mn-pg-3-right-stat'></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='link-sections-wrap'>
        <div class='link-sections'>
          <div class='link-section'>
            <div class='section-title-wrap violet'>
              <h2 class='section-title small'>Featured Articles</h2>
              <div class='section-title-separator'></div>
            </div>
            <div class='post-preview-showcase grid-1col gutter-small'>
              <div class='post-preview tiny e-sport'>
                <LinkTo @route='esports.post' @model='slug'>
                  <div class='post-preview-img-wrap'>
                    <figure class='post-preview-img liquid'>
                      <img alt='post-36' src='/img/posts/36.jpg' />
                    </figure>
                  </div>
                </LinkTo>
                <LinkTo @route='esports.post' @model='slug' class='post-preview-title'>Gwen Parker is leading her team
                  to the semifinals</LinkTo>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'>By
                    <LinkTo class='post-author' @route='search'>
                      Dexter</LinkTo><span class='separator'>|</span>Dec 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview tiny e-sport'>
                <LinkTo @route='esports.post' @model='slug'>
                  <div class='post-preview-img-wrap'>
                    <figure class='post-preview-img liquid'>
                      <img alt='post-38' src='/img/posts/38.jpg' />
                    </figure>
                  </div>
                </LinkTo>
                <LinkTo @route='esports.post' @model='slug' class='post-preview-title'>Pro Soccer 2018 world league
                  kicked off today!</LinkTo>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'>By
                    <LinkTo @route='search' class='post-author'>
                      Faye V.</LinkTo><span class='separator'>|</span>Dec 15th, 2018</p>
                </div>
              </div>
              <div class='post-preview tiny e-sport'>
                <LinkTo @route='esports.post' @model='slug'>
                  <div class='post-preview-img-wrap'>
                    <figure class='post-preview-img liquid'>
                      <img alt='post-37' src='/img/posts/37.jpg' />
                    </figure>
                  </div>
                </LinkTo>
                <LinkTo @route='esports.post' @model='slug' class='post-preview-title'>Last night the wolves beat the
                  rhinos 12-10</LinkTo>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'>By
                    <LinkTo @route='search' class='post-author'>Faye V.</LinkTo><span class='separator'>|</span>Dec
                    15th, 2018
                  </p>
                </div>
              </div>
              <div class='post-preview tiny e-sport'>
                <LinkTo @route='esports.post' @model='slug'>
                  <div class='post-preview-img-wrap'>
                    <figure class='post-preview-img liquid'>
                      <img alt='post-39' src='/img/posts/39.jpg' />
                    </figure>
                  </div>
                </LinkTo>
                <LinkTo @route='esports.post' @model='slug' class='post-preview-title'>We had an interview with the
                  great Emily Danniels</LinkTo>
                <div class='post-author-info-wrap'>
                  <p class='post-author-info small light'>By
                    <LinkTo @route='search' class='post-author'>Vellatrix</LinkTo>
                    <span class='separator'>|</span>Dec 15th, 2018
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
