import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_GAME_QUERY } from '../../gql/queries/esports-game';
export default class Tournament extends Route {
    async model({ game_slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_GAME_QUERY,
                {
                    variables: {
                        slug: game_slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod.articles.items;
    }
}
