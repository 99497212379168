import { template } from "@ember/template-compiler";
export default template(`
  <div class='post-preview landscape gaming-news'>
    <a href='/news/article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/13.webp' alt='post-13' />
        </figure>
      </div>
    </a>
    <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
    <a href='/news/article-slug' class='post-preview-title'>The "Clash of Eternity" new
      <span class='highlight'>game</span>
      was just released</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
      eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape e-sport'>
    <a href='/esports/news/esports-news-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/37.jpg' alt='post-37' />
        </figure>
      </div>
    </a>
    <a href='esports-news.html' class='tag-ornament'>Esports</a>
    <a href='/esports/news/esports-news-slug' class='post-preview-title'>Last night the Wolves beat the Rhinos 12-10</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis
      <span class='highlight'>team</span>
      exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      <span class='highlight'>eSports</span>
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
      sint occaecat cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape e-sport'>
    <a href='/esports/news/esports-news-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/36.jpg' alt='post-36' />
        </figure>
      </div>
    </a>
    <a href='esports-news.html' class='tag-ornament'>Esports</a>
    <a href='/esports/news/esports-news-slug' class='post-preview-title'>Gwen Parker is leading her
      <span class='highlight'>team</span>
      to the semifinals</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
      eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape gaming-news'>
    <a href='/news/article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/01.jpg' alt='post-01' />
        </figure>
      </div>
    </a>
    <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
    <a href='/news/article-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales in USA and
      <span class='highlight'>Japan</span></a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
      eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape e-sport'>
    <a href='/esports/news/esports-news-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/53.jpg' alt='post-53' />
        </figure>
      </div>
    </a>
    <a href='esports-news.html' class='tag-ornament'>Esports</a>
    <a href='/esports/news/esports-news-slug' class='post-preview-title'>Sarah Stark joins the "Pixelated Pythons"
      Xenowatch
      <span class='highlight'>team</span></a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim
      <span class='highlight'>Japan</span>, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
      consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
      Excepteur sint occaecat cupidatat
      <span class='highlight'>eSports</span>
      non proident.</p>
  </div>
  <div class='post-preview landscape gaming-news'>
    <a href='/news/article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/27.jpg' alt='post-27' />
        </figure>
      </div>
    </a>
    <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
    <a href='/news/article-slug' class='post-preview-title'>Everything about Kawai Party 8!</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute irure
      <span class='highlight'>game</span>
      dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape game-review'>
    <a href='/reviews/review-article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/08.jpg' alt='post-08' />
        </figure>
        <div class='review-rating'>
          <div id='content-news-rate-4' class='arc small'></div>
        </div>
      </div>
    </a>
    <a href='/reviews' class='tag-ornament'>Game Reviews</a>
    <a href='/reviews/review-article-slug' class='post-preview-title'>The new mecha cyborg
      <span class='highlight'>game</span>
      is breaking barriers</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
      eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p>
  </div>
  <div class='post-preview landscape movie-news'>
    <a href='post-v3.html'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'>
          <img src='/img/posts/12.jpg' alt='post-12' />
        </figure>
        <div class='rating-ornament'>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon empty'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon empty'>
              <use href='#svg-star'></use>
            </svg>
          </div>
        </div>
      </div>
    </a>
    <a href='news-v3.html' class='tag-ornament'>Movie News</a>
    <a href='post-v3.html' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
    </div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt ut ore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
      ut aliquip ex ea commodo consequat. Duis aute
      <span class='highlight'>game</span>
      irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
      occaecat cupidatat non proident.</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
