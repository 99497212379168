import { template } from "@ember/template-compiler";
import { formatDate } from '../../../helpers/format-date';
import PlayerSocialLinks from '../../esports/player/player-social-links';
import { LinkTo } from '@ember/routing';
import Markdown from '../../markdown';
import RelatedArticles from './related-articles';
import RelatedMatch from './related-match';
import ContentfulBody from '../../layout/news/contentful-body';
export default template(`
  <div class='layout-item grid-limit gutter-big padded-large padded-bottom'>
    <div class='post-open e-sport'>
      <div class='post-open-content v6'>
        <div class='post-open-body'>
          <div class='tag-list'>
            <LinkTo @route='esports' class='tag-ornament'>Esports</LinkTo>
          </div>
          <p class='post-open-title'>{{@post.title}}</p>
          <div class='feature-list v2'>
            <p class='feature-list-item'>
              <span class='feature-title'>{{@post.author.displayName}}</span>
              <span class='feature-info'>Post Author</span>
            </p>
            <p class='feature-list-item'>
              <span class='feature-title'>{{formatDate @post.publishedDate}}</span>
              <span class='feature-info'>Date Posted</span>
            </p>
          </div>
          <div class='post-open-img-wrap'>
            <figure class='post-open-img liquid'>
              <img src='{{@post.coverImage.url}}' alt='post-37' />
            </figure>
          </div>
          <p class='post-open-text bold'>{{@post.shortDescription}}</p>

          <ContentfulBody @content={{@post.content}} />
          {{#unless @post.content}}
            <p class='post-open-text'>
              <Markdown @markdown={{@post.body}} />
            </p>
          {{/unless}}

          {{#if @post.match}}
            <RelatedMatch @match={{@post.match}} />
          {{/if}}

          <div class='button-social-list'>
            <a
              href='http://www.facebook.com/share.php?display=popup&u=https://1vX.gg/esports/news/{{@post.slug}}'
              target='_blank'
              class='button-social big fb'
            >
              <svg class='facebook-icon big'>
                <use href='#svg-facebook'></use>
              </svg>
              Share on Facebook
            </a>
            <a
              href='https://twitter.com/intent/tweet?text={{@post.shortDescription}}&url=https://1vX.gg/esports/news/{{@post.slug}}&hashtags=esports&via=1vxgg'
              target='_blank'
              class='button-social big twt'
            >
              <svg class='twitter-icon big'>
                <use href='#svg-twitter'></use>
              </svg>
              Share on Twitter
            </a>
            <a
              href='https://s2f.kytta.dev/?text={{@post.shortDescription}} - https://1vX.gg/esports/news/{{@post.slug}}'
              target='_blank'
              class='button-social big discord'
            >
              <svg class='twitter-icon big'>
                <use href='#svg-x'></use>
              </svg>
              Share on the Fediverse
            </a>
          </div>

          <div class='post-comment'>
            <figure class='user-avatar medium liquid'>
              <img src='{{@post.author.profilePicture.url}}' alt='{{@post.author.displayName}}' />
            </figure>
            <p class='post-comment-username text-tag author'>{{@post.author.displayName}}</p>
            <p class='post-comment-text'>{{@post.author.bioBlurb}}</p>
            <div class='post-comment-actions'>
              <div class='social-links'>
                <PlayerSocialLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {{#if @post.relatedArticles.items}}
      <RelatedArticles @articles={{@post.relatedArticles.items}} />
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
