// noinspection SpellCheckingInspection

import EmberRouter from '@ember/routing/router';
import config from 'embroil/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('search');
  this.route('ggtv', function () {
    this.route('video', { path: '/:slug' });
  });
  this.route('news', function () {
    this.route('post', { path: '/:slug' });
  });
  this.route('reviews', function () {
    this.route('post', { path: '/:slug' });
  });
  this.route('games');
  this.route('game', { path: '/game/:game_slug' });
  this.route('streamers', { path: '/game/:game_slug/streamers' });
  this.route('streamer', { path: '/streamer/:streamer_slug' });
  this.route('esports', function () {
    this.route('post', { path: '/news/:slug' });
    this.route('game', { path: '/game/:game_slug' });
    this.route('team', { path: '/team/:team_slug' });
    this.route('player', { path: '/player/:player_slug' });
    this.route('tournament', { path: '/tournament/:tournament_slug' });
    this.route('matches', function () {
      this.route('match', { path: '/:match_slug' });
    });
  });
  this.route('about');
  this.route('privacy-policy');
  this.route('terms-and-conditions');
  this.route('404', { path: '/*' });
});

// 1vX.gg
// /search
// /ggtv
// /ggtv/<video-slug>
// /reviews
// /reviews/<review-slug>
// /news
// /news/<news-slug>
// /games
// /games/<game-slug>
// /games/<game-slug>/streamers
// /esports
// /esports/news/<article-slug>
// /esports/game/<game-slug>
// /esports/team/<team-slug>
// /esports/player/<player-slug>
// /esports/matches
// /esports/matches/<match-slug>
// /about
// /privacy-policy
// /terms-and-conditions
// /404
// add
// /esports/game/<game-slug>/teams - just a table of teams?
// /esports/game/<game-slug>/players - just a table of players?
// /author/<author-slug>
