import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'>
      <h2 class='section-title medium'>Calendar</h2>
      <div class='section-title-separator'></div>
    </div>
    <div id='calendar-sidebar' class='calendar small blue'>
      <div class='calendar-header'>
        <div class='calendar-header-top'>
          <div id='calendar1-controls' class='calendar-controls slider-controls blue'>
            <div class='slider-control control-previous'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
            <div class='slider-control control-next'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
          </div>
          <p class='calendar-month-year'>
            <span class='calendar-month'></span>
            <span class='calendar-year'></span>
          </p>
        </div>
        <div class='calendar-header-bottom'></div>
      </div>
      <div class='calendar-body'></div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
