import { modifier } from 'ember-modifier';

export interface AutoFocusModifierSignature {
  Element: HTMLFormElement | HTMLInputElement;
}
export default modifier<AutoFocusModifierSignature>((element) => {
  element.focus();
  // const callback = (mutationList: any, observer: any) => {
  //   mutationList.forEach((mutation: any) => {
  //     console.log('mutation', mutation);
  //     if (
  //       mutation.type === 'attributes' &&
  //       mutation.attributeName === 'class'
  //     ) {
  //       element.focus();
  //     }
  //   });
  // };
  // const observer = new MutationObserver(callback);
  // observer.observe(element, { attributes: true });
  //
  // return () => {
  //   observer.disconnect();
  // };
});
