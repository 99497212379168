import { template } from "@ember/template-compiler";
import { eq } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import Component from '@glimmer/component';
const plusOne = (idx1)=>{
    return idx1 + 1;
};
export default class RoundTabsComponent extends Component {
    get currentVideo() {
        // why do this over just passing the round itself in?
        return this.args.rounds[this.args.currentRound].embedUrl;
    }
    static{
        template(`
    <div class='tab-wrap'>
      <div class='tab-header items-{{@totalRounds}}'>
        {{#each @rounds as |round index|}}
          <div
            class='tab-header-item {{if (eq index @currentRound) "selected"}}'
            {{on 'click' (fn @selectRound index)}}
          >
            <p class='tab-header-item-text'>Round {{plusOne index}}</p>
          </div>
        {{/each}}
      </div>
    </div>
    <br />
    <div class='widget-media-wrap huge'>
      <div class='widget-media-r'>
        <iframe src={{this.currentVideo}} width='500' allowfullscreen></iframe>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
