import { gql } from 'glimmer-apollo';

export const ESPORTS_POST_QUERY = gql`
  query ($slug: String) {
    article: eSportsArticleCollection(where: { slug: $slug }) {
      items {
        title
        slug
        shortDescription
        body
        content {
          json
        }
        publishedDate
        coverImage {
          url
        }
        author {
          displayName
          bioBlurb
          socialLinks: socialLinksCollection(limit: 10) {
            items {
              url
              platform
              accessibilityDescription
            }
          }
          profilePicture {
            url
          }
        }
        match {
          isLive
          isFinal
          slug
          title
          scheduledDate
          rounds: roundsCollection(limit: 10) {
            items {
              victor {
                slug
              }
            }
          }
          teams: teamsCollection(limit: 2) {
            items {
              name
              slug
              logo {
                url
              }
            }
          }
          victor {
            slug
            name
            logo {
              url
            }
          }
        }
        tournament {
          isLive
          isFinal
          teams: teamsCollection(limit: 24) {
            items {
              name
              slug
              logo {
                url
              }
            }
          }
          victor {
            slug
            name
            logo {
              url
            }
          }
        }
        relatedArticles: relatedArticlesCollection(limit: 3) {
          items {
            ... on ESportsArticle {
              title
              shortDescription
              slug
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              shortDescription
              slug
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
