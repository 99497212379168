import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'embroil/config/environment';

import * as Sentry from '@sentry/ember';

Sentry.init({
  dsn: 'https://29f17851ce7e4af8b0ebc3eced383269@app.glitchtip.com/5820',
  environment: config.environment,
  release: '1.0',
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
