import Route from "@ember/routing/route";
import { useQuery } from "glimmer-apollo";
import { GET_HOME_PAGE } from "../gql/queries/home-page";

export default class AboutRoute extends Route {
  data = useQuery(this, () => [GET_HOME_PAGE]);

  async model() {
    await this.data.promise;
    return this.data.data;
  }
}
