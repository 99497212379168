import { gql } from 'glimmer-apollo';

export const ESPORTS_TOURNAMENT_QUERY = gql`
  query ($slug: String) {
    tournament: tournamentCollection(where: { slug: $slug }) {
      items {
        slug
        image {
          url
        }
        startDate
        endDate
        title
        isLive
        isFinal
        victor {
          slug
          name
          logo {
            url
          }
        }
        liveChatUrl
        liveVideoUrl
        prize
        game {
          title
          slug
          subTitle
        }
        featuredPlayer {
          gamertag
          irlName
          slug
          role
          photo {
            url(transform: { width: 90 })
          }
          currentTeam {
            name
            logo {
              url(transform: { width: 40 })
            }
          }
        }
        matches: matchesCollection(limit: 30) {
          items {
            slug
            title
            isLive
          }
        }
        teams: teamsCollection(limit: 16) {
          items {
            slug
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;
