import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import EsportsNewsComponent from '../../components/layout/news/esports-news';
import EsportsMatchResultsBanner from '../../components/layout/banner/esports-match-results';
import { pageTitle } from 'ember-page-title';
let EsportsGameComponent = class EsportsGameComponent extends Component {
    static{
        template(`
    {{pageTitle 'Smite'}}
    <BannerComponent @theme='e-sport' @title='Smite News' />
    <LiveNewsTicker />
    <EsportsMatchResultsBanner />
    <EsportsNewsComponent />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsGameComponent);
