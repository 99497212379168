import { template } from "@ember/template-compiler";
export default template(`
  <div class='page-navigation blue spaced'>
    <div class='slider-control big control-previous'>
      <svg class='arrow-icon medium'>
        <use href='#svg-arrow-medium'></use>
      </svg>
    </div>
    <a href='#' class='page-navigation-item'>1</a>
    <a href='#' class='page-navigation-item active'>2</a>
    <a href='#' class='page-navigation-item'>3</a>
    <a href='#' class='page-navigation-item'>...</a>
    <a href='#' class='page-navigation-item'>8</a>
    <div class='slider-control big control-next'>
      <svg class='arrow-icon medium'>
        <use href='#svg-arrow-medium'></use>
      </svg>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
