import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import PopularPosts from '../../components/widget/sidebar/popular-posts';
import LiveStreams from '../../components/widget/sidebar/live-streams';
import FeaturedVideo from '../../components/widget/sidebar/featured-video';
import TagCloud from '../../components/widget/sidebar/tag-cloud';
import SidebarSearch from '../../components/widget/sidebar/search';
import PostPreview from '../../components/layout/post-preview';
import PaginationComponent from '../../components/layout/pagination';
import { pageTitle } from 'ember-page-title';
let NewsIndexComponent = class NewsIndexComponent extends Component {
    // get tags() {
    //   return this.args.model.map(article => article.tags)
    // }
    static{
        template(`
    {{pageTitle 'Gaming News'}}
    <BannerComponent @theme='gaming-news' @title='Gaming News' />
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          {{#each @model as |article|}}
            <PostPreview @type='gaming-news' @post={{article}} />
          {{/each}}
        </div>
        <!--<PaginationComponent />-->
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <SidebarSearch />
        <PopularPosts />
        <!--<TagCloud />-->
        <LiveStreams />
        <FeaturedVideo />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(NewsIndexComponent);
