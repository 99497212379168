import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
// @ts-expect-error
import { keyResponder, onKey } from 'ember-keyboard';
import InventoryBagItem from './inventory-bag/item';
interface InventoryBagPreviewComponentSignature {
    Element: HTMLDivElement;
    Args: {
        isBagOpen: boolean;
        closeBag: (close: boolean) => void;
    };
    Blocks: {
    };
}
export default @keyResponder
class InventoryBagPreviewComponent extends Component<InventoryBagPreviewComponentSignature> {
    get isBagOpen() {
        return this.args.isBagOpen;
    }
    @onKey('Escape')
    closeBag() {
        this.args.closeBag(false);
    }
    static{
        template(`
    <div class='window-overlay {{if @isBagOpen "open"}}' {{on 'click' (fn @closeBag false)}}>
      <div class='inventory-bag-preview' style='{{if @isBagOpen "transform: translate(-100%)"}}'>
        <svg class='inventory-close-button cross-icon big'>
          <use href='#svg-cross-big'></use>
        </svg>
        <p class='items-in-inventory'><span class='quantity'>8</span>
          items in inventory</p>
        <div class='line-separator'></div>
        <div class='inventory-items-preview'>
          <InventoryBagItem
            @slug='magimons-black-framed-print'
            @title='Magimons black framed print'
            @image='/img/products/14.jpg'
            @quantity={{3}}
            @price={{29999}}
            @rating={{5}}
          />
        </div>
        <div class='line-separator'></div>
        <p class='inventory-subtotal'><span class='subtotal-text'>Inventory subtotal:</span>
          <span class='subtotal-value'>
            <span class='currency'>\$</span><span class='value'>670.50</span></span></p>
        <div class='line-separator'></div>
        <div class='inventory-actions'>
          <a class='button full blue' href='#'>Go to the inventory bag</a>
          <a class='button full violet' href='#'>Proceed to checkout</a>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
