import { template } from "@ember/template-compiler";
export default template(`
  <div class='banner-wrap {{@theme}}'>
    <div class='banner grid-limit'>
      <h2 class='banner-title'>{{@title}}</h2>
      <p class='banner-sections'>
        <span class='banner-section'>Home</span>
        <svg class='arrow-icon'>
          <use href='#svg-arrow'></use>
        </svg>
        <span class='banner-section'>{{@title}}</span>
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
