import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import dropdown from '../../modifiers/dropdown';
let GgtvIndex = class GgtvIndex extends Component {
    static{
        template(`
    <BannerComponent @theme='pixel-videos' @title='GGtv' />
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='section-title-wrap cyan small-space'>
          <h2 class='section-title medium'>Browse Videos</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='filters-row'>
          <div class='dropdown-simple-wrap'>
            <div id='filter-01-dropdown-trigger' class='dp-current-option'>
              <div id='filter-01-dropdown-option-value' class='dp-current-option-value'>
                <p class='dp-option-text'>All Categories</p>
              </div>
              <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg>
            </div>
            <div id='filter-01-dropdown' class='dp-options small' {{dropdown}}>
              <div class='dp-option'>
                <p class='dp-option-text'>All Categories</p>
              </div>
              <div class='dp-option'>
                <p class='dp-option-text'>News</p>
              </div>
              <div class='dp-option'>
                <p class='dp-option-text'>Reviews</p>
              </div>
            </div>
          </div>

          <div class='dropdown-simple-wrap'>
            <div id='filter-02-dropdown-trigger' class='dp-current-option'>
              <div id='filter-02-dropdown-option-value' class='dp-current-option-value'>
                <p class='dp-option-text'>All Authors</p>
              </div>
              <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg>
            </div>
            <div id='filter-02-dropdown' class='dp-options small' {{dropdown}}>
              <div class='dp-option'>
                <p class='dp-option-text'>All Authors</p>
              </div>
              <div class='dp-option'>
                <p class='dp-option-text'>Vellatrix</p>
              </div>
              <div class='dp-option'>
                <p class='dp-option-text'>Dexter</p>
              </div>
            </div>
          </div>
          <div class='dropdown-simple-wrap'>
            <div id='filter-03-dropdown-trigger' class='dp-current-option'>
              <div id='filter-03-dropdown-option-value' class='dp-current-option-value'>
                <p class='dp-option-text'>Order By Date</p>
              </div>
              <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg>
            </div>

            <div id='filter-03-dropdown' class='dp-options small' {{dropdown}}>
              <div class='dp-option'>
                <p class='dp-option-text'>Order By Date</p>
              </div>
              <div class='dp-option'>
                <p class='dp-option-text'>Order By Popularity</p>
              </div>
            </div>
          </div>
          <div class='dropdown-simple-wrap'>
            <div id='filter-04-dropdown-trigger' class='dp-current-option'>
              <div id='filter-04-dropdown-option-value' class='dp-current-option-value'>
                <p class='dp-option-text'>Descending</p>
              </div>

              <svg class='arrow-icon'>
                <use href='#svg-arrow'></use>
              </svg>
            </div>

            <div id='filter-04-dropdown' class='dp-options small' {{dropdown}}>
              <div class='dp-option'>
                <p class='dp-option-text'>Descending</p>
              </div>

              <div class='dp-option'>
                <p class='dp-option-text'>Ascending</p>
              </div>
            </div>
          </div>
        </div>

        <div class='post-preview-showcase grid-3col centered'>
          <div class='post-preview video gaming-news no-hover'>
            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/31.jpg' alt='post-31' />
                </figure>
                <div class='post-preview-overlay'>
                  <div class='play-button'>
                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>
                  </div>
                </div>
              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Galaxy Aventure RX peek at the new weapons</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video game-review no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/16.jpg' alt='post-16' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/reviews' class='tag-ornament'>Game Reviews</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>We reviewed the new Magimons game - video review</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video movie-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/12.jpg' alt='post-12' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='news-v3.html' class='tag-ornament'>Movie News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Check out the new "Guardians of the Universe" trailer</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video e-sport no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/38.jpg' alt='post-38' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='esports-news.html' class='tag-ornament'>Esports</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Watch the best match of the Pro Soccer 2017 World Cup</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video gaming-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/25.jpg' alt='post-25' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Gameplay trailer for the new Legend of Kenji game</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video gaming-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/07.jpg' alt='post-07' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>"Rise of Depredators" launched it's cinematic trailer</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video gaming-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/01.jpg' alt='post-01' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Take a look at the Clash of Dragons new classes</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video geeky-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/17.jpg' alt='post-17' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='news-v4.html' class='tag-ornament'>Geeky News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>GTE5 anime will premiere in May and here's the first
              peek</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video gaming-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/09.jpg' alt='post-09' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>5 minutes of the new "Rizen" game story mode</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video gaming-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/13.webp' alt='post-13' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='/news/article-slug' class='tag-ornament'>Gaming News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>A close look at Leena from "Clash of Eternity"</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video e-sport no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/41.jpg' alt='post-41' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='esports-news.html' class='tag-ornament'>eSports</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>Watch crowd highlights of the Xenowatch semifinals</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

          <div class='post-preview video geeky-news no-hover'>

            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>

                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/02.jpg' alt='post-02' />
                </figure>

                <div class='post-preview-overlay'>

                  <div class='play-button'>

                    <svg class='play-button-icon'>
                      <use href='#svg-play'></use>
                    </svg>

                  </div>

                </div>

              </div>
            </a>

            <div class='tag-list'>
              <a href='/ggtv' class='tag-ornament video'>Video</a>
              <a href='news-v4.html' class='tag-ornament'>Geeky News</a>
            </div>

            <a href='/ggtv/video-slug' class='post-preview-title'>"Charlotte" released a new teaser for the new series</a>

            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
            </div>

            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor...</p>
          </div>

        </div>

        <div class='page-navigation cyan spaced center'>

          <div class='slider-control big control-previous'>

            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>

          </div>

          <a href='#' class='page-navigation-item'>1</a>
          <a href='#' class='page-navigation-item active'>2</a>
          <a href='#' class='page-navigation-item'>3</a>
          <a href='#' class='page-navigation-item'>...</a>
          <a href='#' class='page-navigation-item'>8</a>

          <div class='slider-control big control-next'>

            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>

          </div>

        </div>

      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <div class='widget-sidebar'>
          <div class='section-title-wrap cyan'>
            <h2 class='section-title medium'>Popular Videos</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-1col centered gutter-small'>
            <div class='post-preview video gaming-news tiny no-hover'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/13.webp' alt='post-13' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>07:57</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>A close look at Leena from "Clash of Eternity"</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>

            <div class='post-preview video game-review tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/16.jpg' alt='post-16' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>03:22</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>We reviewed the new Magimons Game - video review</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video geeky-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/17.jpg' alt='post-17' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>07:26</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>GTE5 anime will premiere in May and here's the first
                peek</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video gaming-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/25.jpg' alt='post-25' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>08:04</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Gameplay trailer for the new Legend of Kenji game</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video e-sport tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/38.jpg' alt='post-38' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>07:57</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Watch the best match of the Pro Soccer 2017 World
                Cup</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video gaming-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/31.jpg' alt='post-31' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>04:37</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Galaxy Adventure RX peek at the new weapons</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video gaming-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/07.jpg' alt='post-07' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>09:24</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>"Rise of Depredators" launched it's cinematic
                trailer</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video e-sport tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/41.jpg' alt='post-41' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>08:04</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Watch crowd highlights of the Xenowatch semifinals</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video geeky-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/02.jpg' alt='post-02' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>09:24</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>"Charlotte" released a new teaser for the new series</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video movie-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/12.jpg' alt='post-12' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>05:56</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Check out the new "Guardians of the Universe"
                trailer</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video gaming-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/01.jpg' alt='post-01' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>04:37</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>Take a look at The Clash of Dragons new classes</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

            <div class='post-preview video gaming-news tiny no-hover'>

              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>

                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/09.jpg' alt='post-09' />
                  </figure>

                  <div class='post-preview-overlay'>

                    <div class='play-button tiny'>

                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>

                    </div>

                    <p class='timestamp-tag tiny'>05:56</p>

                  </div>

                </div>
              </a>

              <a href='/ggtv/video-slug' class='post-preview-title'>5 minutes of the new "Rizen" game story mode</a>

              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>

            </div>

          </div>

        </div>

      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(GgtvIndex);
