import { template } from "@ember/template-compiler";
export default template(`
  <div class='layout-item-3-1 grid-limit'>
    <div class='layout-body layout-item gutter-big'>
      <div class='widget-item'>
        <div class='section-title-wrap violet small-space'>
          <h2 class='section-title medium'>Player Builds</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='widget-match-box'>
          <div class='widget-title-wrap'>
            <h6 class='widget-title'>The Lone Wolves <span class='highlight violet'>Builds</span></h6>
            <p class='widget-text bold'>Raging Rhinos <span class='highlight violet'>Builds</span></p>
          </div>
          <div class='character-selection-list'>
            <div class='character-list hoverable'>
              <img class='user-avatar character v2 blue active' src='/img/characters/01.png' alt='character-01' />
              <img class='user-avatar character v2 blue' src='/img/characters/02.png' alt='character-02' />
              <img class='user-avatar character v2 blue' src='/img/characters/03.png' alt='character-03' />
              <img class='user-avatar character v2 blue' src='/img/characters/04.png' alt='character-04' />
              <img class='user-avatar character v2 blue' src='/img/characters/05.png' alt='character-05' />
            </div>
            <p class='character-selection-list-text'>Select a Character</p>
            <div class='character-list hoverable'>
              <img class='user-avatar character v2 red' src='/img/characters/10.png' alt='character-10' />
              <img class='user-avatar character v2 red' src='/img/characters/09.png' alt='character-09' />
              <img class='user-avatar character v2 red' src='/img/characters/08.png' alt='character-08' />
              <img class='user-avatar character v2 red' src='/img/characters/07.png' alt='character-07' />
              <img class='user-avatar character v2 red' src='/img/characters/06.png' alt='character-06' />
            </div>
          </div>
        </div>
        <div class='widget-display multiple-items medium align-left'>
          <div class='featured-stat-wrap'>
            <p class='featured-stat-title'>2.00</p>
            <p class='featured-stat-text'>KDA</p>
          </div>
          <div class='featured-stat-wrap'>
            <p class='featured-stat-title'>1</p>
            <p class='featured-stat-text'>Double Kills</p>
          </div>
          <div class='featured-stat-wrap'>
            <p class='featured-stat-title'>0</p>
            <p class='featured-stat-text'>Triple Kills</p>
          </div>
          <div class='featured-stat-wrap'>
            <p class='featured-stat-title'>0</p>
            <p class='featured-stat-text'>Quadra Kills</p>
          </div>
          <div class='featured-stat-wrap'>
            <p class='featured-stat-title'>0</p>
            <p class='featured-stat-text'>Penta Kills</p>
          </div>
        </div>
        <div class='widget-overflow-wrap skill-stats'>
          <div class='widget-match-box skill-stats'>
            <div class='widget-title-wrap'>
              <div class='widget-title-item'>
                <p class='widget-title'>Skill Order</p>
              </div>
              <div class='widget-title-item'>
                <div class='team-score-boxes-title-wrap'>
                  <p class='team-score-box-title'>1</p>
                  <p class='team-score-box-title'>2</p>
                  <p class='team-score-box-title'>3</p>
                  <p class='team-score-box-title'>4</p>
                  <p class='team-score-box-title'>5</p>
                  <p class='team-score-box-title'>6</p>
                  <p class='team-score-box-title'>7</p>
                  <p class='team-score-box-title'>8</p>
                  <p class='team-score-box-title'>9</p>
                  <p class='team-score-box-title'>10</p>
                  <p class='team-score-box-title'>11</p>
                  <p class='team-score-box-title'>12</p>
                  <p class='team-score-box-title'>13</p>
                  <p class='team-score-box-title'>14</p>
                  <p class='team-score-box-title'>15</p>
                  <p class='team-score-box-title'>16</p>
                  <p class='team-score-box-title'>17</p>
                  <p class='team-score-box-title'>18</p>
                </div>
              </div>
            </div>
            <div class='widget-team-vs v3 tiny'>
              <div class='widget-team-vs-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/skills/skill-b-01.png'
                    alt='skill-b-01'
                  />
                </div>
                <div class='team-score-boxes small fill-end'>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>Q</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                </div>
              </div>
              <div class='widget-team-vs-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/skills/skill-b-02.png'
                    alt='skill-b-02'
                  />
                </div>
                <div class='team-score-boxes small fill-end'>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>W</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>W</p>
                  </div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>W</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                </div>
              </div>
              <div class='widget-team-vs-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/skills/skill-b-03.png'
                    alt='skill-b-03'
                  />
                </div>
                <div class='team-score-boxes small fill-end'>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>E</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>E</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>E</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>E</p>
                  </div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>E</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                </div>
              </div>
              <div class='widget-team-vs-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/skills/skill-b-04.png'
                    alt='skill-b-04'
                  />
                </div>
                <div class='team-score-boxes small fill-end'>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>R</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>R</p>
                  </div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box'></div>
                  <div class='team-score-box filled'>
                    <p class='team-score-box-text'>R</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-match-box'>
          <div class='widget-title-wrap'>
            <div class='widget-title-item'>
              <p class='widget-title'>Items</p>
            </div>
          </div>
          <div class='widget-overflow-wrap item-stats'>
            <div class='widget-overflow-list-wrap'>
              <div class='widget-overflow-list'>
                <p class='widget-overflow-list-text'>0m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-01.png'
                    alt='item-b-01'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-02.png'
                    alt='item-b-02'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-02.png'
                    alt='item-b-02'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-02.png'
                    alt='item-b-02'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-03.png'
                    alt='item-b-03'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-04.png'
                    alt='item-b-04'
                  />
                </div>
                <p class='widget-overflow-list-text'>6m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-05.png'
                    alt='item-b-05'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-06.png'
                    alt='item-b-06'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-03.png'
                    alt='item-b-03'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-07.png'
                    alt='item-b-07'
                  />
                </div>
                <p class='widget-overflow-list-text'>15m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-08.png'
                    alt='item-b-08'
                  />
                </div>
                <p class='widget-overflow-list-text'>22m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-09.png'
                    alt='item-b-09'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-10.png'
                    alt='item-b-10'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-11.png'
                    alt='item-b-11'
                  />
                </div>
                <p class='widget-overflow-list-text'>27m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-02.png'
                    alt='item-b-02'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-12.png'
                    alt='item-b-12'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-13.png'
                    alt='item-b-13'
                  />
                </div>
                <p class='widget-overflow-list-text'>33m</p>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-01.png'
                    alt='item-b-01'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-10.png'
                    alt='item-b-10'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-10.png'
                    alt='item-b-10'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-06.png'
                    alt='item-b-06'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-12.png'
                    alt='item-b-12'
                  />
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/loot/item-b-05.png'
                    alt='item-b-05'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-match-box'>
          <div class='widget-title-wrap'>
            <div class='widget-title-item'>
              <p class='widget-title'>Runes</p>
            </div>
          </div>
          <div class='widget-overflow-wrap rune-stats'>
            <div class='widget-overflow-list-wrap'>
              <div class='widget-overflow-list'>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-01.png'
                    alt='rune-b-01'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Escalation</p>
                    <p class='skill-loot-item-text'>Bursts damage and tager access</p>
                  </div>
                </div>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-02.png'
                    alt='rune-b-02'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Bullseye</p>
                    <p class='skill-loot-item-text'>Improved attacks and sustained damage</p>
                  </div>
                </div>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-03.png'
                    alt='rune-b-03'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Electrify</p>
                    <p class='skill-loot-item-text'>Total: 2140</p>
                  </div>
                </div>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-04.png'
                    alt='rune-b-04'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Mysticist Hunter</p>
                    <p class='skill-loot-item-text'>Total: 13</p>
                  </div>
                </div>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-05.png'
                    alt='rune-b-05'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Sudden Explotion</p>
                    <p class='skill-loot-item-text'>Total: 1017</p>
                  </div>
                </div>
                <div class='skill-loot-list medium'>
                  <img
                    class='skill-loot-item skill-loot-img medium'
                    src='/img/characters/runes/runes-b-06.png'
                    alt='rune-b-06'
                  />
                  <div class='skill-loot-item-info'>
                    <p class='skill-loot-item-title'>Last Shot</p>
                    <p class='skill-loot-item-text'>Total: 560</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='layout-sidebar layout-item gutter-medium'>
      <div class='widget-sidebar'>
        <div class='section-title-wrap violet small-space'>
          <h2 class='section-title medium'>Total Damage</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='table champion-rates'>
          <div class='table-row-header'>
            <div class='table-row-header-item left'>
              <p class='table-row-header-title'>Player/Role</p>
            </div>
            <div class='table-row-header-item'>
              <p class='table-row-header-title'>Damage</p>
            </div>
          </div>
          <div class='table-rows no-color'>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small blue' src='/img/characters/01.png' alt='character-01' />
                  <div class='team-info'>
                    <p class='team-name'>Joystickz-70</p>
                    <p class='team-country'>MID | SOLO</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-01' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small blue' src='/img/characters/04.png' alt='character-04' />
                  <div class='team-info'>
                    <p class='team-name'>DABLADE_NT</p>
                    <p class='team-country'>TOP | SOLO</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-02' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small red' src='/img/characters/10.png' alt='character-10' />
                  <div class='team-info'>
                    <p class='team-name'>PXSlasher</p>
                    <p class='team-country'>JUNGLE</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-03' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small red' src='/img/characters/08.png' alt='character-08' />
                  <div class='team-info'>
                    <p class='team-name'>Arrowhead</p>
                    <p class='team-country'>TOP | SOLO</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-04' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small blue' src='/img/characters/03.png' alt='character-03' />
                  <div class='team-info'>
                    <p class='team-name'>Firestorm</p>
                    <p class='team-country'>BOT | CARRY</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-05' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small blue' src='/img/characters/05.png' alt='character-05' />
                  <div class='team-info'>
                    <p class='team-name'>Keymaster</p>
                    <p class='team-country'>JUNGLE</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-06' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small red' src='/img/characters/09.png' alt='character-09' />
                  <div class='team-info'>
                    <p class='team-name'>TheLightning</p>
                    <p class='team-country'>MID | SOLO</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-07' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small blue' src='/img/characters/02.png' alt='character-02' />
                  <div class='team-info'>
                    <p class='team-name'>Dr.Chaos</p>
                    <p class='team-country'>BOT | SUPPORT</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-08' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small red' src='/img/characters/06.png' alt='character-06' />
                  <div class='team-info'>
                    <p class='team-name'>Pixelus.GD</p>
                    <p class='team-country'>BOT | CARRY</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-09' class='progress-bar link-left'></div>
              </div>
            </div>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character v2 small red' src='/img/characters/07.png' alt='character-07' />
                  <div class='team-info'>
                    <p class='team-name'>Striker15</p>
                    <p class='team-country'>BOT | SOLO</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <div id='ew3-pgb-crd-10' class='progress-bar link-left'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
