import * as Swetrix from 'swetrix';
import Service from '@ember/service';

export default class Metrics extends Service {
  constructor(...args) {
    super(...args);
    Swetrix.init('SOGfNF2YRMRi');
  }

  track(ev, meta = {}) {
    Swetrix.track({ ev, meta });
  }

  trackViews() {
    Swetrix.trackViews();
  }
}
