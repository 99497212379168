import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import carousel from '../modifiers/esports-match-results-carousel';
import PixelPoll from '../components/widget/sidebar/pixel-poll';
import progress from '../modifiers/progress';
let GameIndexRouteComponent = class GameIndexRouteComponent extends Component {
    static{
        template(`
    <div id='banner-slider-3' class='banner-slider v3' {{carousel}}>
      <div id='banner-slider-3-items' class='slider-items'>
        <div class='post-preview picture big game-review'>
          <a href='/reviews/review-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/16.jpg' alt='post-16' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Game Reviews</span>
                <p class='post-preview-title'>We reviewed the new Magimons game</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big game-review'>
          <a href='/reviews/review-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/25.jpg' alt='post-25' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Game Reviews</span>
                <p class='post-preview-title'>"Legend of Kenshi II" is a bit green for now</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big gaming-news'>
          <a href='/news/news-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/09.jpg' alt='post-09' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Gaming News</span>
                <p class='post-preview-title'>New "Rizen" game is gonna be released in summer 2019</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big gaming-news'>
          <a href='/news/news-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/17.jpg' alt='post-17' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Gaming News</span>
                <p class='post-preview-title'>Jazzstar anounced that the GTE5 for PC is delayed</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big geeky-news'>
          <a href='/esports/news/slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/18.jpg' alt='post-18' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Geeky News</span>
                <p class='post-preview-title'>"Ichigo Idol" anime will have a new season in April</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big e-sport'>
          <a href='esports-post.html'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/36.jpg' alt='post-36' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Esports</span>
                <p class='post-preview-title'>Gwen Parker is leading her team to the semifinals</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big geeky-news'>
          <a href='/esports/news/slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/02_tall.jpg' alt='post-02_tall' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Geeky News</span>
                <p class='post-preview-title'>Jessica Tam to star in the new "Charlotte" series</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big movie-news'>
          <a href='/ggtv/video-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/30.jpg' alt='post-30' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Movie News</span>
                <p class='post-preview-title'>"Tiger and Serpent" is a great choice for kids</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
        <div class='post-preview picture big gaming-news'>
          <a href='/news/news-slug'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='/img/posts/01.jpg' alt='post-01' />
              </figure>
              <div class='post-preview-img-overlay'></div>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>Gaming News</span>
                <p class='post-preview-title'>The Clash of Dragons is breaking record sales in USA and Japan</p>
                <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud citation loso
                  laboris tempora aliquip.</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div id='banner-slider-3-controls' class='banner-slider-controls'>
        <div class='control-previous'>
          <svg class='arrow-icon medium'>
            <use xlink:href='#svg-arrow-medium'></use>
          </svg>
        </div>
        <div class='control-next'>
          <svg class='arrow-icon medium'>
            <use xlink:href='#svg-arrow-medium'></use>
          </svg>
        </div>
      </div>
    </div>
    <div class='live-news-widget-wrap'>
      <div class='live-news-widget grid-limit'>
        <div class='live-news-widget-stairs left red'>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
        </div>
        <div class='live-news-widget-stairs right blue'>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
          <div class='live-news-widget-stair'></div>
        </div>
        <div class='live-news-widget-title-wrap'>
          <img class='live-news-widget-icon' src='/img/icons/live-news-icon.png' alt='live-news-icon' />
          <p class='live-news-widget-title'>Live News</p>
        </div>
        <div id='lineslide-wrap1' class='live-news-widget-text-wrap'>
          <p class='live-news-widget-text'></p>
        </div>
      </div>
    </div>
    <div class='layout-content-2 layout-item-1-2-1 grid-limit'>
      <div class='layout-sidebar1 layout-item gutter-medium'>
        <div class='layout-item post-preview-showcase'>
          <div class='post-preview gaming-news img-toggle'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/17.jpg' alt='post-17' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming news</a>
            <a href='/news/news-slug' class='post-preview-title'>Jazzstar announced that the GTE5 for PC is delayed</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview geeky-news img-toggle'>
            <a href='/esports/news/slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/02.jpg' alt='post-02' />
                </figure>
              </div>
            </a>
            <a href='/ggtv/' class='tag-ornament'>Geeky news</a>
            <a href='/esports/news/slug' class='post-preview-title'>Jessica Tam to star in the new "Charlotte" series</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview gaming-news img-toggle'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/09.jpg' alt='post-09' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming news</a>
            <a href='/news/news-slug' class='post-preview-title'>New "Rizen" game is gonna be released in summer 2019</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview game-review img-toggle'>
            <a href='/reviews/review-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/08.jpg' alt='post-08' />
                </figure>
                <div class='review-rating'>
                  <div id='content-news-rate-4' class='arc small'></div>
                </div>
              </div>
            </a>
            <a href='/reviews/' class='tag-ornament'>Game reviews</a>
            <a href='/reviews/review-slug' class='post-preview-title'>The new mecha cyborg game is breaking barriers</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview movie-news img-toggle'>
            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/12.jpg' alt='post-12' />
                </figure>
                <div class='rating-ornament'>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon empty'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon empty'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                </div>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Movie news</a>
            <a href='/ggtv/video-slug' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview e-sport img-toggle'>
            <a href='esports-post.html'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/46.jpg' alt='post-46' />
                </figure>
              </div>
            </a>
            <a href='esports-news.html' class='tag-ornament'>Esports</a>
            <a href='esports-post.html' class='post-preview-title'>Sarah Rodgers has broken the Legends world record!</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview geeky-news img-toggle'>
            <a href='/esports/news/slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/21.jpg' alt='post-21' />
                </figure>
              </div>
            </a>
            <a href='/ggtv/' class='tag-ornament'>Geeky news</a>
            <a href='/esports/news/slug' class='post-preview-title'>"Steamboat" anime will have a preview event on May</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview game-review img-toggle'>
            <a href='/reviews/review-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/10.jpg' alt='post-10' />
                </figure>
                <div class='review-rating'>
                  <div id='content-news-rate-8' class='arc small'></div>
                </div>
              </div>
            </a>
            <a href='/reviews/' class='tag-ornament'>Game reviews</a>
            <a href='/reviews/review-slug' class='post-preview-title'>Dragons and Knights arrived with a big impression</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
          <div class='post-preview e-sport img-toggle'>
            <a href='esports-post.html'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/40.jpg' alt='post-40' />
                </figure>
              </div>
            </a>
            <a href='esports-news.html' class='tag-ornament'>Esports</a>
            <a href='esports-post.html' class='post-preview-title'>The Siberian Tigers arrived at New York for the
              finals</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
          </div>
        </div>
      </div>
      <div class='layout-body layout-item centered'>
        <div class='layout-item'>
          <div class='post-preview big gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/13.webp' alt='post-13' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming news</a>
            <a href='/news/news-slug' class='post-preview-title'>The "Clash of Eternity" new game was just released</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/01.jpg' alt='user-01' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/news/news-slug#op-comments'
                  class='post-comment-count'
                >174 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur excepteur sint occaecat pidatat.</p>
          </div>
          <div class='post-preview big game-review'>
            <a href='/reviews/review-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/05.jpg' alt='post-05' />
                </figure>
                <div class='review-rating'>
                  <div id='content-news-rate-3' class='arc'></div>
                </div>
              </div>
            </a>
            <a href='/reviews/' class='tag-ornament'>Game Reviews</a>
            <a href='/reviews/review-slug' class='post-preview-title'>We reviewed the new and exciting Fantasy game
              "Olympus"</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/04.jpg' alt='user-04' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/reviews/review-slug#op-comments'
                  class='post-comment-count'
                >258 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur excepteur sint occaecat pidatat.</p>
          </div>
          <div class='post-preview big movie-news'>
            <a href='/ggtv/video-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/03.jpg' alt='post-03' />
                </figure>
                <div class='rating-ornament'>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon empty'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                  <div class='rating-ornament-item'>
                    <svg class='rating-ornament-icon empty'>
                      <use xlink:href='#svg-star'></use>
                    </svg>
                  </div>
                </div>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Movie News</a>
            <a href='/ggtv/video-slug' class='post-preview-title'>"The Sandbender II" break the bad sequel spell with a
              great movie</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/03.jpg' alt='user-03' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Greyson</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/ggtv/video-slug#op-comments'
                  class='post-comment-count'
                >174 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur excepteur sint occaecat pidatat.</p>
          </div>
          <div class='post-preview big geeky-news'>
            <a href='/esports/news/slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/18.jpg' alt='post-18' />
                </figure>
              </div>
            </a>
            <a href='/ggtv/' class='tag-ornament'>Geeky news</a>
            <a href='/esports/news/slug' class='post-preview-title'>"Ichigo Idol" anime will have a new season in April</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/04.jpg' alt='user-04' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/esports/news/slug#op-comments'
                  class='post-comment-count'
                >260 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur excepteur sint occaecat pidatat.</p>
          </div>
        </div>
        <div class='layout-item centered padded load-more-wrap'>
          <a href='/news/' class='button blue'>
            Read more news...
            <div class='button-ornament'>
              <svg class='arrow-icon medium'>
                <use xlink:href='#svg-arrow-medium'></use>
              </svg>
              <svg class='cross-icon small'>
                <use xlink:href='#svg-cross-small'></use>
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div class='layout-sidebar2 layout-item gutter-medium'>
        <div class='widget-sidebar'>
          <div class='stream-preview-showcase grid-1col centered gutter-small'>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-1'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-01-streamer.png'
                  alt='streambox-01-streamer'
                />
                <p class='stream-preview-streamer-name'>Game-Huntress</p>
                <p class='stream-preview-streamer-topic'>Let’s Play: Magimon Story Mode</p>
                <p class='stream-start-counter live'>Live</p>
              </div>
              <div class='widget-media small'>
                <iframe
                  src='https://player.twitch.tv/?autoplay=true&muted=true&t=01h01m43s&video=v415202048'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-2'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-02-streamer.png'
                  alt='streambox-02-streamer'
                />
                <p class='stream-preview-streamer-name'>Davikinger90</p>
                <p class='stream-preview-streamer-topic'>I’ll try all Xenowatch’s new skins</p>
                <p class='stream-start-counter'>Starts in:
                  <span id='sidebar-twitch-countdown-1' class='highlighted'></span></p>
              </div>
            </div>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-3'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-03-streamer.png'
                  alt='streambox-03-streamer'
                />
                <p class='stream-preview-streamer-name'>Markus_Snipes</p>
                <p class='stream-preview-streamer-topic'>The best sniper of all county!</p>
                <p class='stream-start-counter offline'>Offline</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Popular Posts</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-1col centered gutter-small'>
            <div class='post-preview tiny gaming-news'>
              <a href='/news/news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/01.jpg' alt='post-01' />
                  </figure>
                </div>
              </a>
              <a href='/news/news-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales in USA
                and Japan</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview tiny game-review'>
              <a href='/reviews/review-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/25.jpg' alt='post-25' />
                  </figure>
                  <div class='review-rating'>
                    <div id='sidebar-rate-1' class='arc tiny'></div>
                  </div>
                </div>
              </a>
              <a href='/reviews/review-slug' class='post-preview-title'>"Legend of Kenshii II" is a bit green for now</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny movie-news'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/12.jpg' alt='post-12' />
                  </figure>
                  <div class='rating-ornament'>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon empty'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon empty'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview tiny gaming-news'>
              <a href='/news/news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/17.jpg' alt='post-17' />
                  </figure>
                </div>
              </a>
              <a href='/news/news-slug' class='post-preview-title'>Jazzstar announced that the GTE5 for PC is delayed</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap red'>
            <h2 class='section-title medium'>Latest Reviews</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-1col centered gutter-small'>
            <div class='post-preview tiny game-review'>
              <a href='/reviews/review-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/16.jpg' alt='post-16' />
                  </figure>
                  <div class='review-rating'>
                    <div id='sidebar-rate-2' class='arc tiny'></div>
                  </div>
                </div>
              </a>
              <a href='/reviews/review-slug' class='post-preview-title'>We reviewed the new Magimons game</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny game-review'>
              <a href='/reviews/review-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/25.jpg' alt='post-25' />
                  </figure>
                  <div class='review-rating'>
                    <div id='sidebar-rate-3' class='arc tiny'></div>
                  </div>
                </div>
              </a>
              <a href='/reviews/review-slug' class='post-preview-title'>"Legend of Kenshii II" is a bit green for now</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny game-review'>
              <a href='/reviews/review-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/05.jpg' alt='post-05' />
                  </figure>
                  <div class='review-rating'>
                    <div id='sidebar-rate-4' class='arc tiny'></div>
                  </div>
                </div>
              </a>
              <a href='/reviews/review-slug' class='post-preview-title'>We reviewed the new and exciting fantasy game
                "Olympus"</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Morgana</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview tiny game-review'>
              <a href='/reviews/review-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/08.jpg' alt='post-08' />
                  </figure>
                  <div class='review-rating'>
                    <div id='sidebar-rate-5' class='arc tiny'></div>
                  </div>
                </div>
              </a>
              <a href='/reviews/review-slug' class='post-preview-title'>The new Mecha Cyborg game is breaking barriers</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap no-space violet'>
            <h2 class='section-title medium'>Esports Match</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Xenowatch league finals</h6>
              <p class='widget-text'>August 28th, 2018</p>
            </div>
            <div class='widget-team-vs'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='team-score'>12</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>Raging Rhinos</p>
                    <p class='team-country'>South Africa</p>
                  </div>
                </div>
                <p class='team-score'>10</p>
              </div>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Match Stats</h6>
              <div class='stats-reference'>
                <div class='stat-reference blue'>
                  <p class='stat-reference-text'>TLW</p>
                </div>
                <div class='stat-reference yellow'>
                  <p class='stat-reference-text'>RRH</p>
                </div>
              </div>
            </div>
            <div class='widget-team-stats bottom-spaced'>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Kills</p>
                <div id='sd-pg-1' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-kills-stat-sd1' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-kills-stat-sd1' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Deaths</p>
                <div id='sd-pg-2' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-deaths-stat-sd2' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-deaths-stat-sd2' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Assists</p>
                <div id='sd-pg-3' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-assists-stat-sd3' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-assists-stat-sd3' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap red'>
            <h2 class='section-title medium'>Instagram Widget</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='photo-list'></div>
        </div>
      </div>
    </div>
    <div class='section-bg'>
      <div class='layout-content-1 layout-item-3-1 padded-large grid-limit'>
        <div class='layout-body'>
          <div class='post-open game-review negative'>
            <div class='post-open-content v5'>
              <div class='post-open-body'>
                <div class='post-open-media-wrap'>
                  <div class='post-open-media'>
                    <iframe
                      src='https://www.youtube.com/embed/IoBOFTi-zAk'
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div class='tag-list'>
                  <a href='browse-videos.html' class='tag-ornament video'>Video</a>
                  <a href='/reviews/' class='tag-ornament'>Game Reviews</a>
                </div>
                <p class='post-open-title small'>We reviewed the new Magimons Game - Video Review</p>
                <div class='post-author-info-wrap'>
                  <a href='search-results.html'>
                    <figure class='user-avatar tiny liquid'>
                      <img src='/img/users/04.jpg' alt='user-04' />
                    </figure>
                  </a>
                  <p class='post-author-info small light'>By
                    <a href='search-results.html' class='post-author'>Vellatrix</a><span
                      class='separator'
                    >|</span>December 15th, 2018<span class='separator'>|</span><a
                      href='/reviews/review-slug#op-comments'
                      class='post-comment-count'
                    >130 Comments</a></p>
                </div>
                <p class='post-open-text'><span class='video-length'>03:22</span>In this video review you’ll be able to
                  see what works and what doesn’t in the new game. Lorem ipsum dolor sit amet, consectetur...</p>
                <div class='social-links'>
                  <a href='#' class='bubble-ornament fb'>
                    <svg class='facebook-icon'>
                      <use xlink:href='#svg-facebook'></use>
                    </svg>
                  </a>
                  <a href='#' class='bubble-ornament twt'>
                    <svg class='twitter-icon'>
                      <use xlink:href='#svg-twitter'></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='layout-sidebar'>
          <div class='post-preview-showcase grid-1col centered gutter-small'>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/25.jpg' alt='post-25' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>08:04</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Gameplay trailer for the new Legend of Kenji game</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video e-sport tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/38.jpg' alt='post-38' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>07:57</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Watch the best match of the Pro Soccer 2017 World
                Cup</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/31.jpg' alt='post-31' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>04:37</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Galaxy Adventure RX peek at the new weapons</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/07.jpg' alt='post-07' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>09:24</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>"Rise of Depredators" launched it's cinematic
                trailer</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video e-sport tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/41.jpg' alt='post-41' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>08:04</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Watch crowd highlights of the Xenowatch semifinals</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video geeky-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/02.jpg' alt='post-02' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>09:24</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>"Charlotte" released a new teaser for the new series</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video movie-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/12.jpg' alt='post-12' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>05:56</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Check out the new "Guardians of the Universe"
                trailer</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/01.jpg' alt='post-01' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>04:37</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Take a look at The Clash of Dragons new classes</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/09.jpg' alt='post-09' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use xlink:href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>05:56</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>5 minutes of the new "Rizen" game story mode</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
          </div>
          <a href='browse-videos.html' class='button small full cyan'>Browse more videos...</a>
        </div>
      </div>
    </div>
    <div class='layout-item padded-big own-grid grid-limit'>
      <div class='section-title-wrap blue'>
        <h2 class='section-title medium'>Gaming News</h2>
        <div class='section-title-separator'></div>
        <div id='gmnews-slider1-controls' class='carousel-controls slider-controls blue'>
          <div class='slider-control control-previous'>
            <svg class='arrow-icon medium'>
              <use xlink:href='#svg-arrow-medium'></use>
            </svg>
          </div>
          <div class='slider-control control-next'>
            <svg class='arrow-icon medium'>
              <use xlink:href='#svg-arrow-medium'></use>
            </svg>
          </div>
        </div>
      </div>
      <div id='gmnews-slider1' class='carousel big' {{carousel slideGap=30}}>
        <div id='gmnews-slider1-items' class='carousel-items'>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/13.webp' alt='post-13' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>The "Clash of Eternity" new game was just released</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna..</p>
          </div>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/20.jpg' alt='post-20' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>Legend of the Temple is coming to all consoles</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna.</p>
          </div>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/17.jpg' alt='post-17' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>Jazzstar announced that the GTE5 for PC is delayed</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna.</p>
          </div>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/01.jpg' alt='post-01' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna.</p>
          </div>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/09.jpg' alt='post-09' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>New "Rizen" game is gonna be released in summer 2019</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna.</p>
          </div>
          <div class='post-preview gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/07.jpg' alt='post-07' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>New Expansion Pack coming to "Rise of Depredators"</a>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018</p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
              tempor lorem incididunt ut labore et dolore magna.</p>
          </div>
        </div>
      </div>
    </div>
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body layout-item'>
        <div class='layout-item'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Latest News</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview landscape big gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/01.jpg' alt='post-01' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales in Japan</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/01.jpg' alt='user-01' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/news/news-slug#op-comments'
                  class='post-comment-count'
                >174 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore...</p>
          </div>
          <div class='post-preview landscape big e-sport'>
            <a href='esports-post.html'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/45.jpg' alt='post-45' />
                </figure>
              </div>
            </a>
            <a href='esports-news.html' class='tag-ornament'>Esports</a>
            <a href='esports-post.html' class='post-preview-title'>Thomas Howlett joins the Wolves Xenowatch esports
              team</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/08.jpg' alt='user-08' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Morgana</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='esports-post.html#op-comments'
                  class='post-comment-count'
                >342 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore...</p>
          </div>
          <div class='post-preview landscape big gaming-news'>
            <a href='/news/news-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/27.jpg' alt='post-27' />
                </figure>
              </div>
            </a>
            <a href='/news/' class='tag-ornament'>Gaming News</a>
            <a href='/news/news-slug' class='post-preview-title'>Everything about kawai party 8!</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/01.jpg' alt='user-01' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/news/news-slug#op-comments'
                  class='post-comment-count'
                >174 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore...</p>
          </div>
          <div class='post-preview landscape big game-review'>
            <a href='/reviews/review-slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/16.jpg' alt='post-16' />
                </figure>
                <div class='review-rating'>
                  <div id='content-news-rate-9' class='arc'></div>
                </div>
              </div>
            </a>
            <a href='/reviews/' class='tag-ornament'>Game Review</a>
            <a href='/reviews/review-slug' class='post-preview-title'>We reviewed the new Magimons game</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/08.jpg' alt='user-08' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/reviews/review-slug#op-comments'
                  class='post-comment-count'
                >258 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore...</p>
          </div>
          <div class='post-preview landscape big geeky-news'>
            <a href='/esports/news/slug'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='/img/posts/21.jpg' alt='post-21' />
                </figure>
              </div>
            </a>
            <a href='/ggtv/' class='tag-ornament'>Geeky News</a>
            <a href='/esports/news/slug' class='post-preview-title'>"Steamboat" anime will have a preview event in May</a>
            <div class='post-author-info-wrap'>
              <a href='search-results.html'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/08.jpg' alt='user-08' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='search-results.html' class='post-author'>Morgana</a><span class='separator'>|</span>December
                15th, 2018<span class='separator'>|</span><a
                  href='/esports/news/slug#op-comments'
                  class='post-comment-count'
                >342 Comments</a></p>
            </div>
            <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris
              tempora aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore...</p>
          </div>
          <div class='page-navigation blue padded right'>
            <div class='slider-control big control-previous'>
              <svg class='arrow-icon medium'>
                <use xlink:href='#svg-arrow-medium'></use>
              </svg>
            </div>
            <a href='#' class='page-navigation-item'>1</a>
            <a href='#' class='page-navigation-item active'>2</a>
            <a href='#' class='page-navigation-item'>3</a>
            <a href='#' class='page-navigation-item'>...</a>
            <a href='#' class='page-navigation-item'>8</a>
            <div class='slider-control big control-next'>
              <svg class='arrow-icon medium'>
                <use xlink:href='#svg-arrow-medium'></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <PixelPoll />
        <div class='widget-sidebar'>
          <div class='promo-advert'>
            <a href='#'>
              <img class='promo-advert-img' src='/img/banners/promo-ad-1.jpg' alt='promo-ad-1' />
            </a>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Top 5 news V2</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-1col gutter-small centered'>
            <div class='post-preview tiny featured gaming-news'>
              <div class='bubble-ornament medium black no-link'>
                <p class='bubble-ornament-info'>01</p>
              </div>
              <a href='/news/news-slug' class='post-preview-title'>The Clash of Dragons is breaking record sales in USA
                and Japan</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview tiny featured geeky-news'>
              <div class='bubble-ornament medium black no-link'>
                <p class='bubble-ornament-info'>02</p>
              </div>
              <a href='/esports/news/slug' class='post-preview-title'>Jessica Tam to star in the new "Charlotte" series</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny featured e-sport'>
              <div class='bubble-ornament medium black no-link'>
                <p class='bubble-ornament-info'>03</p>
              </div>
              <a href='esports-post.html' class='post-preview-title'>The Xenowatch semifinals were truly incredible</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
            <div class='post-preview tiny featured game-review'>
              <div class='bubble-ornament medium black no-link'>
                <p class='bubble-ornament-info'>04</p>
              </div>
              <a href='/reviews/review-slug' class='post-preview-title'>"Legend of Kenshi II" is a bit green for now</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec
                  15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny featured movie-news'>
              <div class='bubble-ornament medium black no-link'>
                <p class='bubble-ornament-info'>05</p>
              </div>
              <a href='/ggtv/video-slug' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='search-results.html' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th,
                  2018</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Calendar</h2>
            <div class='section-title-separator'></div>
          </div>
          <div id='calendar-sidebar' class='calendar small blue'>
            <div class='calendar-header'>
              <div class='calendar-header-top'>
                <div id='calendar1-controls' class='calendar-controls slider-controls blue'>
                  <div class='slider-control control-previous'>
                    <svg class='arrow-icon medium'>
                      <use xlink:href='#svg-arrow-medium'></use>
                    </svg>
                  </div>
                  <div class='slider-control control-next'>
                    <svg class='arrow-icon medium'>
                      <use xlink:href='#svg-arrow-medium'></use>
                    </svg>
                  </div>
                </div>
                <p class='calendar-month-year'><span class='calendar-month'></span><span
                    class='calendar-year'
                  ></span></p>
              </div>
              <div class='calendar-header-bottom'></div>
            </div>
            <div class='calendar-body'></div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(GameIndexRouteComponent);
