import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'>
      <h2 class='section-title medium'>Pixel Tags</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='tag-list'>
      <a href='/news' class='tag-item'>Gaming</a>
      <a href='/search' class='tag-item'>Video Reviews</a>
      <a href='/search?tag=previews' class='tag-item'>Previews</a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
