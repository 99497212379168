import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-item'>
    <div class='section-title-wrap violet'>
      <h2 class='section-title medium'>Upcoming Matches</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='widget-match-box multiple-items'>
      <div class='widget-team-vs v2 medium'>
        <div class='widget-team-vs-item'>
          <div class='team-info-wrap medium'>
            <img class='team-logo medium' src='/img/teams/logos/01.png' alt='logo-01' />
            <div class='team-info'>
              <p class='team-name'>The Lone Wolves</p>
              <p class='team-country'>United States</p>
            </div>
          </div>
        </div>
        <div class='match-stats'>
          <p class='match-score medium match-vs'>VS</p>
          <p class='match-time medium'>August 28th, 2018<br />8:00PCT</p>
        </div>
        <div class='widget-team-vs-item'>
          <div class='team-info-wrap medium inverted'>
            <img class='team-logo medium' src='/img/teams/logos/02.png' alt='logo-02' />
            <div class='team-info'>
              <p class='team-name'>Raging Rhinos</p>
              <p class='team-country'>South Africa</p>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-info-box'>
        <div class='widget-info-box-item'>
          <p class='widget-info-title'>Xenowatch League Finals</p>
          <p class='widget-info-text'>Quarters - Week 5 Day 2</p>
        </div>
        <div class='widget-info-box-item'>
          <div class='countdown-text-wrap small'>
            <p class='countdown-text-heading'>Starts In:</p>
            <p id='ew1-match-cd-text' class='countdown-text'></p>
            <div id='ew1-match-cd-pgb' class='progress-bar'></div>
          </div>
        </div>
      </div>
    </div>
    <div class='table matches'>
      <div class='table-rows'>
        <div class='table-row no-border-top'>
          <div class='table-row-item'>
            <p class='table-text bold'>04/06/2018</p>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
              <div class='team-info'>
                <p class='team-name'>Crimson Pack</p>
                <p class='team-country'>Japan</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='match-stats'>
              <p class='match-score smaller'>vs</p>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap inverted'>
              <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
              <div class='team-info'>
                <p class='team-name'>Siberian Tigers</p>
                <p class='team-country'>Russia</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>Quarters - Week 5 Day 3</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>9:30PM CPT</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item'>
            <p class='table-text bold'>04/10/2018</p>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
              <div class='team-info'>
                <p class='team-name'>The Lone Wolves</p>
                <p class='team-country'>United States</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='match-stats'>
              <p class='match-score smaller'>vs</p>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap inverted'>
              <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
              <div class='team-info'>
                <p class='team-name'>Los Toreros</p>
                <p class='team-country'>Spain</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>Quarters - Week 5 Day 4</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>10:00PM CPT</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item'>
            <p class='table-text bold'>04/12/2018</p>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
              <div class='team-info'>
                <p class='team-name'>Raging Rhinos</p>
                <p class='team-country'>South Africa</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='match-stats'>
              <p class='match-score smaller'>vs</p>
            </div>
          </div>
          <div class='table-row-item'>
            <div class='team-info-wrap inverted'>
              <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
              <div class='team-info'>
                <p class='team-name'>The Lone Wolves</p>
                <p class='team-country'>United States</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>Quarters - Week 6 Day 1</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>9:30PM CPT</p>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
