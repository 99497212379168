import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <form class='sidebar-search-form'>
      <div class='submit-input full blue'>
        <input type='text' id='sidebar-search1' name='sidebar-search1' placeholder='Search articles here...' />
        <button class='submit-input-button'>
          <svg class='search-icon small'>
            <use href='#svg-search'></use>
          </svg>
        </button>
      </div>
    </form>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
