import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { NEWS_POST_QUERY } from 'embroil/gql/queries/news-post';
import { service } from '@ember/service';

export default class Post extends Route {
  @service headData;

  async model({ slug }) {
    this.data = useQuery(this, () => [
      NEWS_POST_QUERY,
      { variables: { slug } },
    ]);

    await this.data.promise;
    return this.data.data.article.items[0];
  }

  afterModel(model) {
    if (model) {
      this.headData.title = model.title;
      this.headData.description = model.shortDescription;
      this.headData.image = model.coverImage.url;
      this.headData.canonicalURL = `https://1vX.gg/esports/news/${model.slug}`;
    }
  }
}
