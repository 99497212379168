import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
let AboutRouteComponent = class AboutRouteComponent extends Component {
    static{
        template(`
    {{#each @model.recentArticles.items as |article|}}
      <h1>{{article.title}}</h1>
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(AboutRouteComponent);
