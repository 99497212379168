import { gql } from 'glimmer-apollo';

export const NEWS_POST_QUERY = gql`
  query ($slug: String) {
    article: gameArticleCollection(where: { slug: $slug }) {
      items {
        title
        slug
        shortDescription
        publishedDate
        body
        content {
          json
        }
        coverImage {
          url
        }
        author {
          displayName
          bioBlurb
          socialLinks: socialLinksCollection(limit: 10) {
            items {
              url
              platform
              accessibilityDescription
            }
          }
          profilePicture {
            url
          }
        }
        relatedArticles: relatedArticlesCollection(limit: 4) {
          items {
            __typename
            ... on ESportsArticle {
              title
              shortDescription
              slug
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              shortDescription
              slug
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
