import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap red'>
      <h2 class='section-title medium'>Pixelated Poll</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='poll-widget'>
      <p class='poll-widget-title'>What actor do you like to play "James" in the
        upcoming Firestorm movie?</p>
      <form class='poll-widget-form'>
        <div class='radio-item-list'>
          <div class='radio-item'>
            <input type='radio' id='poll-val-1' name='poll-actor' value='scj' />
            <div class='radio-circle red'></div>
            <label for='poll-val-1' class='rl-label'>Stephen Clark Jones</label>
          </div>
          <div class='radio-item'>
            <input
              type='radio'
              id='poll-val-2'
              name='poll-actor'
              value='dr'
              checked
            />
            <div class='radio-circle red'></div>
            <label for='poll-val-2' class='rl-label'>Derek Richardson</label>
          </div>
          <div class='radio-item'>
            <input type='radio' id='poll-val-3' name='poll-actor' value='js' />
            <div class='radio-circle red'></div>
            <label for='poll-val-3' class='rl-label'>Jhonatan Specter</label>
          </div>
          <div class='radio-item'>
            <input
              type='radio'
              id='poll-val-4'
              name='poll-actor'
              value='rdjr'
            />
            <div class='radio-circle red'></div>
            <label for='poll-val-4' class='rl-label'>Roberts Dauristen Jr.</label>
          </div>
        </div>
        <div class='poll-widget-form-actions'>
          <button class='button small red'>Submit Vote</button>
          <button class='button small blue'>View Results</button>
        </div>
      </form>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
