
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("embroil/apollo", function(){ return i("embroil/apollo.ts");});
d("embroil/app", function(){ return i("embroil/app.ts");});
d("embroil/config/environment", function(){ return i("embroil/config/environment.js");});
d("embroil/gql/queries/components/esports-banner", function(){ return i("embroil/gql/queries/components/esports-banner.ts");});
d("embroil/gql/queries/components/esports-tournament-banner", function(){ return i("embroil/gql/queries/components/esports-tournament-banner.ts");});
d("embroil/gql/queries/components/news-slider", function(){ return i("embroil/gql/queries/components/news-slider.ts");});
d("embroil/gql/queries/esports-game", function(){ return i("embroil/gql/queries/esports-game.ts");});
d("embroil/gql/queries/esports-index", function(){ return i("embroil/gql/queries/esports-index.ts");});
d("embroil/gql/queries/esports-match", function(){ return i("embroil/gql/queries/esports-match.ts");});
d("embroil/gql/queries/esports-player", function(){ return i("embroil/gql/queries/esports-player.ts");});
d("embroil/gql/queries/esports-post", function(){ return i("embroil/gql/queries/esports-post.ts");});
d("embroil/gql/queries/esports-team", function(){ return i("embroil/gql/queries/esports-team.ts");});
d("embroil/gql/queries/esports-tournament", function(){ return i("embroil/gql/queries/esports-tournament.ts");});
d("embroil/gql/queries/home-page", function(){ return i("embroil/gql/queries/home-page.ts");});
d("embroil/gql/queries/news-index", function(){ return i("embroil/gql/queries/news-index.ts");});
d("embroil/gql/queries/news-post", function(){ return i("embroil/gql/queries/news-post.ts");});
d("embroil/gql/queries/reviews-index", function(){ return i("embroil/gql/queries/reviews-index.ts");});
d("embroil/gql/queries/weewoo", function(){ return i("embroil/gql/queries/weewoo.ts");});
d("embroil/instance-initializers/apollo", function(){ return i("embroil/instance-initializers/apollo.js");});
d("embroil/packages/mobile-menu", function(){ return i("embroil/packages/mobile-menu.js");});
d("embroil/packages/xm-arc", function(){ return i("embroil/packages/xm-arc.js");});
d("embroil/packages/xm-dropdown", function(){ return i("embroil/packages/xm-dropdown.js");});
d("embroil/packages/xm-progress", function(){ return i("embroil/packages/xm-progress.js");});
d("embroil/router", function(){ return i("embroil/router.ts");});
d("embroil/services/auth", function(){ return i("embroil/services/auth.ts");});
d("embroil/services/current-user", function(){ return i("embroil/services/current-user.ts");});
d("embroil/services/live-streams", function(){ return i("embroil/services/live-streams.js");});
d("embroil/services/metrics", function(){ return i("embroil/services/metrics.js");});
d("embroil/services/routing", function(){ return i("embroil/services/routing.js");});
d("embroil/supabase", function(){ return i("embroil/supabase.ts");});
d("embroil/services/keyboard", function(){ return i("embroil/services/keyboard.js");});
d("embroil/services/page-title", function(){ return i("embroil/services/page-title.js");});
d("embroil/initializers/set-glimmer-apollo-environment-ember", function(){ return i("embroil/initializers/set-glimmer-apollo-environment-ember.js");});
d("embroil/component-managers/glimmer", function(){ return i("embroil/component-managers/glimmer.js");});
d("embroil/instance-initializers/sentry-performance", function(){ return i("embroil/instance-initializers/sentry-performance.js");});
d("embroil/initializers/app-version", function(){ return i("embroil/initializers/app-version.js");});
d("embroil/instance-initializers/head-browser", function(){ return i("embroil/instance-initializers/head-browser.js");});
d("embroil/services/head-data", function(){ return i("embroil/services/head-data.js");});
d("embroil/data-adapter", function(){ return i("embroil/data-adapter.js");});
d("embroil/initializers/ember-data", function(){ return i("embroil/initializers/ember-data.js");});
d("embroil/services/store", function(){ return i("embroil/services/store.js");});
d("embroil/transforms/boolean", function(){ return i("embroil/transforms/boolean.js");});
d("embroil/transforms/date", function(){ return i("embroil/transforms/date.js");});
d("embroil/transforms/number", function(){ return i("embroil/transforms/number.js");});
d("embroil/transforms/string", function(){ return i("embroil/transforms/string.js");});
d("embroil/services/-ensure-registered", function(){ return i("embroil/services/-ensure-registered.js");});
d("embroil/container-debug-adapter", function(){ return i("embroil/container-debug-adapter.js");});
d("embroil/templates/about", function(){ return i("embroil/templates/about.js");});
d("embroil/routes/about", function(){ return i("embroil/routes/about.js");});
d("embroil/templates/application", function(){ return i("embroil/templates/application.js");});
d("embroil/routes/application", function(){ return i("embroil/routes/application.js");});
d("embroil/templates/esports", function(){ return i("embroil/templates/esports.js");});
d("embroil/templates/esports/game", function(){ return i("embroil/templates/esports/game.js");});
d("embroil/routes/esports/game", function(){ return i("embroil/routes/esports/game.js");});
d("embroil/templates/esports/index", function(){ return i("embroil/templates/esports/index.js");});
d("embroil/routes/esports/index", function(){ return i("embroil/routes/esports/index.js");});
d("embroil/templates/esports/matches/match", function(){ return i("embroil/templates/esports/matches/match.js");});
d("embroil/routes/esports/matches/match", function(){ return i("embroil/routes/esports/matches/match.js");});
d("embroil/templates/esports/player", function(){ return i("embroil/templates/esports/player.js");});
d("embroil/routes/esports/player", function(){ return i("embroil/routes/esports/player.js");});
d("embroil/templates/esports/post", function(){ return i("embroil/templates/esports/post.js");});
d("embroil/routes/esports/post", function(){ return i("embroil/routes/esports/post.js");});
d("embroil/templates/esports/team", function(){ return i("embroil/templates/esports/team.js");});
d("embroil/routes/esports/team", function(){ return i("embroil/routes/esports/team.js");});
d("embroil/templates/esports/tournament", function(){ return i("embroil/templates/esports/tournament.js");});
d("embroil/routes/esports/tournament", function(){ return i("embroil/routes/esports/tournament.js");});
d("embroil/templates/index", function(){ return i("embroil/templates/index.js");});
d("embroil/routes/index", function(){ return i("embroil/routes/index.js");});
d("embroil/templates/news/index", function(){ return i("embroil/templates/news/index.js");});
d("embroil/routes/news/index", function(){ return i("embroil/routes/news/index.js");});
d("embroil/templates/news/post", function(){ return i("embroil/templates/news/post.js");});
d("embroil/routes/news/post", function(){ return i("embroil/routes/news/post.js");});
d("embroil/templates/reviews/index", function(){ return i("embroil/templates/reviews/index.js");});
d("embroil/routes/reviews/index", function(){ return i("embroil/routes/reviews/index.js");});
d("embroil/templates/reviews/post", function(){ return i("embroil/templates/reviews/post.js");});
d("embroil/routes/reviews/post", function(){ return i("embroil/routes/reviews/post.js");});
d("embroil/templates/404", function(){ return i("embroil/templates/404.js");});
d("embroil/templates/game", function(){ return i("embroil/templates/game.js");});
d("embroil/templates/ggtv/index", function(){ return i("embroil/templates/ggtv/index.js");});
d("embroil/templates/ggtv/video", function(){ return i("embroil/templates/ggtv/video.js");});
d("embroil/templates/head", function(){ return i("embroil/templates/head.hbs");});
d("embroil/templates/privacy-policy", function(){ return i("embroil/templates/privacy-policy.js");});
d("embroil/templates/search", function(){ return i("embroil/templates/search.js");});
d("embroil/templates/streamer", function(){ return i("embroil/templates/streamer.js");});
d("embroil/templates/streamers", function(){ return i("embroil/templates/streamers.js");});
d("embroil/templates/terms-and-conditions", function(){ return i("embroil/templates/terms-and-conditions.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("embroil/instance-initializers/setup-fetch", function(){ return i("embroil/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"embroil","version":"0.0.0+76f3997f"});
}

