'use strict';
function _typeof(o) {
  return (_typeof =
    'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
      ? function (o) {
          return typeof o;
        }
      : function (o) {
          return o &&
            'function' == typeof Symbol &&
            o.constructor === Symbol &&
            o !== Symbol.prototype
            ? 'symbol'
            : typeof o;
        })(o);
}
export default function XMDropdown(o) {
  var e = {
      triggerEvent: 'click',
      offset: {},
      animation: { type: 'slide', speed: 400 },
      selectable: !1,
      forceClose: !1,
    },
    n = this,
    t = function () {
      (n.dropdownItem = document.querySelector(e.dropdownSelector)),
        m(n.dropdownItem, e.dropdownSelector),
        e.dropdownContainer
          ? ((n.dropdownContainerItem = document.querySelector(
              e.dropdownContainer,
            )),
            m(n.dropdownContainerItem, e.dropdownContainer))
          : (n.dropdownContainerItem = n.dropdownItem.parentNode),
        (n.open = !1);
    },
    r = function () {
      e.absolute || (n.dropdownContainerItem.style.position = 'relative'),
        (n.dropdownItem.style.position = 'absolute'),
        (n.dropdownItem.style.zIndex = 9999),
        void 0 !== e.offset.top &&
          (n.dropdownItem.style.top = '-'.concat(e.offset.top / 3, 'px')),
        void 0 !== e.offset.left &&
          (n.dropdownItem.style.left = ''.concat(e.offset.left, 'px')),
        void 0 !== e.offset.right &&
          (n.dropdownItem.style.right = ''.concat(e.offset.right, 'px')),
        (n.dropdownItem.style.visibility = 'hidden'),
        (n.dropdownItem.style.opacity = 0);
    },
    i = function () {
      n.dropdownItem.style.transition = 'all '.concat(
        e.animation.speed / 1e3,
        's ease-in-out',
      );
    },
    d = function () {
      (n.handlerItem = document.querySelector(e.dropdownHandler)),
        m(n.handlerItem, e.dropdownHandler),
        e.forceClose
          ? n.handlerItem.addEventListener(e.triggerEvent, c)
          : n.handlerItem.addEventListener(e.triggerEvent, f);
    },
    p = function () {
      (n.optionItems = n.dropdownItem.querySelectorAll(e.options.container)),
        (n.currentOption = document.querySelector(e.options.current));
      for (var o = 0, t = n.optionItems.length; o < t; o++)
        n.optionItems[o].addEventListener('click', s);
    },
    s = function () {
      n.currentOption.innerHTML = this.innerHTML;
    },
    c = function () {
      n.open ? a() : f();
    },
    f = function () {
      n.open ||
        ((n.dropdownItem.style.top = ''.concat(e.offset.top, 'px')),
        (n.dropdownItem.style.visibility = 'visible'),
        (n.dropdownItem.style.opacity = 1),
        (n.open = !0),
        e.forceClose ||
          window.setTimeout(function () {
            window.addEventListener(e.triggerEvent, a);
          }, e.animation.speed));
    },
    a = function o() {
      n.open &&
        ((n.dropdownItem.style.top = '-'.concat(e.offset.top / 3, 'px')),
        (n.dropdownItem.style.visibility = 'hidden'),
        (n.dropdownItem.style.opacity = 0),
        (n.open = !1),
        e.forceClose || window.removeEventListener(e.triggerEvent, o));
    },
    l = function () {
      if (e.breakpoints) {
        var o = 1 / 0;
        for (var t in e.breakpoints)
          window.innerWidth <= t && (o = t < o ? t : o);
        o !== 1 / 0 &&
          (void 0 !== e.breakpoints[o].offset.top &&
            (n.dropdownItem.style.top = '-'.concat(
              e.breakpoints[o].offset.top / 3,
              'px',
            )),
          void 0 !== e.breakpoints[o].offset.left &&
            (n.dropdownItem.style.left = ''.concat(
              e.breakpoints[o].offset.left,
              'px',
            )));
      }
    },
    m = function (o, t) {
      var e = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
      if (!o)
        throw e
          ? new Error("Can't find ".concat(t, ' inside ').concat(e))
          : new Error("Can't find ".concat(t, ' in the DOM'));
    };
  (function o(t, e) {
    var n;
    for (var r in e)
      'object' !== _typeof(e[r]) || e[r] instanceof Date
        ? e[r] instanceof Date
          ? (t[r] = new Date(e[r].getTime()))
          : (t[r] = e[r])
        : ((n = e[r] instanceof Array ? [] : {}),
          void 0 === t[r] && (t[r] = n),
          o(t[r], e[r]));
  })(e, o),
    t(),
    r(),
    i(),
    d(),
    e.selectable && p(),
    l();
}
