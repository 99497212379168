import { gql } from 'glimmer-apollo';

export const NEWS_SLIDER_QUERY = gql`
  query ($slug: String) {
    featured: featuredBlockCollection(where: { slug: $slug }, limit: 1) {
      items {
        articles: articlesCollection(limit: 4) {
          items {
            __typename
            ... on ESportsArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
            ... on GameArticle {
              title
              slug
              shortDescription
              publishedDate
              coverImage {
                url
              }
              author {
                displayName
                slug
                profilePicture {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
