import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import MarkdownIt from 'markdown-it';
const md = new MarkdownIt({
    html: true,
    breaks: true
});
export default class Markdown extends Component {
    get html() {
        return md.render(this.args.markdown);
    }
    static{
        template(`
    {{{this.html}}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
