import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { formatDate } from '../../../helpers/format-date';
import { articleType, eq } from '../../../helpers/article-type';
export default class ArticlesList extends Component {
    get firstThree() {
        return this.args.articles.slice(0, 3);
    }
    get lastFive() {
        return this.args.articles.slice(3, 8);
    }
    static{
        template(`
    <div class='layout-item-2-1'>
      <div class='layout-item'>
        {{#each this.firstThree as |article|}}
          <div
            class='post-preview big
              {{if (eq (articleType article.__typename) "esports") "e-sport"}}
              {{if (eq (articleType article.__typename) "news") "gaming-news"}}'
          >
            <LinkTo @route='{{articleType article.__typename}}.post' @model='{{article.slug}}'>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{article.coverImage.url}}' alt='{{article.title}}' />
                </figure>
              </div>
            </LinkTo>
            <LinkTo @route='news' class='tag-ornament'>
              {{if (eq (articleType article.__typename) 'esports') 'eSports'}}
              {{if (eq (articleType article.__typename) 'news') 'Gaming News'}}
            </LinkTo>
            <LinkTo
              @route='{{articleType article.__typename}}.post'
              @model='{{article.slug}}'
              class='post-preview-title'
            >{{article.title}}</LinkTo>
            <div class='post-author-info-wrap'>
              <a href='/search'>
                <figure class='user-avatar tiny liquid'>
                  <img src='{{article.author.profilePicture.url}}' alt='{{article.author.displayName}}' />
                </figure>
              </a>
              <p class='post-author-info small light'>
                By
                <a href='/search' class='post-author'>{{article.author.displayName}}</a>
                <span class='separator'>|</span>{{formatDate article.publishedDate}}
              </p>
            </div>
            <p class='post-preview-text'>{{article.shortDescription}}</p>
          </div>
        {{/each}}
      </div>
      <div class='layout-item'>
        {{#each this.lastFive as |article|}}
          <div class='post-preview gaming-news img-toggle'>
            <LinkTo @route='{{articleType article.__typename}}.post' @model={{article.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{article.coverImage.url}}' alt='{{article.title}}' />
                </figure>
              </div>
            </LinkTo>
            <LinkTo @route='news.index' class='tag-ornament'>Gaming news</LinkTo>
            <LinkTo
              @route='{{articleType article.__typename}}.post'
              @model={{article.slug}}
              class='post-preview-title'
            >{{article.title}}</LinkTo>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>
                By
                <a href='/search' class='post-author'>{{article.author.displayName}}</a>
                <span class='separator'>|</span>{{formatDate article.publishedDate}}
              </p>
            </div>
            <p class='post-preview-text'>
              {{article.shortDescription}}
            </p>
          </div>
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
const one = template(`
  <div class='post-preview big game-review'>
    <a href='/reviews/review-article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/05.jpg' alt='post-05' /></figure>
        <div class='review-rating'>
          <div id='content-news-rate-3' class='arc'></div>
        </div>
      </div>
    </a>
    <a href='/reviews' class='tag-ornament'>Game Reviews</a>
    <a href='/reviews/review-article-slug' class='post-preview-title'>We reviewed the new and exciting Fantasy game
      "Olympus"</a>
    <div class='post-author-info-wrap'><a href='/search'>
        <figure class='user-avatar tiny liquid'><img src='/img/users/04.jpg' alt='user-04' /></figure>
      </a>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018<span
          class='separator'
        >|</span><a href='/reviews/review-slug#op-comments' class='post-comment-count'>258 Comments</a></p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris tempora
      aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
      fugiat nulla pariatur excepteur sint occaecat pidatat.</p></div>
  <div class='post-preview big gaming-news'>
    <a href='/news/article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/27.jpg' alt='post-27' /></figure>
      </div>
    </a>
    <a href='/news/article-slug' class='tag-ornament'>Gaming news</a>
    <a href='/news/article-slug' class='post-preview-title'>Everything about Kawai Party 8!</a>
    <div class='post-author-info-wrap'><a href='/search'>
        <figure class='user-avatar tiny liquid'><img src='/img/users/01.jpg' alt='user-01' /></figure>
      </a>
      <p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>December 15th, 2018<span
          class='separator'
        >|</span><a href='/news/article-slug#op-comments' class='post-comment-count'>174 Comments</a></p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
      incididunt dutor et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation loso laboris tempora
      aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
      fugiat nulla pariatur excepteur sint occaecat pidatat.</p></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const two = template(`
  <div class='post-preview geeky-news img-toggle'>
    <a href='post-v4.html'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/02.jpg' alt='post-02' /></figure>
      </div>
    </a>
    <a href='news-v4.html' class='tag-ornament'>Geeky news</a>
    <a href='post-v4.html' class='post-preview-title'>Jessica Tam to star in the new "Charlotte" series</a>
    <div class='post-author-info-wrap'><p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod tempor
      lorem incididunt ut labore et dolore magna.</p></div>
  <div class='post-preview gaming-news img-toggle'>
    <a href='/news/article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/09.jpg' alt='post-09' /></figure>
      </div>
    </a>
    <a href='/news/article-slug' class='tag-ornament'>Gaming news</a>
    <a href='/news/article-slug' class='post-preview-title'>New "Rizen" game is gonna be released in summer 2019</a>
    <div class='post-author-info-wrap'><p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod tempor
      lorem incididunt ut labore et dolore magna.</p></div>
  <div class='post-preview game-review img-toggle'>
    <a href='/reviews/review-article-slug'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/08.jpg' alt='post-08' /></figure>
        <div class='review-rating'>
          <div id='content-news-rate-4' class='arc small'></div>
        </div>
      </div>
    </a>
    <a href='/reviews' class='tag-ornament'>Game reviews</a>
    <a href='/reviews/review-article-slug' class='post-preview-title'>The new mecha cyborg game is breaking barriers</a>
    <div class='post-author-info-wrap'><p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod tempor
      lorem incididunt ut labore et dolore magna.</p></div>
  <div class='post-preview movie-news img-toggle'>
    <a href='post-v3.html'>
      <div class='post-preview-img-wrap'>
        <figure class='post-preview-img liquid'><img src='/img/posts/12.jpg' alt='post-12' /></figure>
        <div class='rating-ornament'>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon empty'>
              <use href='#svg-star'></use>
            </svg>
          </div>
          <div class='rating-ornament-item'>
            <svg class='rating-ornament-icon empty'>
              <use href='#svg-star'></use>
            </svg>
          </div>
        </div>
      </div>
    </a>
    <a href='news-v3.html' class='tag-ornament'>Movie news</a>
    <a href='post-v3.html' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
    <div class='post-author-info-wrap'><p class='post-author-info small light'>By
        <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p></div>
    <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod tempor
      lorem incididunt ut labore et dolore magna.</p></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
