import { modifier } from 'ember-modifier';
import XMSlider from '../helpers/xm-slider';

export default modifier((element, [eventName]) => {
  new XMSlider({
    sliderContainer: '#banner-slider-2',
    rosterContainer: '.banner-slider-preview-roster',
    controls: {
      container: '#sliderb2-controls',
    },
    animation: {
      type: 'carousel',
      orientation: 'horizontal',
      transition: 'direct',
      slideDelay: 6000,
      slideTransition: 700,
    },
  });
  new XMSlider({
    sliderContainer: '#banner-slider-2-thumbs',
    itemsContainer: '.banner-slider-preview-roster',
    rosterContainer: false,
    slideOnClick: true,
    loop: true,
    loopOffset: 1,
    controls: {
      container: '#sliderb2-controls',
    },
    animation: {
      type: 'carousel',
      orientation: 'horizontal',
      slideDelay: 6000,
      slideTransition: 700,
      slideGap: 146,
    },
  });

  /* new XMSlider({
    sliderContainer: '#lvideos-slider1',
    itemsContainer: '.carousel-items',
    rosterContainer: false,
    autoSlide: false,
    animation: {
      type: 'carousel',
      orientation: 'horizontal',
      transition: 'direct',
      slideDelay: 7000,
      slideTransition: 700,
      slideGap: 30,
      slideCount: 1,
    },
    controls: {
      container: '#lvideos-slider1-controls',
    },
  });*/
});
