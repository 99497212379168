import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'><h2 class='section-title medium'>Latest Comments</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='comment-preview-showcase grid-1col centered gutter-medium'>
      <div class='comment-preview'><a href='post-v4.html#op-comments'>
          <img class='user-avatar' src='/img/users/06.jpg' alt='user-06' />
        </a>
        <a href='post-v4.html#op-comments' class='comment-preview-title'>Jessica Croft</a>
        <a href='post-v4.html' class='comment-preview-link yellow'>Jessica Tam to star in the new...</a>
        <p class='comment-preview-text'>Lorem ipsum dolor sit tameteturre adipisicing elit, incididunt...</p></div>
      <div class='comment-preview'><a href='/news/article-slug#op-comments'>
          <img class='user-avatar' src='/img/users/07.jpg' alt='user-07' />
        </a>
        <a href='/news/article-slug#op-comments' class='comment-preview-title'>Nathan Thompson</a>
        <a href='/news/article-slug' class='comment-preview-link blue'>The Clash of Dragons is breaking...</a>
        <p class='comment-preview-text'>Lorem ipsum dolor sit ameteturre adipisicing elit...</p></div>
      <div class='comment-preview'><a href='post-v3.html#op-comments'>
          <img class='user-avatar' src='/img/users/02.jpg' alt='user-02' />
        </a>
        <a href='post-v3.html#op-comments' class='comment-preview-title'>Elizabeth Valentine</a>
        <a href='post-v3.html' class='comment-preview-link green'>We reviewed the “Guardians of...</a>
        <p class='comment-preview-text'>Malat lorem de dolor sit ameteturre adipisicing restandor...</p></div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
