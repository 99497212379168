import { modifier } from 'ember-modifier';
export default modifier((element, [eventName]) => {
  element.addEventListener('click', function (event) {
    event.preventDefault();
    element.parentElement.classList.toggle('active');
    element.parentElement
      .getElementsByClassName('mobile-dropdown')[0]
      .classList.toggle('active');
  });

  return () => {
    element.remove();
  };
});
