import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_POST_QUERY } from '../../gql/queries/esports-post';
import { service } from '@ember/service';
export default class Tournament extends Route {
    @service
    headData;
    async model({ slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_POST_QUERY,
                {
                    variables: {
                        slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod.article.items[0];
    }
    afterModel(model) {
        if (model) {
            this.headData.title = model.title;
            this.headData.description = model.shortDescription;
            this.headData.image = model.coverImage.url;
            this.headData.canonicalURL = `https://1vX.gg/esports/news/${model.slug}`;
            this.headData.keywords = 'esports,games,gaming,streaming,streamers,smite,league of legends';
            this.headData.pageType = 'website';
        }
    }
}
