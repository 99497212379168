import { modifier } from 'ember-modifier';
import XMArc from 'embroil/packages/xm-arc';

export default modifier((element, [rating, size = 'lg'], { shadow = true }) => {
  let sizes = {
    xs: { width: 46, height: 46, lineWidth: 6 },
    sm: { width: 64, height: 64, lineWidth: 6 },
    md: { width: 90, height: 90, lineWidth: 8 },
    lg: { width: 112, height: 112, lineWidth: 10 },
    xl: { width: 170, height: 170, lineWidth: 18 },
  };
  let config = {
    ...sizes[size],
    container: `#${element.id}`,
    gradient: {
      colors: ['#5216fd', '#ff055d'],
    },
    scale: {
      start: 0,
      end: 10,
      stop: rating,
    },
    shadow,
  };
  new XMArc(config);

  return () => {};
});
