import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import PopularPosts from '../../components/widget/sidebar/popular-posts';
import LatestReviews from '../../components/widget/sidebar/latest-reviews';
import LiveStreams from '../../components/widget/sidebar/live-streams';
import LatestComments from '../../components/widget/sidebar/latest-comments';
import PixelPoll from '../../components/widget/sidebar/pixel-poll';
import FeaturedVideo from '../../components/widget/sidebar/featured-video';
import TagCloud from '../../components/widget/sidebar/tag-cloud';
import Calendar from '../../components/widget/sidebar/calendar';
import SidebarSearch from '../../components/widget/sidebar/search';
import PostPreview from '../../components/layout/post-preview';
import PaginationComponent from '../../components/layout/pagination';
let ReviewsIndexComponent = class ReviewsIndexComponent extends Component {
    static{
        template(`
    <BannerComponent @theme='game-review' @title='Game Reviews' />
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          <PostPreview @type='game-review' @index={{1}} />
          <PostPreview @type='game-review' @index={{2}} />
          <PostPreview @type='game-review' @index={{3}} />
          <PostPreview @type='game-review' @index={{4}} />
          <PostPreview @type='game-review' @index={{5}} />
        </div>
        <PaginationComponent />
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <SidebarSearch />
        <LatestReviews />
        <PopularPosts />
        <LiveStreams />
        <LatestComments />
        <PixelPoll />
        <FeaturedVideo />
        <TagCloud />
        <Calendar />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(ReviewsIndexComponent);
