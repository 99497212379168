import { template } from "@ember/template-compiler";
import carousel from '../../../modifiers/esports-match-results-carousel';
import dropdown from '../../../modifiers/dropdown';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_TOURNAMENT_BANNER_QUERY } from '../../../gql/queries/components/esports-tournament-banner';
import Component from '@glimmer/component';
import MatchResult from './match-result';
// get all matches of a tournament
export default class EsportsMatchResults extends Component {
    selectedTourney = 'smite-world-championship-season-x';
    get selectedTourneySlug() {
        return this.args.tournament.slug || this.selectedTourney;
    }
    query = useQuery(this, ()=>[
            ESPORTS_TOURNAMENT_BANNER_QUERY,
            {
                variables: {
                    slug: this.selectedTourney
                }
            }
        ]);
    get matches() {
        return this.query.data?.tournament?.items[0].matches.items;
    }
    static{
        template(`
    <div id='ew1-cmrf-slider-controls' class='carousel-match-result-wrap full'>
      <div class='match-selector'>
        <div class='dropdown-simple-wrap'>
          <div id='ew1-match-selector-dropdown-trigger' class='dp-current-option'>
            <div id='ew1-match-selector-dropdown-option-value' class='dp-current-option-value'>
              <p class='dp-option-text'>Smite Worlds<br />SWC <br /> Season X</p>
            </div>
            <!--
            <svg class='arrow-icon'>
              <use href='#svg-arrow'></use>
            </svg>-->
          </div>
          <!--
          <div id='ew1-match-selector-dropdown' class='dp-options' {{dropdown}}>
            <div class='dp-option'>
              <p class='dp-option-text'>League of Heroes<br />Tournament 18'</p>
            </div>
            <div class='dp-option'>
              <p class='dp-option-text'>Striker GO<br />League Finals 18'</p>
            </div>
            <div class='dp-option'>
              <p class='dp-option-text'>Heroestone<br />League 18'</p>
            </div>
            <div class='dp-option'>
              <p class='dp-option-text'>Xenowatch<br />Tournament 18'</p>
            </div>
          </div>-->
        </div>
        <div class='control-previous'>
          <svg class='arrow-icon medium'>
            <use href='#svg-arrow-medium'></use>
          </svg>
        </div>
      </div>
      {{#if this.matches}}
        <div id='ew1-cmrf-slider' class='carousel-match-result' {{carousel stopAtEnd=true}}>
          <div id='ew1-cmrf-slider-items' class='carousel-match-result-items'>
            {{#each this.matches as |match|}}
              <MatchResult @match={{match}} />
            {{/each}}

          </div>
        </div>
      {{/if}}
      <div class='control-next'>
        <svg class='arrow-icon medium'>
          <use href='#svg-arrow-medium'></use>
        </svg>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
