import { template } from "@ember/template-compiler";
import { formatDate } from '../../../helpers/format-date';
import articleType from '../../../helpers/article-type';
const eq = (a1, b1)=>a1 === b1;
export default template(`
  <div
    class='post-preview tiny negative no-img
      {{if (eq (articleType @article.__typename) "esports") "e-sport"}}
      {{if (eq (articleType @article.__typename) "news") "gaming-news"}}'
  >
    <p class='post-preview-title'>
      {{@article.title}}
    </p>
    <div class='post-author-info-wrap'>
      <p class='post-author-info small light'>
        By
        <span class='post-author'>{{@article.author.displayName}}</span>
        <span class='separator'>|</span>
        {{formatDate @article.publishedDate}}
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval //game-review, gaming-news, e-sports
        (arguments[0]);
    }
});
