import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import config from 'embroil/config/environment';
const eq = (a1, b1)=>a1 === b1;
export default template(`
  {{#if (eq @stream.type 'live')}}
    <LiveTemplate @stream={{@stream}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const LiveTemplate = template(`
  <div class='stream-preview'>
    <div class='stream-preview-streamer stream-preview-streamer-1'>
      <!-- <img
                class='stream-preview-streamer-img'
                src='/img/streamers/streambox-01-streamer.png'
                alt='streambox-01-streamer'
              /> -->
      <p class='stream-preview-streamer-name'>{{@stream.user_name}}</p>
      <p class='stream-preview-streamer-topic'>Playing: {{@stream.game_name}}</p>
      <p class='stream-start-counter live'>{{@stream.type}}</p>
    </div>
    <div class='widget-media small'>
      <iframe
        src='https://player.twitch.tv/?channel={{@stream.user_login}}&parent={{config.twitchParentUrl}}&autoplay=true&muted=true'
        allowfullscreen
      ></iframe>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const startingSoonTemplate = template(`
  <div class='stream-preview'>
    <div class='stream-preview-streamer stream-preview-streamer-2'>
      <img
        class='stream-preview-streamer-img'
        src='/img/streamers/streambox-02-streamer.png'
        alt='streambox-02-streamer'
      />
      <p class='stream-preview-streamer-name'>Davikinger90</p>
      <p class='stream-preview-streamer-topic'>I’ll try all Xenowatch’s new skins</p>
      <p class='stream-start-counter'>Starts in:
        <span id='sidebar-twitch-countdown-1' class='highlighted'></span>
      </p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const offlineTemplate = template(`
  <div class='stream-preview'>
    <div class='stream-preview-streamer stream-preview-streamer-3'>
      <img
        class='stream-preview-streamer-img'
        src='/img/streamers/streambox-03-streamer.png'
        alt='streambox-03-streamer'
      />
      <p class='stream-preview-streamer-name'>{{@stream.user_name}}</p>
      <p class='stream-preview-streamer-topic'>The best sniper of all county!</p>
      <p class='stream-start-counter offline'>Offline</p>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
