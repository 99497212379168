import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'embroil/config/environment';

export default class LiveStreams extends Service {
  @tracked streams = [];

  constructor(...args) {
    super(...args);

    fetch(`${config.supabaseFunctionsUrl}/livestreams`).then(async (resp) => {
      const res = await resp.json();
      console.log(res.data);
      this.streams = res.data;
    });
  }

  get liveCount() {
    return this.streams?.length;
  }
}
