function _typeof(t) {
  return (_typeof =
    'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
      ? function (t) {
          return typeof t;
        }
      : function (t) {
          return t &&
            'function' == typeof Symbol &&
            t.constructor === Symbol &&
            t !== Symbol.prototype
            ? 'symbol'
            : typeof t;
        })(t);
}

export default function XMSlider(n) {
  var l = {
      rosterContainer: '.slider-roster',
      itemsContainer: '.slider-items',
      startIndex: 0,
      reverse: !1,
      autoSlide: !0,
      loop: !1,
      lock: !0,
      stopAtEnd: !1,
      rosterControls: {
        activeClass: 'active',
        haltedClass: 'halted',
        triggerEvent: 'click',
      },
      sliderItems: { activeClass: 'active', haltedClass: 'halted' },
      controls: {
        previousSelector: '.control-previous',
        nextSelector: '.control-next',
        disabledClass: 'disabled',
      },
      animation: {
        type: 'carousel',
        orientation: 'vertical',
        transition: 'step',
        slideDelay: 3e3,
        slideTransition: 500,
        slideCount: 1,
      },
    },
    m = this,
    o = 0,
    i = function () {
      var t = document.visibilityState;
      'hidden' === t
        ? m.stopSlider()
        : l.autoSlide && 'visible' === t && m.startSlider();
    },
    s = function () {
      if (l.breakpoints) {
        var t = 1 / 0;
        for (var e in l.breakpoints)
          window.innerWidth <= e && (t = e < t ? e : t);
        t !== 1 / 0 && (l.animation.slideCount = l.breakpoints[t].slideCount);
      }
    },
    a = function () {
      for (
        var t = function (t, e) {
            l.newsItems[t].addEventListener('click', function () {
              m.stopSlider(), h(t);
            });
          },
          e = 0,
          n = l.newsItems.length;
        e < n;
        e++
      )
        t(e);
    },
    t = function (t, e) {
      var n = 2 < arguments.length && void 0 !== arguments[2] && arguments[2];
      if (!t)
        throw n
          ? new Error("Can't find ".concat(e, ' inside ').concat(n))
          : new Error("Can't find ".concat(e, ' in the DOM'));
    },
    r = function () {
      (l.controls.containerItem = document.querySelector(l.controls.container)),
        t(l.controls.containerItem, l.controls.container),
        (l.controls.previousControl = l.controls.containerItem.querySelector(
          l.controls.previousSelector,
        )),
        t(
          l.controls.previousControl,
          l.controls.previousSelector,
          l.controls.container,
        ),
        l.controls.previousControl.addEventListener('click', function () {
          m.stopSlider(), x();
        }),
        (l.controls.nextControl = l.controls.containerItem.querySelector(
          l.controls.nextSelector,
        )),
        t(
          l.controls.nextControl,
          l.controls.nextSelector,
          l.controls.container,
        ),
        l.controls.nextControl.addEventListener('click', function () {
          m.stopSlider(), w();
        }),
        l.stopAtEnd &&
          l.controls.previousControl.classList.add(l.controls.disabledClass);
    },
    c = function () {
      (m.container = document.querySelector(l.sliderContainer)),
        t(m.container, l.sliderContainer),
        l.rosterContainer &&
          ((l.rosterContainerItem = m.container.querySelector(
            l.rosterContainer,
          )),
          t(l.rosterContainerItem, l.rosterContainer, l.sliderContainer),
          (l.rosterItems = l.rosterContainerItem.children)),
        (l.itemsContainerItem = m.container.querySelector(l.itemsContainer)),
        t(l.itemsContainerItem, l.itemsContainer, l.sliderContainer),
        (l.newsItems = l.itemsContainerItem.children),
        (l.currentIndex = 0),
        (l.maxIndex = l.newsItems.length - 1),
        (m.started = !1),
        (m.locked = !1);
    },
    d = function () {
      for (
        var t = function (t, e) {
            l.rosterItems[t].addEventListener(
              l.rosterControls.triggerEvent,
              function () {
                m.stopSlider(), h(t);
              },
            );
          },
          e = 0,
          n = l.rosterItems.length;
        e < n;
        e++
      )
        t(e);
    },
    f = function () {
      if (
        (m.stopSlider(),
        'carousel' === l.animation.type &&
          'direct' === l.animation.transition &&
          'horizontal' === l.animation.orientation)
      ) {
        C(),
          s(),
          (l.animation.slideOffset = l.newsItems[0].offsetWidth),
          l.animation.slideGap &&
            (l.animation.slideOffset += l.animation.slideGap);
        for (var t = 0, e = 0; e < l.newsItems.length; e++)
          (l.newsItems[e].style.left = ''.concat(t, 'px')),
            (t += l.animation.slideOffset);
        l.itemsContainerItem.style.left = '-'.concat(
          l.currentIndex * l.animation.slideOffset,
          'px',
        );
      }
    },
    I = function () {
      if (
        ((l.itemsContainerItem.parentElement.style.position = 'relative'),
        (l.itemsContainerItem.parentElement.style.overflow = 'hidden'),
        (l.itemsContainerItem.style.position = 'absolute'),
        (l.itemsContainerItem.style.top = 0),
        (l.itemsContainerItem.style.left = 0),
        'static' === l.animation.type || 'fade' === l.animation.type)
      )
        for (var t = 0, e = l.newsItems.length; t < e; t++)
          (l.newsItems[t].style.position = 'absolute'),
            (l.newsItems[t].style.top = 0),
            (l.newsItems[t].style.left = 0),
            t !== l.currentIndex
              ? (l.newsItems[t].style.opacity = 0)
              : (l.newsItems[t].style.zIndex = 2);
      if ('carousel' === l.animation.type)
        if (
          ('vertical' === l.animation.orientation &&
            (l.animation.slideOffset ||
              (l.animation.slideOffset = l.newsItems[0].offsetHeight)),
          'horizontal' === l.animation.orientation &&
            (l.animation.slideOffset ||
              (l.animation.slideOffset = l.newsItems[0].offsetWidth)),
          l.animation.slideGap &&
            (l.animation.slideOffset += l.animation.slideGap),
          l.loopOffset &&
            'horizontal' === l.animation.orientation &&
            (l.itemsContainerItem.style.left = ''.concat(
              l.animation.slideOffset * l.loopOffset,
              'px',
            )),
          'step' === l.animation.transition)
        )
          for (var n = 0, i = 0, o = l.newsItems.length; i < o; i++)
            (l.newsItems[i].style.position = 'absolute'),
              'vertical' === l.animation.orientation &&
                ((l.newsItems[i].style.top = ''.concat(n, 'px')),
                (l.newsItems[i].style.left = 0)),
              'horizontal' === l.animation.orientation &&
                ((l.newsItems[i].style.left = ''.concat(n, 'px')),
                (l.newsItems[i].style.top = 0)),
              (n += l.animation.slideOffset);
        else if ('direct' === l.animation.transition) {
          var s = 0;
          if ('vertical' === l.animation.orientation) {
            l.animation.showCount ||
              (l.animation.showCount = Math.floor(
                m.container.offsetHeight / l.newsItems[0].offsetHeight,
              ));
            for (var a = 0; a < l.newsItems.length; a++)
              (l.newsItems[a].style.position = 'absolute'),
                (l.newsItems[a].style.top = ''.concat(s, 'px')),
                (l.newsItems[a].style.left = 0),
                (l.newsItems[a].style.zIndex = 2),
                (s += l.animation.slideOffset);
          }
          if ('horizontal' === l.animation.orientation) {
            l.animation.showCount ||
              (l.animation.showCount = Math.floor(
                m.container.offsetWidth / l.newsItems[0].offsetWidth,
              ));
            for (var r = 0; r < l.newsItems.length; r++)
              (l.newsItems[r].style.position = 'absolute'),
                (l.newsItems[r].style.left = ''.concat(s, 'px')),
                (l.newsItems[r].style.top = 0),
                (l.newsItems[r].style.zIndex = 2),
                (s += l.animation.slideOffset);
          }
        }
    },
    u = function () {
      for (var t = 0, e = l.newsItems.length; t < e; t++)
        'fade' === l.animation.type &&
          (l.newsItems[t].style.transition = 'opacity '.concat(
            l.animation.slideTransition / 1e3,
            's ease-in-out',
          ));
      'carousel' === l.animation.type &&
        ('vertical' === l.animation.orientation &&
          (l.itemsContainerItem.style.transition = 'top '.concat(
            l.animation.slideTransition / 1e3,
            's ease-in-out',
          )),
        'horizontal' === l.animation.orientation &&
          (l.itemsContainerItem.style.transition = 'left '.concat(
            l.animation.slideTransition / 1e3,
            's ease-in-out',
          )));
    },
    C = function () {
      for (var t = 0, e = l.newsItems.length; t < e; t++)
        'fade' === l.animation.type && (l.newsItems[t].style.transition = '');
      'carousel' === l.animation.type &&
        ('vertical' === l.animation.orientation &&
          (l.itemsContainerItem.style.transition = ''),
        'horizontal' === l.animation.orientation &&
          (l.itemsContainerItem.style.transition = ''));
    };
  (m.startSlider = function () {
    var t =
      0 < arguments.length && void 0 !== arguments[0]
        ? arguments[0]
        : l.currentIndex;
    if (!m.started) {
      if (((m.started = !0), l.rosterContainer))
        for (var e = 0, n = l.rosterItems.length; e < n; e++)
          l.rosterItems[e].classList.remove(l.rosterControls.haltedClass);
      for (var i = 0, o = l.newsItems.length; i < o; i++)
        l.newsItems[i].classList.remove(l.sliderItems.haltedClass);
      h(t);
      var s = l.reverse ? x : w;
      m.intID = window.setInterval(s, l.animation.slideDelay);
    }
  }),
    (m.stopSlider = function () {
      if (m.started) {
        if (
          ((m.started = !1), window.clearInterval(m.intID), l.rosterContainer)
        )
          for (var t = 0, e = l.rosterItems.length; t < e; t++)
            l.rosterItems[t].classList.add(l.rosterControls.haltedClass);
        for (var n = 0, i = l.newsItems.length; n < i; n++)
          l.newsItems[n].classList.add(l.sliderItems.haltedClass);
      }
    });
  var p = function () {
      var t =
        0 < arguments.length && void 0 !== arguments[0]
          ? arguments[0]
          : l.currentIndex;
      return t + 1 > l.maxIndex ? 0 : t + 1;
    },
    y = function () {
      var t =
        0 < arguments.length && void 0 !== arguments[0]
          ? arguments[0]
          : l.currentIndex;
      return t - 1 < 0 ? l.maxIndex : t - 1;
    },
    v = function (t) {
      for (
        var e =
            1 < arguments.length && void 0 !== arguments[1]
              ? arguments[1]
              : l.currentIndex,
          n = 0 < t ? p : y,
          i = 0;
        i < Math.abs(t);
        i++
      )
        e = n(e);
      return e;
    },
    w = function () {
      l.next = !0;
      var t = l.animation.slideCount ? v(l.animation.slideCount) : p();
      h(t), (l.next = !1);
    },
    x = function () {
      l.previous = !0;
      var t = l.animation.slideCount ? v(-l.animation.slideCount) : y();
      h(t), (l.previous = !1);
    },
    h = function (t) {
      m.locked ||
        (l.rosterContainer && (e(l.currentIndex), O(t)),
        g(t),
        l.lock &&
          ((m.locked = !0),
          window.setTimeout(function () {
            m.locked = !1;
          }, 1e3)));
    },
    e = function (t) {
      l.rosterItems[t].classList.remove(l.rosterControls.activeClass);
    },
    O = function (t) {
      l.rosterItems[t].classList.add(l.rosterControls.activeClass);
    },
    g = function (t) {
      if (1 !== l.newsItems.length) {
        var e = t - l.currentIndex;
        (l.animation.showCount &&
          l.animation.showCount === l.newsItems.length) ||
          (l.newsItems[l.currentIndex].classList.remove(
            l.sliderItems.activeClass,
          ),
          l.newsItems[t].classList.add(l.sliderItems.activeClass),
          'carousel' === l.animation.type
            ? l.loop
              ? T(e, t)
              : 'direct' === l.animation.transition
                ? l.stopAtEnd
                  ? l.next
                    ? S()
                    : l.previous && b()
                  : l.next
                    ? L()
                    : l.previous
                      ? E()
                      : 0 === t && l.currentIndex === l.maxIndex
                        ? L(v(-1, t))
                        : t === l.maxIndex && 0 === l.currentIndex
                          ? E(v(1, t))
                          : t > l.currentIndex
                            ? L(v(-1, t))
                            : t < l.currentIndex && E(v(1, t))
                : 'step' === l.animation.transition &&
                  ('vertical' === l.animation.orientation &&
                    (l.itemsContainerItem.style.top = ''.concat(
                      window.parseInt(l.itemsContainerItem.style.top, 10) -
                        e * l.animation.slideOffset,
                      'px',
                    )),
                  'horizontal' === l.animation.orientation &&
                    (l.itemsContainerItem.style.left = ''.concat(
                      window.parseInt(l.itemsContainerItem.style.left, 10) -
                        e * l.animation.slideOffset,
                      'px',
                    )))
            : ('static' !== l.animation.type && 'fade' !== l.animation.type) ||
              ((l.newsItems[l.currentIndex].style.opacity = 0),
              (l.newsItems[l.currentIndex].style.zIndex = 0),
              (l.newsItems[t].style.opacity = 1),
              (l.newsItems[t].style.zIndex = 2)),
          (l.currentIndex = t));
      }
    },
    S = function () {
      var t,
        e,
        n,
        i =
          'vertical' === l.animation.orientation
            ? 'top'
            : 'horizontal' === l.animation.orientation
              ? 'left'
              : '',
        o =
          ((t = Math.floor(m.container.offsetWidth / l.animation.slideOffset)),
          (e = t * l.animation.slideOffset),
          (n = m.container.offsetWidth - e),
          -(
            l.animation.slideOffset * l.maxIndex -
            t * l.animation.slideOffset +
            (l.animation.slideOffset - n)
          ));
      if (parseInt(l.itemsContainerItem.style[i], 10) !== o) {
        var s =
            parseInt(l.itemsContainerItem.style[i], 10) -
            l.animation.slideOffset * l.animation.slideCount,
          a = 0;
        s <= o
          ? ((a = o),
            l.controls.nextControl.classList.add(l.controls.disabledClass))
          : ((a = s),
            l.controls.previousControl.classList.remove(
              l.controls.disabledClass,
            )),
          (l.itemsContainerItem.style[i] = ''.concat(a, 'px'));
      }
    },
    b = function () {
      var t =
        'vertical' === l.animation.orientation
          ? 'top'
          : 'horizontal' === l.animation.orientation
            ? 'left'
            : '';
      if (0 !== parseInt(l.itemsContainerItem.style[t], 10)) {
        var e =
            parseInt(l.itemsContainerItem.style[t], 10) +
            l.animation.slideOffset * l.animation.slideCount,
          n = 0;
        0 <= e
          ? ((n = 0),
            l.controls.previousControl.classList.add(l.controls.disabledClass))
          : ((n = e),
            l.controls.nextControl.classList.remove(l.controls.disabledClass)),
          (l.itemsContainerItem.style[t] = ''.concat(n, 'px'));
      }
    },
    z = function () {
      for (var t = 0, e = l.newsItems.length; t < e; t++)
        t !== l.currentIndex && (l.newsItems[t].style.zIndex = -1);
    },
    L = function () {
      var t =
          0 < arguments.length && void 0 !== arguments[0]
            ? arguments[0]
            : l.currentIndex,
        e =
          'vertical' === l.animation.orientation
            ? 'top'
            : 'horizontal' === l.animation.orientation
              ? 'left'
              : '';
      z();
      for (
        var n =
            -parseInt(l.itemsContainerItem.style[e], 10) +
            l.animation.slideOffset * l.animation.slideCount,
          i = 0;
        i < l.animation.showCount;
        i++
      )
        (l.newsItems[v(l.animation.slideCount + i, t)].style[e] = ''.concat(
          n,
          'px',
        )),
          (l.newsItems[v(l.animation.slideCount + i, t)].style.zIndex = 2),
          (n += l.animation.slideOffset);
      l.itemsContainerItem.style[e] = ''.concat(
        parseInt(l.itemsContainerItem.style[e], 10) -
          l.animation.slideOffset * l.animation.slideCount,
        'px',
      );
    },
    E = function () {
      var t =
          0 < arguments.length && void 0 !== arguments[0]
            ? arguments[0]
            : l.currentIndex,
        e =
          'vertical' === l.animation.orientation
            ? 'top'
            : 'horizontal' === l.animation.orientation
              ? 'left'
              : '';
      z();
      for (
        var n =
            -parseInt(l.itemsContainerItem.style[e], 10) -
            l.animation.slideOffset * l.animation.slideCount,
          i = 0;
        i < l.animation.showCount + 1;
        i++
      )
        (l.newsItems[v(-l.animation.slideCount + i, t)].style[e] = ''.concat(
          n,
          'px',
        )),
          (l.newsItems[v(-l.animation.slideCount + i, t)].style.zIndex = 2),
          (n += l.animation.slideOffset);
      l.itemsContainerItem.style[e] = ''.concat(
        parseInt(l.itemsContainerItem.style[e], 10) +
          l.animation.slideOffset * l.animation.slideCount,
        'px',
      );
    },
    k = function (t, e, n) {
      if (
        ((l.itemsContainerItem.style.transition = ''
          .concat(n, ' ')
          .concat(l.animation.slideTransition / 1e3, 's ease-in-out')),
        l.loopOffset)
      ) {
        l.currentIndex === e &&
          (l.itemsContainerItem.style.transition = 'none');
        var i = t;
        1 !== i && -1 !== i && 0 !== i && (i = 0 < t ? -1 : 1),
          1 === i ? o++ : -1 === i && o--,
          (l.newsItems[y(e)].style[n] = ''.concat(
            o * l.animation.slideOffset - l.animation.slideOffset,
            'px',
          )),
          (l.newsItems[e].style[n] = ''.concat(
            o * l.animation.slideOffset,
            'px',
          )),
          (l.newsItems[p(e)].style[n] = ''.concat(
            o * l.animation.slideOffset + l.animation.slideOffset,
            'px',
          )),
          (l.itemsContainerItem.style[n] = ''.concat(
            window.parseInt(l.itemsContainerItem.style[n], 10) -
              i * l.animation.slideOffset,
            'px',
          ));
      } else
        l.currentIndex === l.maxIndex && 0 === e
          ? ((l.newsItems[0].style[n] = ''.concat(
              (l.maxIndex + 1) * l.animation.slideOffset,
              'px',
            )),
            (l.itemsContainerItem.style[n] = ''.concat(
              window.parseInt(l.itemsContainerItem.style[n], 10) -
                l.animation.slideOffset,
              'px',
            )),
            window.setTimeout(function () {
              (l.itemsContainerItem.style.transition = 'none'),
                (l.newsItems[0].style[n] = '0'),
                (l.itemsContainerItem.style[n] = '0');
            }, l.animation.slideTransition))
          : 0 === l.currentIndex && e === l.maxIndex
            ? ((l.newsItems[l.maxIndex].style[n] = '-'.concat(
                l.animation.slideOffset,
                'px',
              )),
              (l.itemsContainerItem.style[n] = ''.concat(
                window.parseInt(l.itemsContainerItem.style[n], 10) +
                  l.animation.slideOffset,
                'px',
              )),
              window.setTimeout(function () {
                (l.itemsContainerItem.style.transition = 'none'),
                  (l.newsItems[l.maxIndex].style[n] = ''.concat(
                    l.maxIndex * l.animation.slideOffset,
                    'px',
                  )),
                  (l.itemsContainerItem.style[n] = '-'.concat(
                    l.maxIndex * l.animation.slideOffset,
                    'px',
                  ));
              }, l.animation.slideTransition))
            : (l.itemsContainerItem.style[n] = ''.concat(
                window.parseInt(l.itemsContainerItem.style[n], 10) -
                  t * l.animation.slideOffset,
                'px',
              ));
    },
    T = function (t, e) {
      'vertical' === l.animation.orientation && k(t, e, 'top'),
        'horizontal' === l.animation.orientation && k(t, e, 'left');
    },
    D = function t(e, n) {
      var i;
      for (var o in n)
        'object' !== _typeof(n[o]) || n[o] instanceof Date
          ? n[o] instanceof Date
            ? (e[o] = new Date(n[o].getTime()))
            : (e[o] = n[o])
          : ((i = n[o] instanceof Array ? [] : {}),
            void 0 === e[o] && (e[o] = i),
            t(e[o], n[o]));
    };
  !(function () {
    if (
      (D(l, n),
      s(),
      c(),
      I(),
      u(),
      l.rosterContainer && d(),
      l.controls.container && r(),
      l.slideOnClick && a(),
      l.autoSlide)
    )
      m.startSlider(l.startIndex);
    else if ((h(l.startIndex), l.rosterContainer))
      for (var t = 0, e = l.rosterItems.length; t < e; t++)
        l.rosterItems[t].classList.add(l.rosterControls.haltedClass);
    window.addEventListener('resize', function () {
      f(), l.autoSlide && m.startSlider(), setTimeout(u, 300);
    }),
      document.addEventListener('visibilitychange', i);
  })();
}
