import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-item'>
    <div class='section-title-wrap violet'>
      <h2 class='section-title medium'>Live Tournament</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='widget-media-wrap large'>
      <div class='widget-media-r'>
        <iframe src={{@match.liveVideoUrl}} allowfullscreen></iframe>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
