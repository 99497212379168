import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap blue'>
      <h2 class='section-title medium'>Social Pixel</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='social-links medium centered'>
      <a href='#' class='bubble-ornament big fb'>
        <svg class='facebook-icon big'>
          <use href='#svg-facebook'></use>
        </svg>
        <p class='bubble-ornament-text'>2560</p>
      </a>
      <a href='#' class='bubble-ornament big twt'>
        <svg class='twitter-icon big'>
          <use href='#svg-twitter'></use>
        </svg>
        <p class='bubble-ornament-text'>1945</p>
      </a>
      <a href='#' class='bubble-ornament big insta'>
        <svg class='instagram-icon big'>
          <use href='#svg-instagram'></use>
        </svg>
        <p class='bubble-ornament-text'>835</p>
      </a>
      <a href='#' class='bubble-ornament big twitch'>
        <svg class='twitch-icon big'>
          <use href='#svg-twitch'></use>
        </svg>
        <p class='bubble-ornament-text'>9632</p>
      </a>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
