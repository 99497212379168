function _typeof(e) {
  return (_typeof =
    'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
      ? function (e) {
          return typeof e;
        }
      : function (e) {
          return e &&
            'function' == typeof Symbol &&
            e.constructor === Symbol &&
            e !== Symbol.prototype
            ? 'symbol'
            : typeof e;
        })(e);
}
export default function XMProgressBar(d) {
  var p = {
      width: 240,
      height: 6,
      lineColor: '#ebebeb',
      lineCap: 'round',
      buttEnd: !1,
      animation: { easing: !0 },
      animateOnScroll: !1,
      controlAnimation: !1,
      speed: 50,
      scale: { start: 0, end: 1, stop: 1 },
      invertedProgress: !1,
      reverse: !1,
      linkText: !1,
      innerLink: !0,
      linkToContainer: '.bar-progress-wrap',
      linkTo: '.bar-progress-text',
      extraLinkTo: [],
      linkUnits: '%',
      linkUnitsSelector: '.bar-progress-unit',
      innerTextFollowsProgress: !1,
      decimalPoints: 0,
      barTextStyle: {
        primaryColor: '#fff',
        secondaryColor: '#363636',
        font: 'bold 10px Exo, sans-serif',
      },
    },
    o = document.createElement('canvas'),
    r = o.getContext('2d'),
    l = this,
    h = function (e) {
      var t = document.createElement('div');
      (t.style.width = ''.concat(e.partWidth + 2 * e.width, 'px')),
        (t.style.height = ''.concat(p.height + 2 * e.width, 'px')),
        (t.style.border = ''.concat(e.width, 'px solid ').concat(e.color)),
        (t.style.borderRadius = '200px'),
        (t.style.position = 'absolute'),
        (t.style.top = '-'.concat(e.width, 'px')),
        (t.style.left = ''.concat(e.offset, 'px')),
        l.container.append(t);
    },
    f = function () {
      if (((l.container = document.querySelector(p.container)), !l.container))
        throw Error('Unable to find "'.concat(p.container, '" on the DOM'));
      (o.width = p.width),
        (o.height = p.height),
        (o.style.position = 'absolute'),
        (o.style.top = 0),
        (o.style.left = 0),
        (l.container.style.width = ''.concat(p.width, 'px')),
        (l.container.style.height = ''.concat(p.height, 'px')),
        (l.container.style.position = 'relative'),
        l.container.append(o);
    },
    u = function () {
      var e = 1 / 0;
      for (var t in p.breakpoints)
        window.innerWidth <= t && (e = t < e ? t : e);
      e !== 1 / 0 &&
        ((p.width = p.breakpoints[e].width),
        (p.height = p.breakpoints[e].height));
    },
    m = function () {
      var e = 1 / 0;
      for (var t in p.breakpoints)
        window.innerWidth <= t && (e = t < e ? t : e);
      n(e === 1 / 0 ? p.originalDimensions : p.breakpoints[e]);
    },
    n = function (e) {
      (o.width = e.width),
        (o.height = e.height),
        (l.container.style.width = ''.concat(e.width, 'px')),
        (l.container.style.height = ''.concat(e.height, 'px'));
      var t = (p.scale.stop / p.scale.end) * e.width;
      if (
        (p.linkElContainer &&
          p.innerTextFollowsProgress &&
          (p.linkElContainer.style.left = ''.concat(t, 'px')),
        w(),
        r.beginPath(),
        'butt' === p.lineCap &&
          (r.moveTo(0, e.height / 2), r.lineTo(t, e.height / 2)),
        'round' === p.lineCap)
      ) {
        var n = r.lineWidth / 2,
          i = t - n;
        p.reverse
          ? (r.moveTo(e.width - n, e.height / 2),
            e.width - t <= n
              ? r.lineTo(n, e.height / 2)
              : r.lineTo(e.width - t, e.height / 2))
          : (r.moveTo(n, e.height / 2),
            i < n ? r.lineTo(n, e.height / 2) : r.lineTo(i, e.height / 2));
      }
      r.stroke();
    },
    e = function (e, t) {
      var n = t[0],
        i = t.substring(1);
      '.' === n && e.setAttribute('class', i),
        '#' === n && e.setAttribute('id', i);
    },
    g = function () {
      p.linkText &&
        (p.innerLink &&
          ((p.linkElContainer = l.container.querySelector(p.linkToContainer)),
          p.linkElContainer ||
            ((p.linkElContainer = document.createElement('div')),
            e(p.linkElContainer, p.linkToContainer)),
          (p.linkEl = l.container.querySelector(p.linkTo)),
          p.linkEl ||
            ((p.linkEl = document.createElement('p')),
            e(p.linkEl, p.linkTo),
            p.linkElContainer.append(p.linkEl)),
          p.innerTextFollowsProgress &&
            (p.linkElContainer.style.position = 'absolute'),
          p.linkUnits &&
            ((p.linkUnitsEl = document.createElement('span')),
            e(p.linkUnitsEl, p.linkUnitsSelector),
            (p.linkUnitsEl.innerHTML = p.linkUnits)),
          l.container.append(p.linkElContainer)),
        0 < p.extraLinkTo.length &&
          ((p.extraLinkEl = []),
          p.extraLinkTo.forEach(function (e) {
            if (!document.querySelector(e))
              throw Error('Unable to find "'.concat(e, '" on the DOM'));
            p.extraLinkEl.push(document.querySelector(e));
          })));
    },
    T = function () {
      (p.speed = 100 < p.speed ? 100 : p.speed),
        (p.speed = p.speed < 1 ? 1 : p.speed),
        (p.animation.step = (p.speed * p.width) / 2e3),
        (p.animation.currentProgress = 0);
    };
  function w() {
    if (p.gradient) {
      var e = r.createLinearGradient(0, p.height, p.width, 0);
      e.addColorStop(0, p.gradient.colors[0]),
        e.addColorStop(1, p.gradient.colors[1]),
        (r.strokeStyle = e);
    } else r.strokeStyle = p.lineColor;
    (r.lineCap = p.lineCap), (r.lineWidth = p.height);
  }
  function y() {
    for (
      var e =
          (window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop) + window.innerHeight,
        t = o,
        n = t.offsetTop;
      (t = t.offsetParent);

    )
      n += t.offsetTop;
    return n <= e;
  }
  function k() {
    p.animation.currentProgress < p.stopPoint &&
      (p.animation.easing
        ? ((p.animation.currentProgress +=
            0.06 * (p.stopPoint - p.animation.currentProgress)),
          p.animation.currentProgress >= p.stopPoint - 1 &&
            ((p.animation.currentProgress = p.stopPoint), (l.done = !0)))
        : ((p.animation.currentProgress += p.animation.step),
          p.animation.currentProgress >= p.stopPoint &&
            ((p.animation.currentProgress = p.stopPoint), (l.done = !0)))),
      x(p.animation.currentProgress),
      l.done || window.requestAnimationFrame(k);
  }
  var x = function () {
      var e =
        0 < arguments.length && void 0 !== arguments[0]
          ? arguments[0]
          : p.stopPoint;
      if (
        ((function (e) {
          if (p.linkText) {
            var t = ((e / p.width) * p.scale.end).toFixed(p.decimalPoints);
            p.invertedProgress && (t = p.scale.end - t),
              p.innerLink &&
                ((p.linkEl.innerHTML = t),
                p.linkUnits && p.linkEl.append(p.linkUnitsEl),
                '/' === p.linkUnits && (p.linkEl.innerHTML += p.scale.end),
                p.innerTextFollowsProgress &&
                  (p.linkElContainer.style.left = ''.concat(e, 'px'))),
              0 < p.extraLinkTo.length &&
                p.extraLinkEl.forEach(function (e) {
                  e.innerHTML = t;
                });
          }
        })(e),
        r.clearRect(0, 0, p.width, p.height),
        0 === e)
      )
        return (
          p.emptyText &&
            (p.emptyTextContainer
              ? (p.emptyTextEl = document.querySelector(p.emptyTextContainer))
              : (p.emptyTextEl = p.linkEl.parentElement),
            (p.emptyTextEl.innerHTML = p.emptyText)),
          void (l.done = !0)
        );
      if (
        (r.beginPath(),
        'butt' === p.lineCap &&
          (r.moveTo(0, p.height / 2), r.lineTo(e, p.height / 2), r.stroke()),
        'round' === p.lineCap)
      ) {
        var t = r.lineWidth / 2,
          n = e - t;
        p.reverse
          ? (r.moveTo(p.width - t, p.height / 2),
            p.width - e <= t
              ? r.lineTo(t, p.height / 2)
              : r.lineTo(p.width - e, p.height / 2))
          : (r.moveTo(t, p.height / 2),
            n < t ? r.lineTo(t, p.height / 2) : r.lineTo(n, p.height / 2)),
          r.stroke(),
          p.buttEnd &&
            ((r.lineCap = 'butt'),
            p.reverse
              ? (r.moveTo(p.width - e, p.height / 2),
                r.lineTo(p.width - e - t, p.height / 2))
              : (r.moveTo(n, p.height / 2), r.lineTo(e, p.height / 2)),
            r.stroke(),
            (r.lineCap = 'round'));
      }
      p.barText && i(e),
        p.completeText &&
          e === p.width &&
          (p.completeTextContainer
            ? (p.completeTextEl = document.querySelector(
                p.completeTextContainer,
              ))
            : (p.completeTextEl = p.linkEl.parentElement),
          (p.completeTextEl.innerHTML = p.completeText));
    },
    i = function (e) {
      var t = 0;
      r.font = p.barTextStyle.font;
      for (var n = 0; n < p.barText.length; n++)
        (r.fillStyle = p.barTextStyle.primaryColor),
          e < t + 12 && (r.fillStyle = p.barTextStyle.secondaryColor),
          r.fillText(p.barText[n], 12 + t, 4 + p.height / 2),
          (t += r.measureText(p.barText[n]).width);
    };
  l.render = function (e) {
    (e =
      ((e =
        (e = e > p.scale.end ? p.scale.end : e) < p.scale.start
          ? p.scale.start
          : e) /
        p.scale.end) *
      p.width),
      x(e);
  };
  var v = function e(t, n) {
    var i;
    for (var o in n)
      'object' !== _typeof(n[o]) || n[o] instanceof Date
        ? n[o] instanceof Date
          ? (t[o] = new Date(n[o].getTime()))
          : (t[o] = n[o])
        : ((i = n[o] instanceof Array ? [] : {}),
          void 0 === t[o] && (t[o] = i),
          e(t[o], n[o]));
  };
  !(function () {
    if (
      (v(p, d),
      (p.originalDimensions = { width: p.width, height: p.height }),
      u(),
      d.scale &&
        ((p.scale.stop =
          p.scale.stop > p.scale.end ? p.scale.end : p.scale.stop),
        (p.scale.stop =
          p.scale.stop < p.scale.start ? p.scale.start : p.scale.stop),
        (p.scale.stop = void 0 !== d.scale.stop ? p.scale.stop : p.scale.end)),
      (p.stopPoint = (p.scale.stop / p.scale.end) * p.width),
      f(),
      g(),
      w(),
      T(),
      p.animateOnScroll
        ? y()
          ? window.requestAnimationFrame(k)
          : window.addEventListener('scroll', function e() {
              y() &&
                (window.requestAnimationFrame(k),
                window.removeEventListener('scroll', e));
            })
        : p.controlAnimation || x(),
      void 0 !== p.split)
    )
      if (void 0 !== p.split.parts)
        for (
          var e = (p.width - p.split.gap * (p.split.parts - 1)) / p.split.parts,
            t = -p.split.gap,
            n = 0;
          n < p.split.parts;
          n++
        )
          h({
            width: p.split.gap,
            color: p.split.color,
            offset: t,
            partWidth: e,
          }),
            (t += e + p.split.gap);
      else if (void 0 !== p.split.partItems) {
        var i = 0,
          o = !0,
          r = !1,
          l = void 0;
        try {
          for (
            var a, s = p.split.partItems[Symbol.iterator]();
            !(o = (a = s.next()).done);
            o = !0
          ) {
            var c = a.value;
            (i -= c.gap),
              h({
                width: c.gap,
                color: p.split.color,
                offset: i,
                partWidth: c.width,
              }),
              (i += c.width + 2 * c.gap);
          }
        } catch (e) {
          (r = !0), (l = e);
        } finally {
          try {
            o || null == s.return || s.return();
          } finally {
            if (r) throw l;
          }
        }
      }
    p.breakpoints &&
      window.addEventListener('resize', function () {
        m();
      });
  })();
}
