import { template } from "@ember/template-compiler";
export default template(`
  <div class='layout-body layout-item gutter-big'>
    <div class='widget-item'>
      <div class='section-title-wrap violet small-space'>
        <h2 class='section-title medium'>Career Stats</h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='widget-match-box'>
        <div class='widget-simple-stats v2'>
          <div class='widget-simple-stat half'>
            <div class='widget-simple-stat-header'>
              <p class='widget-simple-stat-header-title'>This Year</p>
            </div>
            <div class='widget-simple-stat-rows'>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Played</p>
                <p class='widget-simple-stat-value'>8</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Won</p>
                <p class='widget-simple-stat-value'>7</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Lost</p>
                <p class='widget-simple-stat-value'>1</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Win Rate</p>
                <p class='widget-simple-stat-value'>92.6%</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Damage Inflicted</p>
                <p class='widget-simple-stat-value'>42.546</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Damage Received</p>
                <p class='widget-simple-stat-value'>31.369</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Game MVP's</p>
                <p class='widget-simple-stat-value'>1</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Most Played Hero</p>
                <div class='team-info-wrap small inverted'>
                  <div class='hexagon hexagon-wrap small red'>
                    <div class='hexagon-in1'>
                      <div class='hexagon-in2 hexagon-wrap'>
                        <div class='hexagon inner-hexagon'>
                          <div class='hexagon-in1'>
                            <div class='hexagon-in2 hexagon-area liquid'>
                              <img src='/img/characters/05.png' alt='character-05' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='team-info'>
                    <p class='team-name'>Galium</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='widget-simple-stat half'>
            <div class='widget-simple-stat-header'>
              <p class='widget-simple-stat-header-title'>Lifetime</p>
            </div>
            <div class='widget-simple-stat-rows'>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Played</p>
                <p class='widget-simple-stat-value'>94</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Won</p>
                <p class='widget-simple-stat-value'>82</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Games Lost</p>
                <p class='widget-simple-stat-value'>12</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Win Rate</p>
                <p class='widget-simple-stat-value'>78.43%</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Damage Inflicted</p>
                <p class='widget-simple-stat-value'>195.670</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Damage Received</p>
                <p class='widget-simple-stat-value'>160.448</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Game MVP's</p>
                <p class='widget-simple-stat-value'>37</p>
              </div>
              <div class='widget-simple-stat-row'>
                <p class='widget-simple-stat-title'>Most Played Hero</p>
                <div class='team-info-wrap small inverted'>
                  <div class='hexagon hexagon-wrap small cyan'>
                    <div class='hexagon-in1'>
                      <div class='hexagon-in2 hexagon-wrap'>
                        <div class='hexagon inner-hexagon'>
                          <div class='hexagon-in1'>
                            <div class='hexagon-in2 hexagon-area liquid'>
                              <img src='/img/characters/11.png' alt='character-11' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class='team-info'>
                    <p class='team-name'>Luria</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='widget-item'>
      <div class='section-title-wrap violet'>
        <h2 class='section-title medium'>Most Played Heroes</h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='table lineups most-played'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Hero / Role</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Time Played</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Record</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Medals</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Win Rate</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>On Fire</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Healing</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='hexagon hexagon-wrap cyan'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/11.png' alt='character-11' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='team-info'>
                  <p class='team-name'>Luria</p>
                  <p class='team-country'>Support</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>09:00:00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>33</span>
                /
                <span class='highlight'>8</span>
                /
                <span class='highlight'>2</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>3.17</p>
            </div>
            <div class='table-row-item ew2-lineups-tooltip' data-title='2.65'>
              <p class='table-text bolder'>
                <svg class='plus-icon rate-up'>
                  <use href='#svg-plus-big'></use>
                </svg>
                63.33%
              </p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>18.04%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>15.662</p>
            </div>
          </div>
        </div>
      </div>
      <div class='table lineups most-played'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Hero / Role</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Time Played</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Record</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Medals</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Win Rate</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>On Fire</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Damage</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='hexagon hexagon-wrap red'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/05.png' alt='character-05' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='team-info'>
                  <p class='team-name'>Galium</p>
                  <p class='team-country'>Offense</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>07:23:00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>30</span> / <span class='highlight'>6</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>3.08</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>85.50%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>22.90%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>14.307</p>
            </div>
          </div>
        </div>
      </div>
      <div class='table lineups most-played'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Hero / Role</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Time Played</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Record</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Medals</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Win Rate</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>On Fire</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Healing</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='hexagon hexagon-wrap cyan'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/12.png' alt='character-12' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='team-info'>
                  <p class='team-name'>Duvida</p>
                  <p class='team-country'>Support</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>04:06:00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>15</span>
                /
                <span class='highlight'>5</span>
                /
                <span class='highlight'>1</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>3.53</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>42.20%</p>
            </div>
            <div class='table-row-item ew2-lineups-tooltip' data-title='2.50'>
              <p class='table-text bolder'>
                <svg class='minus-cc-icon rate-down'>
                  <use href='#svg-minus-cc'></use>
                </svg>
                20.03%
              </p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>17.799</p>
            </div>
          </div>
        </div>
      </div>
      <div class='table lineups most-played'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Hero / Role</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Time Played</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Record</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Medals</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Win Rate</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>On Fire</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Damage</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='hexagon hexagon-wrap blue'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/07.png' alt='character-07' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='team-info'>
                  <p class='team-name'>Rekson</p>
                  <p class='team-country'>Defense</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>00:58:00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>6</span> / <span class='highlight'>2</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>2.86</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>79.71%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>21.56%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>12.422</p>
            </div>
          </div>
        </div>
      </div>
      <div class='table lineups most-played'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Hero / Role</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Time Played</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Record</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Medals</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Win Rate</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>On Fire</p>
          </div>
          <div class='table-row-header-item padded-big'>
            <p class='table-row-header-title'>Damage</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='hexagon hexagon-wrap violet'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/04.png' alt='character-04' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='team-info'>
                  <p class='team-name'>Vanstus</p>
                  <p class='team-country'>Tank</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>00:42:00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>1</span> / <span class='highlight'>0</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>3.00</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>92.58%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>48.05%</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>15.765</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class='widget-item'>
      <div class='section-title-wrap violet small-space'>
        <h2 class='section-title medium'>Records &amp; Awards</h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='table records'>
        <div class='table-row-header'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Record/Award</p>
          </div>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Description</p>
          </div>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Date Awarded</p>
          </div>
        </div>
        <div class='table-rows'>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/flag-record-icon.png' alt='record-img' />
                <p class='featured-info-text'>Most damage inflicted</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <div class='featured-info small'>
                <div class='featured-info-img hexagon hexagon-wrap small light'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/05.png' alt='character-05' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class='featured-info-text'>Inflicted 98.256 of damage in a single game. Hero: Galium</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>March 2019</p>
            </div>
          </div>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/cup-award-icon.png' alt='award-img' />
                <p class='featured-info-text'>Player of the year 2018</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>Awarded by the Xenowatch League organizers</p>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>November 2018</p>
            </div>
          </div>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/flag-record-icon.png' alt='record-img' />
                <p class='featured-info-text'>Fastest kill in league</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <div class='featured-info small'>
                <div class='featured-info-img hexagon hexagon-wrap small light'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/04.png' alt='character-04' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class='featured-info-text'>Killed an opponent 0.23 secs after the start. Hero: Vanstus</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>August 2018</p>
            </div>
          </div>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/flag-record-icon.png' alt='record-img' />
                <p class='featured-info-text'>Highest healing in world cup</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <div class='featured-info small'>
                <div class='featured-info-img hexagon hexagon-wrap small light'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/10.png' alt='character-10' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class='featured-info-text'>42.125 healing done in a single game. Hero: Magina</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>February 2018</p>
            </div>
          </div>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/cup-award-icon.png' alt='award-img' />
                <p class='featured-info-text'>Player of the year 2014</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>Awarded by the Xenowatch Tournament founders</p>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>October 2014</p>
            </div>
          </div>
          <div class='table-row'>
            <div class='table-row-item'>
              <div class='featured-info'>
                <img class='featured-info-img' src='/img/icons/flag-record-icon.png' alt='record-img' />
                <p class='featured-info-text'>Highest eliminations</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <div class='featured-info small'>
                <div class='featured-info-img hexagon hexagon-wrap small light'>
                  <div class='hexagon-in1'>
                    <div class='hexagon-in2 hexagon-wrap'>
                      <div class='hexagon inner-hexagon'>
                        <div class='hexagon-in1'>
                          <div class='hexagon-in2 hexagon-area liquid'>
                            <img src='/img/characters/05.png' alt='character-05' />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p class='featured-info-text'>43 eliminations in a single life. Hero: Galium</p>
              </div>
            </div>
            <div class='table-row-item left'>
              <p class='table-text bold'>June 2014</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
