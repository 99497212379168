import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { Input } from '@ember/component';
const NEWSLETTER_URL = 'https://newsletter.undark-gaming.workers.dev/';
// const NEWSLETTER_URL = 'https://list.1vX.gg/';
import { service } from '@ember/service';
interface SubscribeBannerComponentSignature {
    Element: HTMLDivElement;
    Args: {
    };
    Blocks: {
    };
}
// TODO: Uncomment fetch, maybe change the newsletter list out, have input disappear on success
// stash a value in localstorage so we can show them they are already subscribed. Maybe a level up icon or badge
// Dedepulicate email on the receiving function.
export default class SubscribeBanner extends Component<SubscribeBannerComponentSignature> {
    @service
    metrics: any;
    @tracked
    showForm: boolean = true;
    @tracked
    email: string = '';
    @tracked
    submissionAttempt: boolean = false;
    get emailValid() {
        const simpleRegex1 = /^[^@]+@[^@]+\.[^@]+$/;
        return simpleRegex1.test(this.email);
    }
    get showError() {
        return this.submissionAttempt && !this.emailValid;
    }
    @action
    async signUp(e1: Event) {
        e1.preventDefault();
        if (this.email && this.emailValid) {
            this.metrics.track('subscribe.banner.signup');
            await fetch(NEWSLETTER_URL, {
                method: 'POST',
                headers: {
                    email: this.email,
                    'Content-Type': 'application/json'
                }
            });
            this.showForm = false;
        }
    }
    static{
        template(`
    <div class='subscribe-section-wrap' ...attributes>
      <div class='subscribe-section grid-limit'>
        <img class='subscribe-section-img' src='/img/other/newsletter_people.png' alt='subscribe-img' />
        <div class='subscribe-section-info'>
          <p class='subscribe-section-title'>Subscribe to our newsletter!</p>
          <p class='subscribe-section-text'>Receive the latest news, events, tournaments, featured videos and more</p>
          {{#if this.showForm}}
            <form {{on 'submit' this.signUp}} class='form-wrap'>
              <div class='submit-input medium green'>
                <Input
                  @type='text'
                  @value={{this.email}}
                  id='newsletter_email'
                  placeholder='Enter your email here...'
                  aria-label='Enter your email'
                />
                <button class='submit-input-button' type='submit'>
                  <svg class='arrow-icon medium'>
                    <use href='#svg-arrow-medium'></use>
                  </svg>
                </button>
              </div>
            </form>
          {{else}}
            <p class='subscribe-section-text'>
              <svg class='check-icon'>
                <use xlink:href='#svg-check'></use>
              </svg>
              Awesome - You're on the list!</p>
          {{/if}}
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
