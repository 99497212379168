import { gql } from 'glimmer-apollo';

export const ESPORTS_GAME_QUERY = gql`
  query {
    articles: eSportsArticleCollection(limit: 4) {
      items {
        title
        shortDescription
        slug
        author {
          displayName
          profilePicture {
            url
            description
          }
        }
        coverImage {
          url
          description
        }
      }
    }
  }
`;
