import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { GET_HOME_PAGE } from 'embroil/gql/queries/home-page';
export default class IndexRoute extends Route {
  query = useQuery(this, () => [GET_HOME_PAGE]);

  async model() {
    await this.query.promise;
    return {
      articles: this.query.data.homePage.items[0].articles.items,
      topNews: this.query.data.topNews.items[0].articles.items,
      newsSlider: this.query.data.newsSlider.items[0].articles.items,
      popularPosts: this.query.data.popularPosts.items[0].articles.items,
    };
  }
}
