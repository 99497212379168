import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
let PrivacyPolicyComponent = class PrivacyPolicyComponent extends Component {
    static{
        template(`
    <div class='layout-content-1 layout-item-3-1 search-pad grid-limit'>
      <div class='layout-body layout-item centered'>
        <h1>PRIVACY POLICY</h1>
        <p><strong>Last updated January 01, 2024</strong></p>

        <p>
          This privacy notice for Augmented Interactive, LLC (doing business as 1vX.gg) ("<strong>we</strong>," "<strong
          >us</strong>," or "<strong>our</strong>"), describes how and why we might collect, store, use, and/or share ("<strong
          >process</strong>") your information when you use our services ("<strong>Services</strong>"), such as when
          you:
        </p>
        <ul>
          <li>Visit our website at https://1vX.gg, or any website of ours that links to this privacy notice</li>
          <li>Engage with us in other related ways, including any sales, marketing, or events</li>
        </ul>

        <strong>Questions or concerns?</strong>
        <p>
          Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with
          our policies and practices, please do not use our Services. If you still have any questions or concerns,
          please contact us at admin@1vX.gg.
        </p>

        <h2>SUMMARY OF KEY POINTS</h2>

        <p>
          This summary provides key points from our privacy notice, but you can find out more details about any of these
          topics by clicking the link following each key point or by using our table of contents below to find the
          section you are looking for.
        </p>

        <p>
          What personal information do we process? When you visit, use, or navigate our Services, we may process
          personal information depending on how you interact with us and the Services, the choices you make, and the
          products and features you use. Learn more about personal information you disclose to us.
        </p>

        <p>Do we process any sensitive personal information? We do not process sensitive personal information.</p>

        <p>Do we receive any information from third parties? We do not receive any information from third parties.</p>

        <p>How do we process your information? We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
          your information for other purposes with your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about how we process your information.</p>

        <p>In what situations and with which types of parties do we share personal information? We may share information
          in specific situations and with specific categories of third parties. Learn more about when and with whom we
          share your personal information.</p>

        <p>How do we keep your information safe? We have organizational and technical processes and procedures in place
          to protect your personal information. However, no electronic transmission over the internet or information
          storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly
          collect, access, steal, or modify your information. Learn more about how we keep your information safe.</p>

        <p>What are your rights? Depending on where you are located geographically, the applicable privacy law may mean
          you have certain rights regarding your personal information. Learn more about your privacy rights.</p>

        <p>How do you exercise your rights? The easiest way to exercise your rights is by visiting
          https://1vX.gg/contact-us, or by contacting us. We will consider and act upon any request in accordance with
          applicable data protection laws.</p>

        <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>

        <h3>TABLE OF CONTENTS</h3>

        <ol>
          <li><a href='#information-we-collect'>WHAT INFORMATION DO WE COLLECT?</a></li>
          <li>HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</li>
          <li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</li>
        </ol>

        <h4 id='#information-we-collect'>1. WHAT INFORMATION DO WE COLLECT?</h4>

        <p><strong>Personal information you disclose to us</strong></p>
        <p><strong>In Short</strong>: We collect personal information that you provide to us.</p>
        <p>We collect personal information that you voluntarily provide to us when you register on the Services, express
          an interest in obtaining information about us or our products and Services, when you participate in activities
          on the Services, or otherwise when you contact us.</p>
        <p><strong>Personal Information Provided by You.</strong>
          The personal information that we collect depends on the context of your interactions with us and the Services,
          the choices you make, and the products and features you use. The personal information we collect may include
          the following:</p>
        <ul>
          <li>email addresses</li>
          <li>usernames</li>
          <li>passwords</li>
          <li>contact preferences</li>
          <li>contact or authentication data</li>
          <li>billing addresses</li>
          <li>debit/credit card numbers</li>
          <li>mailing addresses</li>
          <li>gaming preferences</li>
          <li>names</li>
          <li>Sensitive Information. We do not process sensitive information.</li>
        </ul>
        <p><strong>Payment Data.</strong>
          We may collect data necessary to process your payment if you make purchases, such as your payment instrument
          number, and the security code associated with your payment instrument. All payment data is stored by Shopify
          and Stripe. You may find their privacy notice link(s) here: https://www.shopify.com/legal/privacy and
          https://stripe.com/privacy.</p>
        <p><strong>Social Media Login Data</strong>. We may provide you with the option to register with us using your
          existing social media account details, like your Facebook, Twitter, or other social media account. If you
          choose to register in this way, we will collect the information described in the section called "HOW DO WE
          HANDLE YOUR SOCIAL LOGINS?" below.</p>
        <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us
          of any changes to such personal information.</p>

        <h5>Information automatically collected</h5>

        <p><strong>In Short</strong>: Some information — such as your Internet Protocol (IP) address and/or browser and
          device characteristics — is collected automatically when you visit our Services.</p>
        <p>We automatically collect certain information when you visit, use, or navigate the Services. This information
          does not reveal your specific identity (like your name or contact information) but may include device and
          usage information, such as your IP address, browser and device characteristics, operating system, language
          preferences, referring URLs, device name, country, location, information about how and when you use our
          Services, and other technical information. This information is primarily needed to maintain the security and
          operation of our Services, and for our internal analytics and reporting purposes.</p>
        <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
        <p>The information we collect includes:</p>
        <ul>
          <li><strong>Log and Usage Data</strong>. Log and usage data is service-related, diagnostic, usage, and
            performance information our servers automatically collect when you access or use our Services and which we
            record in log files. Depending on how you interact with us, this log data may include your IP address,
            device information, browser type, and settings and information about your activity in the Services (such as
            the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you
            take such as which features you use), device event information (such as system activity, error reports
            (sometimes called "crash dumps"), and hardware settings).</li>
          <li><strong>Device Data</strong>. We collect device data such as information about your computer, phone,
            tablet, or other device you use to access the Services. Depending on the device used, this device data may
            include information such as your IP address (or proxy server), device and application identification
            numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating
            system, and system configuration information.</li>
          <li>
            <strong>Location Data</strong>. We collect location data such as information about your device's location,
            which can be either precise or imprecise. How much information we collect depends on the type and settings
            of the device you use to access the Services. For example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your IP address). You can opt out of allowing
            us to collect this information either by refusing access to the information or by disabling your Location
            setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the
            Services.
          </li>
        </ul>

        <h4>2. HOW DO WE PROCESS YOUR INFORMATION?</h4>
        <p>
          <strong>In Short</strong>: We process your information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply with law. We may also process your
          information for other purposes with your consent.
        </p>
        <p>We process your personal information for a variety of reasons, depending on how you interact with our
          Services, including:</p>
        <ul>
          <li><strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong>
            We may process your information so you can create and log in to your account, as well as keep your account
            in working order.</li>
          <li><strong>To deliver and facilitate delivery of services to the user</strong>. We may process your
            information to provide you with the requested service.</li>
          <li><strong>To respond to user inquiries/offer support to users</strong>. We may process your information to
            respond to your inquiries and solve any potential issues you might have with the requested service.</li>
          <li><strong>To fulfill and manage your orders</strong>. We may process your information to fulfill and manage
            your orders, payments, returns, and exchanges made through the Services.</li>
          <li><strong>To enable user-to-user communications</strong>. We may process your information if you choose to
            use any of our offerings that allow for communication with another user.</li>
          <li><strong>To request feedback</strong>. We may process your information when necessary to request feedback
            and to contact you about your use of our Services.</li>
          <li><strong>To send you marketing and promotional communications</strong>. We may process the personal
            information you send to us for our marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time. For more information, see "WHAT ARE YOUR
            PRIVACY RIGHTS?" below.</li>
          <li><strong>To deliver targeted advertising to you</strong>. We may process your information to develop and
            display personalized content and advertising tailored to your interests, location, and more.</li>
          <li><strong>To protect our Services</strong>. We may process your information as part of our efforts to keep
            our Services safe and secure, including fraud monitoring and prevention.</li>
          <li><strong>To save or protect an individual's vital interest</strong>. We may process your information when
            necessary to save or protect an individual’s vital interest, such as to prevent harm.</li>
          <li><strong>Gaming Preferences</strong>. To determine which news and esports matches to show you.</li>
        </ul>

        <h4>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h4>

        <p><strong>In Short</strong>: We only process your personal information when we believe it is necessary and we
          have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply
          with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your
          rights, or to fulfill our legitimate business interests.</p>
        <p><strong>If you are located in the EU or UK, this section applies to you.</strong></p>
        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely
          on in order to process your personal information. As such, we may rely on the following legal bases to process
          your personal information:</p>
        <ul>
          <li><strong>Consent</strong>. We may process your information if you have given us permission (i.e., consent)
            to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn
            more about withdrawing your consent.</li>
          <li><strong>Performance of a Contract</strong>. We may process your personal information when we believe it is
            necessary to fulfill our contractual obligations to you, including providing our Services or at your request
            prior to entering into a contract with you.</li>
          <li><strong>Legitimate Interests</strong>. We may process your information when we believe it is reasonably
            necessary to achieve our legitimate business interests and those interests do not outweigh your interests
            and fundamental rights and freedoms. For example, we may process your personal information for some of the
            purposes described in order to:
            <ul>
              <li>Send users information about special offers and discounts on our products and services</li>
              <li>Develop and display personalized and relevant advertising content for our users</li>
              <li>Diagnose problems and/or prevent fraudulent activities</li>
              <li>Understand how our users use our products and services so we can improve user experience</li>
            </ul>
          </li>
          <li>
            <strong>Legal Obligations</strong>. We may process your information where we believe it is necessary for
            compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory
            agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which
            we are involved.
          </li>
          <li>
            <strong>Vital Interests</strong>. We may process your information where we believe it is necessary to
            protect your vital interests or the vital interests of a third party, such as situations involving potential
            threats to the safety of any person.
          </li>
        </ul>
        <p><strong>If you are located in Canada, this section applies to you.</strong></p>
        <p>We may process your information if you have given us specific permission (i.e., express consent) to use your
          personal information for a specific purpose, or in situations where your permission can be inferred (i.e.,
          implied consent). You can withdraw your consent at any time.</p>
        <p>In some exceptional cases, we may be legally permitted under applicable law to process your information
          without your consent, including, for example:</p>
        <ul>
          <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
          <li>For investigations and fraud detection and prevention</li>
          <li>For business transactions provided certain conditions are met</li>
          <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an
            insurance claim</li>
          <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
          <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
          <li>If it is reasonable to expect collection and use with consent would compromise the availability or the
            accuracy of the information and the collection is reasonable for purposes related to investigating a breach
            of an agreement or a contravention of the laws of Canada or a province</li>
          <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating
            to the production of records</li>
          <li>If it was produced by an individual in the course of their employment, business, or profession and the
            collection is consistent with the purposes for which the information was produced</li>
          <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
          <li>If the information is publicly available and is specified by the regulations</li>
        </ul>

        <h4>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h4>

        <p><strong>In Short</strong>: We may share information in specific situations described in this section and/or
          with the following categories of third parties.</p>
        <p><strong>Vendors, Consultants, and Other Third-Party Service Providers</strong>. We may share your data with
          third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us
          or on our behalf and require access to such information to do that work. We have contracts in place with our
          third parties, which are designed to help safeguard your personal information. This means that they cannot do
          anything with your personal information unless we have instructed them to do it. They will also not share your
          personal information with any organization apart from us. They also commit to protect the data they hold on
          our behalf and to retain it for the period we instruct. The categories of third parties we may share personal
          information with are as follows:</p>
        <ul>
          <li>Ad Networks</li>
          <li>Affiliate Marketing Programs</li>
          <li>Payment Processors</li>
          <li>Performance Monitoring Tools</li>
        </ul>
        <p>We also may need to share your personal information in the following situations:</p>
        <ul>
          <li><strong>Business Transfers</strong>. We may share or transfer your information in connection with, or
            during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of
            our business to another company.</li>
          <li><strong>Affiliates</strong>. We may share your information with our affiliates, in which case we will
            require those affiliates to honor this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we control or that are under common control
            with us.</li>
        </ul>

        <h4>5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</h4>

        <p><strong>In Short</strong>: We are not responsible for the safety of any information that you share with third
          parties that we may link to or who advertise on our Services, but are not affiliated with, our Services.</p>
        <p>The Services may link to third-party websites, online services, or mobile applications and/or contain
          advertisements from third parties that are not affiliated with us and which may link to other websites,
          services, or applications. Accordingly, we do not make any guarantee regarding any such third parties, and we
          will not be liable for any loss or damage caused by the use of such third-party websites, services, or
          applications. The inclusion of a link towards a third-party website, service, or application does not imply an
          endorsement by us. We cannot guarantee the safety and privacy of data you provide to any third parties. Any
          data collected by third parties is not covered by this privacy notice. We are not responsible for the content
          or privacy and security practices and policies of any third parties, including other websites, services, or
          applications that may be linked to or from the Services. You should review the policies of such third parties
          and contact them directly to respond to your questions.</p>

        <h4>6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>

        <p><strong>In Short</strong>: We may use cookies and other tracking technologies to collect and store your
          information.</p>
        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
          information. Specific information about how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.</p>

        <h4>7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>

        <p><strong>In Short</strong>: If you choose to register or log in to our Services using a social media account,
          we may have access to certain information about you.</p>
        <p>Our Services offer you the ability to register and log in using your third-party social media account details
          (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile
          information about you from your social media provider. The profile information we receive may vary depending
          on the social media provider concerned, but will often include your name, email address, friends list, and
          profile picture, as well as other information you choose to make public on such a social media platform.</p>
        <p>We will use the information we receive only for the purposes that are described in this privacy notice or
          that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not
          responsible for, other uses of your personal information by your third-party social media provider. We
          recommend that you review their privacy notice to understand how they collect, use, and share your personal
          information, and how you can set your privacy preferences on their sites and apps.</p>

        <h4>8. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>

        <p><strong>In Short</strong>: We keep your information for as long as necessary to fulfill the purposes outlined
          in this privacy notice unless otherwise required by law.</p>
        <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this
          privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or
          other legal requirements). No purpose in this notice will require us keeping your personal information for
          longer than the period of time in which users have an account with us.</p>
        <p>When we have no ongoing legitimate business need to process your personal information, we will either delete
          or anonymize such information, or, if this is not possible (for example, because your personal information has
          been stored in backup archives), then we will securely store your personal information and isolate it from any
          further processing until deletion is possible.</p>

        <div class='nk-gap'></div>
        <h4>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>

        <p><strong>In Short</strong>: We aim to protect your personal information through a system of organizational and
          technical security measures.</p>
        <p>We have implemented appropriate and reasonable technical and organizational security measures designed to
          protect the security of any personal information we process. However, despite our safeguards and efforts to
          secure your information, no electronic transmission over the Internet or information storage technology can be
          guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your personal information, transmission of
          personal information to and from our Services is at your own risk. You should only access the Services within
          a secure environment.</p>

        <h4>10. WHAT ARE YOUR PRIVACY RIGHTS?</h4>

        <p><strong>In Short</strong>: In some regions, such as the European Economic Area (EEA), United Kingdom (UK),
          Switzerland, and Canada, you have rights that allow you greater access to and control over your personal
          information. You may review, change, or terminate your account at any time.</p>
        <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data
          protection laws. These may include the right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
          information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making.
          In certain circumstances, you may also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the contact details provided in the section
          "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.</p>
        <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
        <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information,
          you also have the right to complain to your Member State data protection authority or UK data protection
          authority.</p>
        <p>If you are located in Switzerland, you may contact the Federal Data Protection and Information Commissioner.</p>
        <p>
          <strong>Withdrawing your consent</strong>: If we are relying on your consent to process your personal
          information, which may be express and/or implied consent depending on the applicable law, you have the right
          to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the
          contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below or updating your
          preferences.
        </p>
        <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor,
          when applicable law allows, will it affect the processing of your personal information conducted in reliance
          on lawful processing grounds other than consent.</p>
        <p>
          <strong>Opting out of marketing and promotional communications</strong>: You can unsubscribe from our
          marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we
          send, or by contacting us using the details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS
          NOTICE?" below. You will then be removed from the marketing lists. However, we may still communicate with you
          — for example, to send you service-related messages that are necessary for the administration and use of your
          account, to respond to service requests, or for other non-marketing purposes.
        </p>

        <h5>Account Information</h5>

        <p>If you would at any time like to review or change the information in your account or terminate your account,
          you can:</p>
        <ul>
          <li>Log in to your account settings and update your user account.</li>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or delete your account and information from
          our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
          problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal
          requirements.
        </p>
        <p>
          <strong>Cookies and similar technologies</strong>: Most Web browsers are set to accept cookies by default. If
          you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose
          to remove cookies or reject cookies, this could affect certain features or services of our Services. You may
          also opt out of interest-based advertising by advertisers on our Services.
        </p>
        <p>If you have questions or comments about your privacy rights, you may email us at admin@1vX.gg.</p>

        <h4>11. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>

        <p>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT")
          feature or setting you can activate to signal your privacy preference not to have data about your online
          browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
          implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or
          any other mechanism that automatically communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will inform you about that practice in a
          revised version of this privacy notice.
        </p>

        <h4>12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>

        <p><strong>In Short</strong>: If you are a resident of , you are granted specific rights regarding access to
          your personal information.</p>

        <h5>What categories of personal information do we collect?</h5>

        <table class='nk-table'>
          <thead>
            <tr>
              <th colspan='3'>We have collected the following categories of personal information in the past twelve (12)
                months:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th class='text-center'>Collected</th>
            </tr>
            <tr>
              <td>A. Identifiers</td>
              <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
                personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
              <td class='text-center'><strong>YES</strong></td>
            </tr>
            <tr>
              <td>B. Protected classification characteristics under state or federal law</td>
              <td>Gender and date of birth</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>C. Commercial information</td>
              <td>Transaction information, purchase history, financial details, and payment information</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>D. Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>E. Internet or other similar network activity</td>
              <td>Browsing history, search history, online behavior, interest data, and interactions with our and other
                websites, applications, systems, and advertisements</td>
              <td class='text-center'><strong>YES</strong></td>
            </tr>
            <tr>
              <td>F. Geolocation data</td>
              <td>Device location</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>G. Audio, electronic, visual, thermal, olfactory, or similar information</td>
              <td>Images and audio, video or call recordings created in connection with our business activities</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>H. Professional or employment-related information</td>
              <td>Business contact details in order to provide you our Services at a business level or job title, work
                history, and professional qualifications if you apply for a job with us</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>I. Education Information</td>
              <td>Student records and directory information</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>J. Inferences drawn from collected personal information</td>
              <td>Inferences drawn from any of the collected personal information listed above to create a profile or
                summary about, for example, an individual’s preferences and characteristics</td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
            <tr>
              <td>K. Sensitive personal Information</td>
              <td></td>
              <td class='text-center'><strong>NO</strong></td>
            </tr>
          </tbody>
        </table>

        <p>We may also collect other personal information outside of these categories through instances where you
          interact with us in person, online, or by phone or mail in the context of:</p>
        <ul>
          <li>Receiving help through our customer support channels;</li>
          <li>Participation in customer surveys or contests; and</li>
          <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
        </ul>

        <h5>How do we use and share your personal information?</h5>
        <p>Learn about how we use your personal information in the section, "HOW DO WE PROCESS YOUR INFORMATION?"</p>
        <p>We collect and share your personal information through:</p>

        <ul>
          <li>Targeting cookies/Marketing cookies</li>
          <li>Click redirects: Amazon affiliate link.</li>
        </ul>

        <h5>Will your information be shared with anyone else?</h5>

        <p>We may disclose your personal information with our service providers pursuant to a written contract between
          us and each service provider. Learn more about how we disclose personal information to in the section, "WHEN
          AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>
        <p>We may use your personal information for our own business purposes, such as for undertaking internal research
          for technological development and demonstration. This is not considered to be "selling" of your personal
          information.</p>
        <p>We have not sold or shared any personal information to third parties for a business or commercial purpose in
          the preceding twelve (12) months. We have disclosed the following categories of personal information to third
          parties for a business or commercial purpose in the preceding twelve (12) months:</p>
        <p>The categories of third parties to whom we disclosed personal information for a business or commercial
          purpose can be found under "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"</p>

        <h4>13. DO WE MAKE UPDATES TO THIS NOTICE?</h4>

        <p><strong>In Short</strong>: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated
          "Revised" date and the updated version will be effective as soon as it is accessible. If we make material
          changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by
          directly sending you a notification. We encourage you to review this privacy notice frequently to be informed
          of how we are protecting your information.</p>

        <h4>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h4>
        <p>If you have questions or comments about this notice, you may email us at admin@1vX.gg or contact us by post
          at:</p>

        <address>Augmented Interactive, LLC 17290 Preston Rd Dallas, TX 75252 United States</address>

        <h4>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h4>

        <p>You have the right to request access to the personal information we collect from you, change that
          information, or delete it. To request to review, update, or delete your personal information, please visit:
          https://1vX.gg/contact-us.</p>

        <p>This privacy policy was created using Termly.</p>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PrivacyPolicyComponent);
