import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { LinkTo } from '@ember/routing';
import SocialComponent from '../../components/widget/sidebar/social';
import PopularPostsComponent from '../../components/widget/sidebar/popular-posts';
import TagCloudComponent from '../../components/widget/sidebar/tag-cloud';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import LatestReviews from '../../components/widget/sidebar/latest-reviews';
import Markdown from '../../components/markdown';
import { formatDate } from '../../helpers/format-date';
import RelatedArticles from '../../components/layout/news/related-articles';
import SidebarSearch from '../../components/widget/sidebar/search';
import ContentfulBody from '../../components/layout/news/contentful-body';
import { pageTitle } from 'ember-page-title';
let NewsPostComponent = class NewsPostComponent extends Component {
    @tracked
    searchTerm;
    static{
        template(`
    {{pageTitle @model.title}}
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          <div class='post-open gaming-news'>
            <figure class='post-open-img liquid'>
              <img src='{{@model.coverImage.url}}' alt='{{@model.title}}' />
            </figure>
            <div class='post-open-content'>
              <div class='post-open-body'>
                <div class='tag-list'>
                  <LinkTo @route='news.index' class='tag-ornament'>Gaming News</LinkTo>
                </div>
                <p class='post-open-title'>{{@model.title}}</p>
                <div class='post-author-info-wrap'>
                  <LinkTo @route='search'>
                    <figure class='user-avatar tiny liquid'>
                      <img src='{{@model.author.profilePicture.url}}' alt='{{@model.author.displayName}}' />
                    </figure>
                  </LinkTo>
                  <p class='post-author-info small light'>By
                    <LinkTo @route='search' class='post-author'>{{@model.author.displayName}}</LinkTo>
                    <span class='separator'>|</span>{{formatDate @model.publishedDate}}
                  </p>
                </div>
                <ContentfulBody @content={{@model.content}} />
                {{#unless @model.content}}
                  <Markdown @markdown={{@model.body}} />
                {{/unless}}
              </div>
              <div class='post-open-sidebar'>
                <div class='social-links medium vertical animated'>
                  <a
                    href='http://www.facebook.com/share.php?display=popup&u=https://1vX.gg/esports/news/{{@post.slug}}'
                    target='_blank'
                    class='bubble-ornament big fb'
                  >
                    <svg class='facebook-icon big'>
                      <use href='#svg-facebook'></use>
                    </svg>
                    <p class='bubble-ornament-text hover-replace'>Share</p>
                  </a>
                  <a
                    href='https://twitter.com/intent/tweet?text={{@model.shortDescription}}&url=https://1vX.gg/esports/news/{{@model.slug}}&hashtags=esports&via=1vxgg'
                    target='_blank'
                    class='bubble-ornament big twt'
                  >
                    <svg class='twitter-icon big'>
                      <use href='#svg-twitter'></use>
                    </svg>
                    <p class='bubble-ornament-text hover-replace'>Share</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {{#if @model.relatedArticles.items}}
            <RelatedArticles @articles={{@model.relatedArticles.items}} />
          {{/if}}
        </div>
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <SidebarSearch />
        <SocialComponent />
        <PopularPostsComponent />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(NewsPostComponent);
