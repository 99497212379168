export default function XMArc() {
  var e = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {},
    o = {
      width: 112,
      height: 112,
      startAngle: 1.5 * Math.PI,
      endAngle: 3.5 * Math.PI,
      anticlockwise: !1,
      lineWidth: 10,
      lineColor: '#7442ce',
      speed: 70,
      scale: { start: 0, end: 1, stop: 1 },
      animation: { easing: !0 },
      linkText: !0,
      innerLink: !0,
      linkTo: '.arc-progress-text',
      extraLinkTo: [],
      linkUnitsSelector: '.arc-progress-unit',
      decimalPoints: 1,
      animateOnScroll: !1,
      controlAnimation: !1,
      topHalf: !1,
      pad: !1,
      shadow: !1,
      shadowColor: '#000',
      shadowBlur: 30,
    },
    a = document.createElement('canvas'),
    s = a.getContext('2d'),
    n = this;
  (n.done = !1), (n.config = o);
  var t = function (e, n) {
    var t = n[0],
      a = n.substring(1);
    '.' === t && e.setAttribute('class', a),
      '#' === t && e.setAttribute('id', a);
  };
  function i() {
    for (
      var e =
          (window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop) + window.innerHeight,
        n = a,
        t = n.offsetTop;
      (n = n.offsetParent);

    )
      t += n.offsetTop;
    return t <= e;
  }
  function l() {
    o.anticlockwise
      ? o.animation.currentAngle > o.stopAngle &&
        ((o.animation.currentAngle -= o.animation.step),
        o.animation.currentAngle < o.stopAngle &&
          ((o.animation.currentAngle = o.stopAngle), (n.done = !0)))
      : o.animation.currentAngle < o.stopAngle &&
        (o.animation.easing
          ? ((o.animation.currentAngle +=
              0.06 * (o.stopAngle - o.animation.currentAngle)),
            o.animation.currentAngle > o.stopAngle - 0.01 &&
              ((o.animation.currentAngle = o.stopAngle), (n.done = !0)))
          : ((o.animation.currentAngle += o.animation.step),
            o.animation.currentAngle > o.stopAngle &&
              ((o.animation.currentAngle = o.stopAngle), (n.done = !0)))),
      c(o.animation.currentAngle),
      n.done || window.requestAnimationFrame(l);
  }
  var r = function (e) {
    return e < 10 ? '0'.concat(e) : e;
  };
  function c() {
    var e,
      n,
      t,
      a,
      i =
        0 < arguments.length && void 0 !== arguments[0]
          ? arguments[0]
          : o.stopAngle;
    o.linkText &&
      ((e = i),
      0 === (a = o.stopAngle - o.startAngle) && (a = o.endAngle - o.startAngle),
      (n = o.anticlockwise
        ? o.scale.end - ((e - o.endAngle) / a) * (o.scale.stop - o.scale.end)
        : ((e - o.startAngle) / a) * (o.scale.stop - o.scale.start)),
      (t = n.toFixed(o.decimalPoints)),
      o.pad && (t = r(t)),
      o.innerLink &&
        ((o.linkEl.innerHTML = t),
        o.linkUnits && o.linkEl.append(o.linkUnitsEl)),
      0 < o.extraLinkTo.length &&
        o.extraLinkEl.forEach(function (e) {
          e.innerHTML = t;
        })),
      o.shadow
        ? (s.clearRect(
            0,
            0,
            o.width + o.shadowOffset,
            o.height + o.shadowOffset,
          ),
          s.beginPath(),
          s.arc(
            o.center.x + o.shadowOffset / 2,
            o.center.y + o.shadowOffset / 2,
            o.radius,
            o.startAngle,
            i,
          ))
        : (s.clearRect(0, 0, o.width, o.height),
          s.beginPath(),
          s.arc(o.center.x, o.center.y, o.radius, o.startAngle, i)),
      s.stroke();
  }
  (n.render = function (e) {
    (e =
      (e = e > o.scale.end ? o.scale.end : e) < o.scale.start
        ? o.scale.start
        : e),
      c(
        o.startAngle +
          ((o.endAngle - o.startAngle) /
            Math.abs(o.scale.start - o.scale.end)) *
            e,
      );
  }),
    Object.assign(o, e),
    o.topHalf && ((o.startAngle = Math.PI), (o.endAngle = 2 * Math.PI)),
    (o.center = { x: o.width / 2, y: o.height / 2 }),
    (o.radius = (o.width - o.lineWidth) / 2),
    e.scale &&
      ((o.scale.stop = o.scale.stop > o.scale.end ? o.scale.end : o.scale.stop),
      (o.scale.stop =
        o.scale.stop < o.scale.start ? o.scale.start : o.scale.stop),
      (o.scale.stop = void 0 !== e.scale.stop ? o.scale.stop : o.scale.end)),
    o.anticlockwise
      ? (o.animation.currentAngle = o.endAngle)
      : (o.animation.currentAngle = o.startAngle),
    o.shadow && (o.shadowOffset = o.shadowBlur + 10),
    (o.stopAngle =
      o.startAngle +
      ((o.endAngle - o.startAngle) / (o.scale.end - o.scale.start)) *
        o.scale.stop),
    (function () {
      if (
        (o.shadow
          ? ((a.width = o.width + o.shadowOffset),
            (a.height = o.height + o.shadowOffset),
            (a.style.top = '-'.concat(o.shadowOffset / 2, 'px')),
            (a.style.left = '-'.concat(o.shadowOffset / 2, 'px')))
          : ((a.width = o.width), (a.height = o.height)),
        (a.style.position = 'absolute'),
        (n.container = document.querySelector(o.container)),
        !n.container)
      )
        throw Error('Unable to find "'.concat(o.container, '" on the DOM'));
      (n.container.style.width = ''.concat(o.width, 'px')),
        (n.container.style.height = ''.concat(o.height, 'px')),
        (n.container.style.position = 'relative'),
        o.topHalf &&
          ((a.height = o.height / 2),
          (n.container.style.height = ''.concat(o.height / 2, 'px'))),
        n.container.append(a);
    })(),
    o.linkText &&
      (o.innerLink &&
        ((o.linkEl = document.createElement('p')),
        t(o.linkEl, o.linkTo),
        n.container.append(o.linkEl),
        o.linkUnits &&
          ((o.linkUnitsEl = document.createElement('span')),
          t(o.linkUnitsEl, o.linkUnitsSelector),
          (o.linkUnitsEl.innerHTML = o.linkUnits))),
      0 < o.extraLinkTo.length &&
        ((o.extraLinkEl = []),
        o.extraLinkTo.forEach(function (e) {
          o.extraLinkEl.push(document.querySelector(e));
        }))),
    (o.speed = o.speed <= 0 ? 1 : o.speed),
    (o.speed = 100 < o.speed ? 100 : o.speed),
    (o.animation.step = Math.PI / (2e3 / o.speed)),
    (function () {
      if (o.gradient) {
        var e = s.createLinearGradient(0, o.height, o.width, 0);
        e.addColorStop(0, o.gradient.colors[0]),
          e.addColorStop(1, o.gradient.colors[1]),
          (s.strokeStyle = e);
      } else s.strokeStyle = o.lineColor;
      o.shadow &&
        ((s.shadowColor = o.shadowColor), (s.shadowBlur = o.shadowBlur)),
        (s.lineWidth = o.lineWidth);
    })(),
    o.animateOnScroll
      ? i()
        ? window.requestAnimationFrame(l)
        : window.addEventListener('scroll', function e() {
            i() &&
              (window.requestAnimationFrame(l),
              window.removeEventListener('scroll', e));
          })
      : o.controlAnimation || c();
}

// {
//   container: '#content-news-rate-1',
//     width: 112,
//   height: 112,
//   lineWidth: 10,
//   gradient: {
//   colors: ['#5216fd', '#ff055d']
// },
//   scale: {
//     start: 0,
//       end: 10,
//       stop: 8.7
//   },
//   shadow: true
// }
