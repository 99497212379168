import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { ESPORTS_BANNER_QUERY } from '../../../gql/queries/components/esports-banner';
import { useQuery } from 'glimmer-apollo';
export default class EsportsBanner extends Component {
    query = useQuery(this, ()=>[
            ESPORTS_BANNER_QUERY,
            {
                variables: {
                    slug: 'esports-banner'
                }
            }
        ]);
    get articles() {
        return this.query.data?.featured?.items[0].articles?.items ?? [];
    }
    get firstArticle() {
        return this.articles[0];
    }
    get secondArticle() {
        return this.articles[1];
    }
    get thirdArticle() {
        return this.articles[2];
    }
    get fourthArticle() {
        return this.articles[3];
    }
    static{
        template(`
    <div class='banner-post'>
      <div class='banner-post-item'>
        <div class='post-preview picture large e-sport'>
          <LinkTo @route='esports.post' @model='{{this.firstArticle.slug}}'>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='{{this.firstArticle.coverImage.url}}' alt='{{this.firstArticle.title}}' />
              </figure>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>eSports</span>
                <p class='post-preview-title'>
                  {{this.firstArticle.title}}
                </p>
                <p class='post-preview-text'>
                  {{this.firstArticle.shortDescription}}
                </p>
              </div>
            </div>
          </LinkTo>
        </div>
      </div>
      <div class='banner-post-item'>
        <div class='post-preview picture big e-sport'>
          <LinkTo @route='esports.post' @model={{this.secondArticle.slug}}>
            <div class='post-preview-img-wrap'>
              <figure class='post-preview-img liquid'>
                <img src='{{this.secondArticle.coverImage.url}}' alt='{{this.secondArticle.title}}' />
              </figure>
              <div class='post-preview-overlay'>
                <span class='tag-ornament'>eSports</span>
                <p class='post-preview-title'>{{this.secondArticle.title}}</p>
                <p class='post-preview-text'>{{this.secondArticle.shortDescription}}</p>
              </div>
            </div>
          </LinkTo>
        </div>
        <div class='banner-post-item'>
          <div class='post-preview picture medium e-sport'>
            <LinkTo @route='esports.post' @model={{this.thirdArticle.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{this.thirdArticle.coverImage.url}}' alt='{{this.thirdArticle.title}}' />
                </figure>
                <div class='post-preview-overlay'>
                  <span class='tag-ornament'>eSports</span>
                  <p class='post-preview-title'>{{this.thirdArticle.title}}</p>
                </div>
              </div>
            </LinkTo>
          </div>
        </div>
        <div class='banner-post-item'>
          <div class='post-preview picture medium e-sport'>
            <LinkTo @route='esports.post' @model={{this.fourthArticle.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{this.fourthArticle.coverImage.url}}' alt='{{this.fourthArticle.title}}' />
                </figure>
                <div class='post-preview-overlay'>
                  <span class='tag-ornament'>eSports</span>
                  <p class='post-preview-title'>{{this.fourthArticle.title}}</p>
                </div>
              </div>
            </LinkTo>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
