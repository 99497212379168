import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import { LinkTo } from '@ember/routing';
// TODO: add tracking event for 404 page that shows what the attempted route was that got the user here as an extra param
let FourOhFourRouteComponent = class FourOhFourRouteComponent extends Component {
    static{
        template(`
    <div class='layout-content-full'>
      <div class='error-display grid-limit'>
        <figure class='error-img liquid'>
          <img src='/img/other/error-img.png' alt='error-img' />
        </figure>
        <p class='error-title'>Oooooopsss!</p>
        <p class='error-subtitle'>Seems that something went wrong</p>
        <p class='error-text'>The page you are looking for has been moved or doesn’t exist anymore, if you like you can
          return to the previous page, or go to our main homepage. If the problem persists, please send an email to
          <a class='highlight' href='mailto:admin@1vX.gg'>admin@1vX.gg</a></p>
      </div>
      <div class='section-actions'>
        <LinkTo @route='index' class='button violet'>
          Go to main page
          <div class='button-ornament'>
            <svg class='arrow-icon medium'>
              <use href='#svg-arrow-medium'></use>
            </svg>
            <svg class='cross-icon small'>
              <use href='#svg-cross-small'></use>
            </svg>
          </div>
        </LinkTo>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(FourOhFourRouteComponent);
