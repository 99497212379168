import { template } from "@ember/template-compiler";
import breev from '../../../helpers/breev';
import { get } from '@ember/helper';
const outcomeText = (a1, b1)=>{
    return a1 === b1 ? 'Victory' : 'Defeat';
};
export default template(`
  <div class='v2 grid-limit layout-item'>
    <div class='widget-item'>
      <div class='team-lineups-header medium full'>
        <div class='team-info-wrap mid'>
          <img class='team-logo mid' src='{{@teamA.logo.url}}' alt='{{@teamA.name}}' />
          <div class='team-info'>
            <p class='team-name'>
              {{breev @teamA.name}}
              {{#if @match.isFinal}}
                |
                <span class='highlight team-blue'>{{outcomeText @match.victor.slug @teamA.slug}}</span>
              {{/if}}
            </p>
            <p class='team-country'>{{@teamA.country}}</p>
          </div>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon tower-icon blue'>
            <use href='#svg-tower'></use>
          </svg>
          <p class='featured-stat-title'>7</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon inhibitor-icon blue'>
            <use href='#svg-inhibitor'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon baron-icon blue'>
            <use href='#svg-baron'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon dragon-icon blue'>
            <use href='#svg-dragon'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon rift-herald-icon blue'>
            <use href='#svg-rift-herald'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap regular right'>
          <p class='featured-stat-title'><span class='highlight'>36</span>
            /
            <span class='highlight'>27</span>
            /
            <span class='highlight'>70</span></p>
          <p class='featured-stat-text'>K / D / A</p>
        </div>
        <div class='featured-stat-wrap regular left'>
          <p class='featured-stat-title void'><span class='highlight'>27</span>
            /
            <span class='highlight'>37</span>
            /
            <span class='highlight'>31</span></p>
          <p class='featured-stat-text'>K / D / A</p>
        </div>
        <div class='featured-stat-wrap multiple-items inverted'>
          <svg class='featured-stat-icon rift-herald-icon red'>
            <use href='#svg-rift-herald'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap multiple-items inverted'>
          <svg class='featured-stat-icon dragon-icon red'>
            <use href='#svg-dragon'></use>
          </svg>
          <p class='featured-stat-title'>2</p>
        </div>
        <div class='featured-stat-wrap multiple-items inverted'>
          <svg class='featured-stat-icon baron-icon red'>
            <use href='#svg-baron'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items inverted'>
          <svg class='featured-stat-icon inhibitor-icon red'>
            <use href='#svg-inhibitor'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap multiple-items inverted'>
          <svg class='featured-stat-icon tower-icon red'>
            <use href='#svg-tower'></use>
          </svg>
          <p class='featured-stat-title'>3</p>
        </div>
        <div class='team-info-wrap mid inverted'>
          <img class='team-logo mid' src='{{@teamB.logo.url}}' alt='{{@teamB.name}}' />
          <div class='team-info'>
            <p class='team-name'>
              {{#if @match.isFinal}}
                <span class='highlight red'>{{outcomeText @match.victor.slug @teamB.slug}}</span>
                |
              {{/if}}
              {{breev @teamB.name}}
            </p>
            <p class='team-country'>{{@teamB.country}}</p>
          </div>
        </div>
      </div>
      <div class='table lineups full-stats full'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Character / Player</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K/D/A</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Gold</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>CS</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Wards</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K%</p>
          </div>
          <div class='table-row-header-item'></div>
          <div class='table-row-header-item'></div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K%</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Wards</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>CS</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Gold</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K/D/A</p>
          </div>
          <div class='table-row-header-item right'>
            <p class='table-row-header-title'>Character / Player</p>
          </div>
        </div>
        <div class='table-rows no-color'>
          {{#each @teamA.players.items as |player index|}}
            <div class='table-row big'>
              <div class='table-row-item'>
                <div class='team-info-wrap medium'>
                  <div class='user-avatar-wrap'>
                    <img class='user-avatar character v2 blue' src='{{player.photo.url}}' alt='character-01' />
                    <p class='user-avatar-text blue'>16</p>
                  </div>
                  <div class='skill-loot-list vertical'>
                    <img
                      class='skill-loot-item skill-loot-img'
                      src='/img/characters/skills/skill-01.png'
                      alt='skill-01'
                    />
                    <img
                      class='skill-loot-item skill-loot-img'
                      src='/img/characters/skills/skill-02.png'
                      alt='skill-02'
                    />
                  </div>
                  <div class='team-info'>
                    <p class='team-name'>{{player.gamertag}}</p>
                    <p class='team-country'>{{player.role}}</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'><span class='highlight'>9</span>
                  /
                  <span class='highlight'>4</span>
                  /
                  <span class='highlight'>8</span></p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>12934</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>183</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>0 25 2</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>47</p>
              </div>
              <div class='table-row-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-01.png'
                    alt='item-01'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-02.png'
                    alt='item-02'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-03.png'
                    alt='item-03'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-04.png'
                    alt='item-04'
                  />
                </div>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-05.png'
                    alt='item-05'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-06.png'
                    alt='item-06'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-07.png'
                    alt='item-07'
                  />
                </div>
              </div>
              <div class='table-row-item'>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-08.png'
                    alt='item-08'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-09.png'
                    alt='item-09'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-10.png'
                    alt='item-10'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-11.png'
                    alt='item-11'
                  />
                </div>
                <div class='skill-loot-list'>
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-12.png'
                    alt='item-12'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-13.png'
                    alt='item-13'
                  />
                  <img
                    class='skill-loot-item skill-loot-img small'
                    src='/img/characters/loot/item-14.png'
                    alt='item-14'
                  />
                </div>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>37</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>1 10 9</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>211</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'>11973</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bolder'><span class='highlight'>7</span>
                  /
                  <span class='highlight'>5</span>
                  /
                  <span class='highlight'>3</span></p>
              </div>
              <div class='table-row-item'>
                <div class='team-info-wrap medium inverted'>
                  <div class='user-avatar-wrap'>
                    <img
                      class='user-avatar character v2 red'
                      src='{{get (get @teamB.players.items index) "photo.url"}}'
                      alt='character-06'
                    />
                    <p class='user-avatar-text red'>14</p>
                  </div>
                  <div class='skill-loot-list vertical'>
                    <img
                      class='skill-loot-item skill-loot-img'
                      src='/img/characters/skills/skill-11.png'
                      alt='skill-11'
                    />
                    <img
                      class='skill-loot-item skill-loot-img'
                      src='/img/characters/skills/skill-12.png'
                      alt='skill-12'
                    />
                  </div>
                  <div class='team-info'>
                    <p class='team-name'>{{get (get @teamB.players.items index) 'gamertag'}}</p>
                    <p class='team-country'>{{get (get @teamB.players.items index) 'role'}}</p>
                  </div>
                </div>
              </div>
            </div>
          {{/each}}
        </div>
      </div>
      <div class='widget-row filled full'>
        <div class='ban-list-wrap'>
          <p class='ban-list-title'>Team Bans</p>
          <div class='ban-list'>
            <img class='ban-list-item user-avatar micro' src='/img/characters/09.png' alt='character-09' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/07.png' alt='character-07' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/10.png' alt='character-10' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/08.png' alt='character-08' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/06.png' alt='character-06' />
          </div>
        </div>
        <div class='ban-list-wrap inverted'>
          <p class='ban-list-title'>Team Bans</p>
          <div class='ban-list'>
            <img class='ban-list-item user-avatar micro' src='/img/characters/03.png' alt='character-03' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/02.png' alt='character-02' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/04.png' alt='character-04' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/01.png' alt='character-01' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/05.png' alt='character-05' />
          </div>
        </div>
      </div>
      <div class='team-lineups-header medium mobile'>
        <div class='team-info-wrap mid'>
          <img class='team-logo mid' src='/img/teams/logos/01.png' alt='logo-01' />
          <div class='team-info'>
            <p class='team-name'>TLW | <span class='highlight team-blue'>Victory</span></p>
            <p class='team-country'>United States</p>
          </div>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon tower-icon blue'>
            <use href='#svg-tower'></use>
          </svg>
          <p class='featured-stat-title'>7</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon inhibitor-icon blue'>
            <use href='#svg-inhibitor'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon baron-icon blue'>
            <use href='#svg-baron'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon dragon-icon blue'>
            <use href='#svg-dragon'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon rift-herald-icon blue'>
            <use href='#svg-rift-herald'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap regular right'>
          <p class='featured-stat-title'><span class='highlight'>36</span>
            /
            <span class='highlight'>27</span>
            /
            <span class='highlight'>70</span></p>
          <p class='featured-stat-text'>K / D / A</p>
        </div>
      </div>
      <div class='table lineups full-stats mobile'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Character / Player</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K/D/A</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Gold</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>CS</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Wards</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K%</p>
          </div>
          <div class='table-row-header-item'></div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 blue' src='/img/characters/01.png' alt='character-01' />
                  <p class='user-avatar-text blue'>16</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-01.png'
                    alt='skill-01'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-02.png'
                    alt='skill-02'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Joystickz-70</p>
                  <p class='team-country'>Mid | Solo</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>9</span>
                /
                <span class='highlight'>4</span>
                /
                <span class='highlight'>8</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>12934</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>183</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>0 25 2</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>47</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-01.png'
                  alt='item-01'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-02.png'
                  alt='item-02'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-03.png'
                  alt='item-03'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-04.png'
                  alt='item-04'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-05.png'
                  alt='item-05'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-06.png'
                  alt='item-06'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-07.png'
                  alt='item-07'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 blue' src='/img/characters/02.png' alt='character-02' />
                  <p class='user-avatar-text blue'>14</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-03.png'
                    alt='skill-03'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-04.png'
                    alt='skill-04'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Dr.Chaos</p>
                  <p class='team-country'>Bot | Support</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>1</span>
                /
                <span class='highlight'>6</span>
                /
                <span class='highlight'>21</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>9573</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>11</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>4 27 4</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>61</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-08.png'
                  alt='item-08'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-09.png'
                  alt='item-09'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-10.png'
                  alt='item-10'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-11.png'
                  alt='item-11'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-12.png'
                  alt='item-12'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-13.png'
                  alt='item-13'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-14.png'
                  alt='item-14'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 blue' src='/img/characters/03.png' alt='character-03' />
                  <p class='user-avatar-text blue'>16</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-05.png'
                    alt='skill-05'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-06.png'
                    alt='skill-06'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Firestorm</p>
                  <p class='team-country'>Bot | Carry</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>11</span>
                /
                <span class='highlight'>6</span>
                /
                <span class='highlight'>10</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>16109</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>267</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>1 11 3</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>58</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-22.png'
                  alt='item-22'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-23.png'
                  alt='item-23'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-24.png'
                  alt='item-24'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-25.png'
                  alt='item-25'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-27.png'
                  alt='item-27'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-28.png'
                  alt='item-28'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-29.png'
                  alt='item-29'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 blue' src='/img/characters/04.png' alt='character-04' />
                  <p class='user-avatar-text blue'>16</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-07.png'
                    alt='skill-07'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-08.png'
                    alt='skill-08'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>DABLADE_NT</p>
                  <p class='team-country'>Top | Solo</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>8</span>
                /
                <span class='highlight'>5</span>
                /
                <span class='highlight'>17</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>12840</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>174</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>2 12 6</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>69</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-15.png'
                  alt='item-15'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-16.png'
                  alt='item-16'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-17.png'
                  alt='item-17'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-18.png'
                  alt='item-18'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-19.png'
                  alt='item-19'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-20.png'
                  alt='item-20'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-21.png'
                  alt='item-21'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 blue' src='/img/characters/05.png' alt='character-05' />
                  <p class='user-avatar-text blue'>14</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-09.png'
                    alt='skill-09'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-10.png'
                    alt='skill-10'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Keymaster</p>
                  <p class='team-country'>Jungle</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>7</span>
                /
                <span class='highlight'>6</span>
                /
                <span class='highlight'>14</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>11193</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>111</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>5 35 4</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>58</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-29.png'
                  alt='item-29'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-30.png'
                  alt='item-30'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-31.png'
                  alt='item-31'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-32.png'
                  alt='item-32'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-33.png'
                  alt='item-33'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-34.png'
                  alt='item-34'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-35.png'
                  alt='item-35'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-row filled mobile'>
        <div class='ban-list-wrap'>
          <p class='ban-list-title'>Team Bans</p>
          <div class='ban-list'>
            <img class='ban-list-item user-avatar micro' src='/img/characters/09.png' alt='character-09' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/07.png' alt='character-07' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/10.png' alt='character-10' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/08.png' alt='character-08' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/06.png' alt='character-06' />
          </div>
        </div>
      </div>
      <div class='team-lineups-header medium mobile'>
        <div class='team-info-wrap mid'>
          <img class='team-logo mid' src='/img/teams/logos/02.png' alt='logo-02' />
          <div class='team-info'>
            <p class='team-name'>RRH | <span class='highlight red'>Defeat</span></p>
            <p class='team-country'>South Africa</p>
          </div>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon tower-icon red'>
            <use href='#svg-tower'></use>
          </svg>
          <p class='featured-stat-title'>3</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon inhibitor-icon red'>
            <use href='#svg-inhibitor'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon baron-icon red'>
            <use href='#svg-baron'></use>
          </svg>
          <p class='featured-stat-title'>1</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon dragon-icon red'>
            <use href='#svg-dragon'></use>
          </svg>
          <p class='featured-stat-title'>2</p>
        </div>
        <div class='featured-stat-wrap multiple-items'>
          <svg class='featured-stat-icon rift-herald-icon red'>
            <use href='#svg-rift-herald'></use>
          </svg>
          <p class='featured-stat-title'>0</p>
        </div>
        <div class='featured-stat-wrap regular right'>
          <p class='featured-stat-title void'><span class='highlight'>27</span>
            /
            <span class='highlight'>37</span>
            /
            <span class='highlight'>31</span></p>
          <p class='featured-stat-text'>K / D / A</p>
        </div>
      </div>
      <div class='table lineups full-stats mobile'>
        <div class='table-row-header big bordered colored'>
          <div class='table-row-header-item left'>
            <p class='table-row-header-title'>Character / Player</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K/D/A</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Gold</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>CS</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>Wards</p>
          </div>
          <div class='table-row-header-item padded'>
            <p class='table-row-header-title'>K%</p>
          </div>
          <div class='table-row-header-item'></div>
        </div>
        <div class='table-rows no-color'>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 red' src='/img/characters/06.png' alt='character-06' />
                  <p class='user-avatar-text red'>14</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-11.png'
                    alt='skill-11'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-12.png'
                    alt='skill-12'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Pixelus.GD</p>
                  <p class='team-country'>Bot | Carry</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>7</span>
                /
                <span class='highlight'>5</span>
                /
                <span class='highlight'>3</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>11973</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>211</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>1 10 9</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>37</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-08.png'
                  alt='item-08'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-09.png'
                  alt='item-09'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-10.png'
                  alt='item-10'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-11.png'
                  alt='item-11'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-12.png'
                  alt='item-12'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-13.png'
                  alt='item-13'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-14.png'
                  alt='item-14'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 red' src='/img/characters/07.png' alt='character-07' />
                  <p class='user-avatar-text red'>12</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-13.png'
                    alt='skill-13'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-14.png'
                    alt='skill-14'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Striker15</p>
                  <p class='team-country'>Bot | Support</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>2</span>
                /
                <span class='highlight'>9</span>
                /
                <span class='highlight'>4</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>8913</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>57</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>4 22 7</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>22</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-01.png'
                  alt='item-01'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-02.png'
                  alt='item-02'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-03.png'
                  alt='item-03'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-04.png'
                  alt='item-04'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-05.png'
                  alt='item-05'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-06.png'
                  alt='item-06'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-07.png'
                  alt='item-07'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 red' src='/img/characters/08.png' alt='character-08' />
                  <p class='user-avatar-text red'>14</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-15.png'
                    alt='skill-15'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-16.png'
                    alt='skill-16'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>Arrowhead</p>
                  <p class='team-country'>Top | Solo</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>3</span>
                /
                <span class='highlight'>9</span>
                /
                <span class='highlight'>9</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>11148</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>178</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>2 12 4</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>44</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-29.png'
                  alt='item-29'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-30.png'
                  alt='item-30'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-31.png'
                  alt='item-31'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-32.png'
                  alt='item-32'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-33.png'
                  alt='item-33'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-34.png'
                  alt='item-34'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-35.png'
                  alt='item-35'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 red' src='/img/characters/09.png' alt='character-09' />
                  <p class='user-avatar-text red'>15</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-17.png'
                    alt='skill-17'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-18.png'
                    alt='skill-18'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>TheLightning</p>
                  <p class='team-country'>Mid | Solo</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>8</span>
                /
                <span class='highlight'>7</span>
                /
                <span class='highlight'>6</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>13218</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>207</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>3 8 6</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>51</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-22.png'
                  alt='item-22'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-23.png'
                  alt='item-23'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-24.png'
                  alt='item-24'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-25.png'
                  alt='item-25'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-27.png'
                  alt='item-27'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-28.png'
                  alt='item-28'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-29.png'
                  alt='item-29'
                />
              </div>
            </div>
          </div>
          <div class='table-row big'>
            <div class='table-row-item'>
              <div class='team-info-wrap medium'>
                <div class='user-avatar-wrap'>
                  <img class='user-avatar character v2 red' src='/img/characters/10.png' alt='character-10' />
                  <p class='user-avatar-text red'>13</p>
                </div>
                <div class='skill-loot-list vertical'>
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-19.png'
                    alt='skill-19'
                  />
                  <img
                    class='skill-loot-item skill-loot-img'
                    src='/img/characters/skills/skill-20.png'
                    alt='skill-20'
                  />
                </div>
                <div class='team-info'>
                  <p class='team-name'>PXSlasher</p>
                  <p class='team-country'>Jungle</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'><span class='highlight'>7</span>
                /
                <span class='highlight'>7</span>
                /
                <span class='highlight'>9</span></p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>11189</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>156</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>2 26 7</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bolder'>59</p>
            </div>
            <div class='table-row-item'>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-15.png'
                  alt='item-15'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-16.png'
                  alt='item-16'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-17.png'
                  alt='item-17'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-18.png'
                  alt='item-18'
                />
              </div>
              <div class='skill-loot-list'>
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-19.png'
                  alt='item-19'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-20.png'
                  alt='item-20'
                />
                <img
                  class='skill-loot-item skill-loot-img small'
                  src='/img/characters/loot/item-21.png'
                  alt='item-21'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-row filled mobile'>
        <div class='ban-list-wrap'>
          <p class='ban-list-title'>Team Bans</p>
          <div class='ban-list'>
            <img class='ban-list-item user-avatar micro' src='/img/characters/03.png' alt='character-03' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/02.png' alt='character-02' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/04.png' alt='character-04' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/01.png' alt='character-01' />
            <img class='ban-list-item user-avatar micro' src='/img/characters/05.png' alt='character-05' />
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
