import { gql } from 'glimmer-apollo';

export const ESPORTS_TOURNAMENT_BANNER_QUERY = gql`
  query ($slug: String) {
    tournament: tournamentCollection(where: { slug: $slug }, limit: 1) {
      items {
        title
        slug
        game {
          logo {
            url
          }
          title
          slug
        }
        matches: matchesCollection(limit: 20) {
          items {
            isLive
            isFinal
            scheduledDate
            slug
            rounds: roundsCollection(limit: 5) {
              items {
                isLive
                victor {
                  slug
                }
              }
            }
            victor {
              slug
            }
            teams: teamsCollection(limit: 2) {
              items {
                name
                slug
                logo {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
