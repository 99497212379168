import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import config from 'embroil/config/environment';
import StreamPreview from '../../stream/stream-preview';
import { log } from '../../../helpers/log';
export default class LiveStreamsComponent extends Component {
    @service
    liveStreams;
    static{
        template(`
    {{log config}}
    <div class='widget-sidebar'>
      <div class='section-title-wrap violet'>
        <h2 class='section-title medium'>
          Twitch Streams
        </h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='stream-preview-showcase grid-1col centered gutter-small'>
        {{#each this.liveStreams.streams as |stream|}}
          <StreamPreview @stream={{stream}} />
        {{/each}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
