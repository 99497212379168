import { template } from "@ember/template-compiler";
import vsProgress from '../../../modifiers/vs-progress';
import { LinkTo } from '@ember/routing';
import Component from '@glimmer/component';
import { formatDate } from '../../../helpers/format-date';
export default class EsportsNews extends Component {
    get articles() {
        return this.args.articles ?? [];
    }
    get firstArticle() {
        return this.articles[0] || {};
    }
    get remainingArticles() {
        let otherArgs1 = Array.from(this.articles);
        otherArgs1.shift();
        return otherArgs1;
    }
    static{
        template(`
    <div class='layout-content-2 layout-item-1-2-1 grid-limit'>
      <div class='layout-sidebar1 layout-item gutter-medium'>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Twitch Streams</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='stream-preview-showcase grid-1col centered gutter-small'>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-1'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-01-streamer.png'
                  alt='streambox-01-streamer'
                />
                <p class='stream-preview-streamer-name'>Game-Huntress</p>
                <p class='stream-preview-streamer-topic'>Let’s Play: Magimon Story Mode</p>
                <p class='stream-start-counter live'>Live</p>
              </div>
              <div class='widget-media small'>
                <iframe
                  src='https://player.twitch.tv/?autoplay=true&muted=true&t=03h15m50s&video=v417077870'
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-2'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-02-streamer.png'
                  alt='streambox-02-streamer'
                />
                <p class='stream-preview-streamer-name'>Davikinger90</p>
                <p class='stream-preview-streamer-topic'>I’ll try all Xenowatch’s new skins</p>
                <p class='stream-start-counter'>Starts in:
                  <span id='sidebar-twitch-countdown-1' class='highlighted'></span></p>
              </div>
            </div>
            <div class='stream-preview'>
              <div class='stream-preview-streamer stream-preview-streamer-3'>
                <img
                  class='stream-preview-streamer-img'
                  src='/img/streamers/streambox-03-streamer.png'
                  alt='streambox-03-streamer'
                />
                <p class='stream-preview-streamer-name'>Markus_Snipes</p>
                <p class='stream-preview-streamer-topic'>The best sniper of all county!</p>
                <p class='stream-start-counter offline'>Offline</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>eSports Standings</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='table standings'>
            <div class='table-row-header'>
              <div class='table-row-header-item position'>
                <p class='table-row-header-title'>Position</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Match Record</p>
              </div>
            </div>
            <div class='table-rows'>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>01</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>28 - 14</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>02</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>27 - 13</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>03</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                    <div class='team-info'>
                      <p class='team-name'>Los Toreros</p>
                      <p class='team-country'>Spain</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>26 - 11</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>04</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                    <div class='team-info'>
                      <p class='team-name'>Siberian Tigers</p>
                      <p class='team-country'>Russia</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>20 - 19</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>05</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                    <div class='team-info'>
                      <p class='team-name'>Crimson Pack</p>
                      <p class='team-country'>Japan</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>19 - 21</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Latest Match</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Xenowatch league finals</h6>
              <p class='widget-text'>August 28th, 2018</p>
            </div>
            <div class='widget-team-vs'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='team-score'>12</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>Raging Rhinos</p>
                    <p class='team-country'>South Africa</p>
                  </div>
                </div>
                <p class='team-score'>10</p>
              </div>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Match Stats</h6>
              <div class='stats-reference'>
                <div class='stat-reference blue'>
                  <p class='stat-reference-text'>TLW</p>
                </div>
                <div class='stat-reference yellow'>
                  <p class='stat-reference-text'>RRH</p>
                </div>
              </div>
            </div>
            <div class='widget-team-stats bottom-spaced'>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Kills</p>
                <div id='sd-pg-1' class='progress-bar-wrap' {{vsProgress 34 28}}>
                  <div class='bar-progress-wrap left'>
                    <p id='sd-pg-1-left-stat' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='sd-pg-1-right-stat' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Deaths</p>
                <div id='sd-pg-2' class='progress-bar-wrap' {{vsProgress 15 21}}>
                  <div class='bar-progress-wrap left'>
                    <p id='sd-pg-2-left-stat' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='sd-pg-2-right-stat' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Assists</p>
                <div id='sd-pg-3' class='progress-bar-wrap' {{vsProgress 26 13}}>
                  <div class='bar-progress-wrap left'>
                    <p id='sd-pg-3-left-stat' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='sd-pg-3-right-stat' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap red'>
            <h2 class='section-title medium'>Instagram Widget</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='photo-list'></div>
        </div>
      </div>
      <div class='layout-body layout-item'>
        <div class='widget-item'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Latest News</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview big e-sport'>
            <LinkTo @route='esports.post' @model={{this.firstArticle.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{this.firstArticle.coverImage.url}}' alt='{{this.firstArticle.title}}' />
                </figure>
              </div>
            </LinkTo>
            <LinkTo @route='esports' class='tag-ornament'>eSports</LinkTo>
            <LinkTo @route='esports.post' @model={{this.firstArticle.slug}} class='post-preview-title'>
              {{this.firstArticle.title}}
            </LinkTo>
            <div class='post-author-info-wrap'>
              <a href='/search'>
                <figure class='user-avatar tiny liquid'>
                  <img
                    src={{this.firstArticle.author.profilePicture.url}}
                    alt='{{this.firstArticle.author.displayName}}'
                  />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>{{this.firstArticle.author.displayName}}</a>
                <span class='separator'>|</span>{{formatDate this.firstArticle.publishedDate}}
              </p>
            </div>
            <p class='post-preview-text'>{{this.firstArticle.shortDescription}}</p>
          </div>
        </div>
        <div class='post-preview-showcase border-top grid2-1col centered'>
          {{#each this.remainingArticles as |article|}}
            <div class='post-preview landscape e-sport'>
              <LinkTo @route='esports.post' @model={{article.slug}}>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='{{article.coverImage.url}}' alt='{{article.title}}' />
                  </figure>
                </div>
              </LinkTo>
              <LinkTo @route='esports.index' class='tag-ornament'>Esports</LinkTo>
              <LinkTo @route='esports.post' @model={{article.slug}} class='post-preview-title'>
                {{article.title}}
              </LinkTo>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>
                    {{article.author.displayName}}
                  </a>
                  <span class='separator'>|</span>
                  {{formatDate article.publishedDate}}</p>
              </div>
              <p class='post-preview-text'>{{article.shortDescription}}</p>
            </div>
          {{/each}}
        </div>
        <!--<div class='layout-item centered padded load-more-wrap'>
          <a href='esports-news.html' class='button violet'>
            Read more news...
            <div class='button-ornament'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
              <svg class='cross-icon small'>
                <use href='#svg-cross-small'></use>
              </svg>
            </div>
          </a>
        </div>-->
      </div>
      <div class='layout-sidebar2 layout-item gutter-medium'>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Popular Posts</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-1col gutter-small'>
            <div class='post-preview tiny e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/36.jpg' alt='post-36' />
                  </figure>
                </div>
              </a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>Gwen Parker is leading her team to
                the semifinals</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/38.jpg' alt='post-38' />
                  </figure>
                </div>
              </a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>Pro Soccer 2018 world league kicked
                off today!</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/37.jpg' alt='post-37' />
                  </figure>
                </div>
              </a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>Last night the wolves beat the rhinos
                12-10</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview tiny e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/39.jpg' alt='post-39' />
                  </figure>
                </div>
              </a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>We had an interview with the great
                Emily Danniels</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Top Players</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-top-player'>
            <img class='top-player-team-img' src='/img/teams/logos/01.png' alt='team-01' />
            <img class='top-player-img' src='/img/teams/top-player.png' alt='top-player' />
            <div class='top-player-info-wrap'>
              <div class='top-player-info'>
                <p class='top-player-flag featured'>Featured</p>
                <p class='top-player-name'>Davikinger90</p>
                <p class='top-player-team'>The Lone Wolves - USA</p>
              </div>
              <div class='arc-rate-wrap tiny'>
                <div id='ew1-tp-rate-01' class='arc rate small negative'></div>
                <p class='arc-rate-info'>w%</p>
              </div>
            </div>
            <div class='top-player-stats'>
              <div class='top-player-stat'>
                <p class='top-player-stat-title'>74</p>
                <p class='top-player-stat-text'>played</p>
              </div>
              <div class='top-player-stat'>
                <p class='top-player-stat-title'>56</p>
                <p class='top-player-stat-text'>t.kills</p>
              </div>
              <div class='top-player-stat'>
                <p class='top-player-stat-title'>32</p>
                <p class='top-player-stat-text'>t.dth</p>
              </div>
              <div class='top-player-stat'>
                <p class='top-player-stat-title'>9.2</p>
                <p class='top-player-stat-text'>kda.r</p>
              </div>
            </div>
          </div>
          <div class='table top-players'>
            <div class='table-row-header'>
              <div class='table-row-header-item left'>
                <p class='table-row-header-title'>Name &amp; Team</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>KDA.R</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>W%</p>
              </div>
            </div>
            <div class='table-rows no-color'>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='user-avatar tiny' src='/img/users/10.jpg' alt='user-10' />
                    <div class='team-info'>
                      <p class='team-name'>Joystickz-70</p>
                      <p class='team-country'>Crimson Pack - JPN</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>8.7</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>86</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='user-avatar tiny' src='/img/users/12.jpg' alt='user-12' />
                    <div class='team-info'>
                      <p class='team-name'>Dr.Chaos</p>
                      <p class='team-country'>Raging Rhinos - SOA</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>7.6</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>82</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='user-avatar tiny' src='/img/users/15.jpg' alt='user-15' />
                    <div class='team-info'>
                      <p class='team-name'>Arrowhead</p>
                      <p class='team-country'>Crimson Pack - JPN</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6.4</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>73</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='user-avatar tiny' src='/img/users/18.jpg' alt='user-18' />
                    <div class='team-info'>
                      <p class='team-name'>Keymaster</p>
                      <p class='team-country'>Los Toreros - SPA</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>5.5</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>70</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Upcoming Matches</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Xenowatch League 2017</h6>
              <p class='widget-text'>04/06/2018</p>
            </div>
            <div class='widget-team-vs small v2 filled'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap'>
                  <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>TLW</p>
                    <p class='team-country'>USA</p>
                  </div>
                </div>
              </div>
              <div class='match-stats'>
                <p class='match-score smaller'>vs</p>
                <p class='match-time'>8:00PM CPT</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap inverted'>
                  <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                  <div class='team-info'>
                    <p class='team-name'>LTR</p>
                    <p class='team-country'>SPA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Xenowatch Tournament 2017</h6>
              <p class='widget-text'>04/10/2018</p>
            </div>
            <div class='widget-team-vs small v2 filled'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap'>
                  <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                  <div class='team-info'>
                    <p class='team-name'>CPK</p>
                    <p class='team-country'>JAP</p>
                  </div>
                </div>
              </div>
              <div class='match-stats'>
                <p class='match-score smaller'>vs</p>
                <p class='match-time'>10:00PM CPT</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap inverted'>
                  <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>RRH</p>
                    <p class='team-country'>SOA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>SC GO League (Week 4 Day 2)</h6>
              <p class='widget-text'>04/12/2018</p>
            </div>
            <div class='widget-team-vs small v2 filled'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap'>
                  <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                  <div class='team-info'>
                    <p class='team-name'>SBT</p>
                    <p class='team-country'>RUS</p>
                  </div>
                </div>
              </div>
              <div class='match-stats'>
                <p class='match-score smaller'>vs</p>
                <p class='match-time'>9:30PM CPT</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap inverted'>
                  <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>TLW</p>
                    <p class='team-country'>USA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Live Match</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>SC GO League (Week 4 day 3)</h6>
              <p class='widget-text bold text-tag live'>25:46</p>
            </div>
            <div class='widget-team-vs small'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap'>
                  <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='team-score'>2</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap'>
                  <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>Raging Rhinos</p>
                    <p class='team-country'>South Africa</p>
                  </div>
                </div>
                <p class='team-score'>0</p>
              </div>
            </div>
          </div>
          <div class='widget-media small'>
            <iframe
              src='https://player.twitch.tv/?autoplay=true&muted=true&t=01h03m30s&video=v417567785'
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
