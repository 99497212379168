import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-item'>
    <div class='section-title-wrap violet small-space'>
      <h2 class='section-title medium'>Tournament Standings</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='table standings full'>
      <div class='table-row-header'>
        <div class='table-row-header-item position'>
          <p class='table-row-header-title'>Position</p>
        </div>
        <div class='table-row-header-item padded'></div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Wins</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Losses</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Kills</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Deaths</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Assists</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>KDA.R</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Record</p>
        </div>
      </div>
      <div class='table-rows'>
        {{#each @tournament.teams as |team index|}}
          <div class='table-row'>
            <div class='table-row-item position'>
              <p class='table-text'>{{index}}</p>
              <div class='team-info-wrap'>
                <img class='team-logo small' src={{team.logo.url}} alt={{team.name}} />
                <div class='team-info'>
                  <p class='team-name'>{{team.name}}</p>
                  <p class='team-country'>{{team.country}}</p>
                </div>
              </div>
            </div>
            <div class='table-row-item'>
              <!--<img class='country-flag' src='/img/flags/flag-us.png' alt='flag-us' />-->
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.wins}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.losses}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.kills}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.deaths}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.assists}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>{{team.kda}}</p>
            </div>
            <div class='table-row-item'>
              <p class='table-text bold'>
                <span class='highlight'>{{team.wins}}</span>
                /
                <span class='highlight'>{{team.losses}}</span>
              </p>
            </div>
          </div>
        {{/each}}
      </div>
    </div>
    <div class='table standings mobile'>
      <div class='table-row-header'>
        <div class='table-row-header-item position'>
          <p class='table-row-header-title'>Position</p>
        </div>
        <div class='table-row-header-item padded'></div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>W/L</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>K/D/A</p>
        </div>
        <div class='table-row-header-item padded'>
          <p class='table-row-header-title'>Record</p>
        </div>
      </div>
      <div class='table-rows'>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>01</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
              <div class='team-info'>
                <p class='team-name'>The Lone Wolves</p>
                <p class='team-country'>United States</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-us.png' alt='flag-us' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>24 / 6</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>620 / 341 / 1243</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>36</span>
              /
              <span class='highlight'>27</span>
              /
              <span class='highlight'>70</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$125.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>02</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
              <div class='team-info'>
                <p class='team-name'>Raging Rhinos</p>
                <p class='team-country'>South Africa</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-za.png' alt='flag-za' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>22 / 8</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>590 / 368 / 1164</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>31</span>
              /
              <span class='highlight'>25</span>
              /
              <span class='highlight'>80</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$50.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>03</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
              <div class='team-info'>
                <p class='team-name'>Los Toreros</p>
                <p class='team-country'>Spain</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-es.png' alt='flag-es' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>21 / 9</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>543 / 389 / 1043</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>39</span>
              /
              <span class='highlight'>24</span>
              /
              <span class='highlight'>89</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$25.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>04</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
              <div class='team-info'>
                <p class='team-name'>Siberian Tigers</p>
                <p class='team-country'>Russia</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-ru.png' alt='flag-ru' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>19 / 11</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>501 / 402 / 996</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>28</span>
              /
              <span class='highlight'>30</span>
              /
              <span class='highlight'>66</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$25.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>05</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
              <div class='team-info'>
                <p class='team-name'>Crimson Pack</p>
                <p class='team-country'>Japan</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-jp.png' alt='flag-jp' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>18 / 12</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>433 / 435 / 947</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>30</span>
              /
              <span class='highlight'>22</span>
              /
              <span class='highlight'>67</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$10.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>06</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/06.png' alt='logo-06' />
              <div class='team-info'>
                <p class='team-name'>Toxic Bulls</p>
                <p class='team-country'>Brazil</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-br.png' alt='flag-br' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>22 / 8</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>590 / 368 / 1164</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>31</span>
              /
              <span class='highlight'>25</span>
              /
              <span class='highlight'>80</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$50.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>07</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/08.png' alt='logo-08' />
              <div class='team-info'>
                <p class='team-name'>Maple Wolves</p>
                <p class='team-country'>Canada</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-ca.png' alt='flag-ca' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>21 / 9</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>543 / 389 / 1043</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>39</span>
              /
              <span class='highlight'>24</span>
              /
              <span class='highlight'>89</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$25.000</p>
          </div>
        </div>
        <div class='table-row'>
          <div class='table-row-item position'>
            <p class='table-text'>08</p>
            <div class='team-info-wrap'>
              <img class='team-logo small' src='/img/teams/logos/07.png' alt='logo-07' />
              <div class='team-info'>
                <p class='team-name'>Bengal Tigers</p>
                <p class='team-country'>India</p>
              </div>
            </div>
          </div>
          <div class='table-row-item'>
            <img class='country-flag' src='/img/flags/flag-in.png' alt='flag-in' />
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>19 / 11</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>501 / 402 / 996</p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'><span class='highlight'>28</span>
              /
              <span class='highlight'>30</span>
              /
              <span class='highlight'>66</span></p>
          </div>
          <div class='table-row-item'>
            <p class='table-text bold'>\$25.000</p>
          </div>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
