import { gql } from 'glimmer-apollo';

export const ESPORTS_MATCH_QUERY = gql`
  query ($slug: String) {
    match: matchCollection(where: { slug: $slug }) {
      items {
        title
        slug
        isLive
        isFinal
        bestOf
        scheduledDate
        tournament {
          isLive
          liveChatUrl
          liveVideoUrl
        }
        victor {
          name
          slug
          logo {
            url
          }
        }
        game {
          title
          slug
        }
        rounds: roundsCollection(limit: 5) {
          items {
            roundNumber
            isLive
            embedUrl
            victor {
              slug
            }
          }
        }
        teams: teamsCollection(limit: 2) {
          items {
            name
            slug
            players: playersCollection(limit: 5) {
              items {
                gamertag
                role
                slug
                photo {
                  url
                }
              }
            }
            logo {
              url
            }
          }
        }
      }
    }
  }
`;
