import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../../components/layout/banner/main';
import LiveNewsTicker from '../../../components/layout/live-news-ticker';
import MatchFinalResults from '../../../components/esports/match/match-final-result';
import MatchDetails from '../../../components/esports/match/details';
import RoundTabs from '../../../components/esports/match/round-tabs';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { formatDate } from '../../../helpers/format-date';
import EsportsMatchResultsBanner from '../../../components/layout/banner/esports-match-results';
import { pageTitle } from 'ember-page-title';
import { service } from '@ember/service';
let EsportsMatchComponent = class EsportsMatchComponent extends Component {
    @service
    metrics;
    @tracked
    selectedRound = 0;
    get totalRounds() {
        return this.args.model.rounds.items.length;
    }
    get watchTense() {
        if (this.args.model.isLive) {
            return `Watch Live Now`;
        }
        if (this.args.model.isFinal) {
            return 'Watch Replay';
        }
        // TODO: add prompt to add reminder notification for this match, which prompts you to create an account if you arent logged in
        return this.args.model.scheduledDate ? `Watch It Live Here @ ${formatDate(this.args.model.scheduledDate, 'MMMM DD, YYYY - h:mm a')}` : 'Watch It Live Here';
    }
    get teamA() {
        return this.args.model?.teams?.items[0];
    }
    get teamB() {
        return this.args.model?.teams?.items[1];
    }
    get showChat() {
        return this.args.model.isLive && this.args.model?.tournament?.liveChatUrl;
    }
    get currentVideo() {
        if (this.args.model.isLive) {
            return this.args.model.tournament.liveVideoUrl;
        } else {
            return this.args.model.rounds.items[this.selectedRound].embedUrl;
        }
    }
    @action
    selectRound(round1) {
        this.metrics.track('esports.match.selectRound', {
            match: this.args.model.slug,
            round: `${round1}`
        });
        this.selectedRound = round1;
    }
    static{
        template(`
    {{pageTitle @model.title}}
    <BannerComponent @theme='e-sport' @title={{@model.title}} />
    <LiveNewsTicker />
    <EsportsMatchResultsBanner />
    <div class='layout-content-full v2 grid-limit layout-item{{if this.showChat "-3-1"}} gutter-big'>
      <div class='widget-item'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>{{this.watchTense}}</h2>
          <div class='section-title-separator'></div>
        </div>
        {{#if this.args.model.isFinal}}
          <RoundTabs
            @totalRounds={{this.totalRounds}}
            @currentRound={{this.selectedRound}}
            @selectRound={{this.selectRound}}
            @rounds={{@model.rounds.items}}
          />
          <br />
        {{else}}
          <div class='widget-media-wrap huge'>
            <div class='widget-media-r'>
              <iframe src={{this.currentVideo}} width='500' allowfullscreen></iframe>
            </div>
          </div>
        {{/if}}
      </div>
      {{#if this.showChat}}
        <iframe id='twitch-chat-embed' src={{@model.tournament.liveChatUrl}} height='650' width='415'>
        </iframe>
      {{/if}}
    </div>
    <!-- TODO: Combine these into one component -->
    <div class='grid-limit layout-item'>
      <MatchFinalResults @match={{@model}} @teamA={{this.teamA}} @teamB={{this.teamB}} />
    </div>
    <MatchDetails @match={{@model}} @teamA={{this.teamA}} @teamB={{this.teamB}} />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsMatchComponent);
