import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_PLAYER_QUERY } from '../../gql/queries/esports-player';
export default class Tournament extends Route {
    async model({ player_slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_PLAYER_QUERY,
                {
                    variables: {
                        slug: player_slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod.player.items[0];
    }
}
