import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
let GgtvVideo = class GgtvVideo extends Component {
    static{
        template(`
    {{! <LiveNewsTicker /> }}
    <div class='section-bg'>
      <div class='layout-content-1 layout-item-3-1 padded-large grid-limit'>
        <div class='layout-body'>
          <div class='post-open game-review negative'>
            <div class='post-open-content v5'>
              <div class='post-open-body'>
                <div class='post-open-media-wrap'>
                  <div class='post-open-media'>
                    <iframe
                      src='https://www.youtube.com/embed/IoBOFTi-zAk'
                      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div class='tag-list'>
                  <a href='/ggtv' class='tag-ornament video'>Video</a>
                  <a href='/reviews' class='tag-ornament'>Game Reviews</a>
                </div>
                <p class='post-open-title small'>We reviewed the new Magimons Game - Video Review</p>
                <div class='post-author-info-wrap'>
                  <a href='/search'>
                    <figure class='user-avatar tiny liquid'>
                      <img src='/img/users/04.jpg' alt='user-04' />
                    </figure>
                  </a>
                  <p class='post-author-info small light'>By
                    <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th,
                    2018<span class='separator'>|</span><a
                      href='/reviews/review-slug#op-comments'
                      class='post-comment-count'
                    >130 Comments</a></p>
                </div>
                <p class='post-open-text'><span class='video-length'>03:22</span>In this video review you’ll be able to
                  see what works and what doesn’t in the new game. Lorem ipsum dolor sit amet, consectetur...</p>
                <div class='social-links'>
                  <a href='#' class='bubble-ornament fb'>
                    <svg class='facebook-icon'>
                      <use href='#svg-facebook'></use>
                    </svg>
                  </a>
                  <a href='#' class='bubble-ornament twt'>
                    <svg class='twitter-icon'>
                      <use href='#svg-twitter'></use>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='layout-sidebar'>
          <div class='post-preview-showcase grid-1col centered gutter-small'>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/25.jpg' alt='post-25' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>08:04</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Gameplay trailer for the new Legend of Kenji game</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video e-sport tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/38.jpg' alt='post-38' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>07:57</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Watch the best match of the Pro Soccer 2017 World
                Cup</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/31.jpg' alt='post-31' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>04:37</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Galaxy Adventure RX peek at the new weapons</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/07.jpg' alt='post-07' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>09:24</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>"Rise of Depredators" launched it's cinematic
                trailer</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video e-sport tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/41.jpg' alt='post-41' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>08:04</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Watch crowd highlights of the Xenowatch semifinals</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Dexter</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video geeky-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/02.jpg' alt='post-02' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>09:24</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>"Charlotte" released a new teaser for the new series</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video movie-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/12.jpg' alt='post-12' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>05:56</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Check out the new "Guardians of the Universe"
                trailer</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/01.jpg' alt='post-01' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>04:37</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>Take a look at The Clash of Dragons new classes</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
            <div class='post-preview video gaming-news tiny no-hover negative'>
              <a href='/ggtv/video-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/09.jpg' alt='post-09' />
                  </figure>
                  <div class='post-preview-overlay'>
                    <div class='play-button tiny'>
                      <svg class='play-button-icon tiny'>
                        <use href='#svg-play'></use>
                      </svg>
                    </div>
                    <p class='timestamp-tag tiny'>05:56</p>
                  </div>
                </div>
              </a>
              <a href='/ggtv/video-slug' class='post-preview-title'>5 minutes of the new "Rizen" game story mode</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>Dec 15th, 2018</p>
              </div>
            </div>
          </div>
          <a href='browse-videos.html' class='button small full cyan'>Browse more videos...</a>
        </div>
      </div>
    </div>
    <div class='layout-content-1 layout-item-1 grid-limit'>
      <div class='layout-body'>
        <div class='layout-item gutter-big'>
          <div id='op-comments' class='post-comment-thread'>
            <div class='section-title-wrap cyan'>
              <h2 class='section-title medium'>Comments (174)</h2>
              <div class='section-title-separator'></div>
            </div>
            <div class='post-comment'>
              <figure class='user-avatar medium liquid'>
                <img src='/img/users/09.jpg' alt='user-09' />
              </figure>
              <p class='post-comment-username'>Elizabeth Valentine</p>
              <p class='post-comment-timestamp'>25 mins ago</p>
              <a href='#' class='report-button'>Report</a>
              <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
              <div class='post-comment-actions'>
                <div class='reply-button bubble-ornament blue'>
                  <i class='icon-action-undo reply-icon'></i>
                </div>
                <div class='like-button bubble-ornament hoverable cyan'>
                  <i class='icon-like like-icon'></i>
                </div>
                <p class='likes-count'>12 Thumbs Up</p>
              </div>
            </div>
            <div class='post-comment'>
              <figure class='user-avatar medium liquid'>
                <img src='/img/users/07.jpg' alt='user-07' />
              </figure>
              <p class='post-comment-username'>Thomas Stevens</p>
              <p class='post-comment-timestamp'>49 mins ago</p>
              <a href='#' class='report-button'>Report</a>
              <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
              <div class='post-comment-actions'>
                <div class='reply-button bubble-ornament blue'>
                  <i class='icon-action-undo reply-icon'></i>
                </div>
                <div class='like-button bubble-ornament hoverable cyan'>
                  <i class='icon-like like-icon'></i>
                </div>
                <p class='likes-count'>36 Thumbs Up</p>
              </div>
              <div class='post-comment'>
                <figure class='user-avatar medium liquid'>
                  <img src='/img/users/06.jpg' alt='user-06' />
                </figure>
                <p class='post-comment-username'>Megan Lebeau</p>
                <p class='post-comment-timestamp'>6 mins ago</p>
                <a href='#' class='report-button'>Report</a>
                <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                  ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                  voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                <div class='post-comment-actions'>
                  <div class='reply-button bubble-ornament blue'>
                    <i class='icon-action-undo reply-icon'></i>
                  </div>
                  <div class='like-button bubble-ornament hoverable cyan'>
                    <i class='icon-like like-icon'></i>
                  </div>
                  <p class='likes-count'>6 Thumbs Up</p>
                </div>
              </div>
            </div>
            <div class='post-comment'>
              <figure class='user-avatar medium liquid'>
                <img src='/img/users/01.jpg' alt='user-01' />
              </figure>
              <p class='post-comment-username text-tag staff'>Dexter Danvers</p>
              <p class='post-comment-timestamp'>2 hours ago</p>
              <a href='#' class='report-button'>Report</a>
              <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
              <div class='post-comment-actions'>
                <div class='reply-button bubble-ornament blue'>
                  <i class='icon-action-undo reply-icon'></i>
                </div>
                <div class='like-button bubble-ornament hoverable cyan'>
                  <i class='icon-like like-icon'></i>
                </div>
                <p class='likes-count'>47 Thumbs Up</p>
              </div>
            </div>
          </div>
          <div class='page-navigation cyan right'>
            <div class='slider-control big control-previous'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
            <a href='#' class='page-navigation-item'>1</a>
            <a href='#' class='page-navigation-item active'>2</a>
            <a href='#' class='page-navigation-item'>3</a>
            <a href='#' class='page-navigation-item'>...</a>
            <a href='#' class='page-navigation-item'>8</a>
            <div class='slider-control big control-next'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
            </div>
          </div>
          <div class='post-comment-form-wrap'>
            <div class='section-title-wrap cyan'>
              <h2 class='section-title medium'>Write a comment</h2>
              <div class='section-title-separator'></div>
            </div>
            <form class='post-comment-form'>
              <div class='form-row'>
                <div class='form-item half blue'>
                  <label for='pcf_name' class='rl-label'>Your Name</label>
                  <input type='text' id='pcf_name' name='pcf_name' placeholder='Enter your first name here...' />
                </div>
                <div class='form-item half blue'>
                  <label for='pcf_email' class='rl-label'>Your Email</label>
                  <input type='text' id='pcf_email' name='pcf_email' placeholder='Enter your email here...' />
                </div>
              </div>
              <div class='form-row'>
                <div class='form-item blue'>
                  <label for='pcf_comment' class='rl-label'>Your Comment</label>
                  <textarea
                    name='pcf_comment'
                    id='pcf_comment'
                    class='violet'
                    placeholder='Write you comment here...'
                  ></textarea>
                </div>
              </div>
              <div class='submit-button-wrap right'>
                <button class='submit-button button cyan'>
                  Post your comment
                  <span class='button-ornament'>
                    <svg class='arrow-icon medium'>
                      <use href='#svg-arrow-medium'></use>
                    </svg>
                    <svg class='cross-icon small'>
                      <use href='#svg-cross-small'></use>
                    </svg>
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(GgtvVideo);
