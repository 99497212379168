import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { ESPORTS_MATCH_QUERY } from '../../../gql/queries/esports-match';
export default class Post extends Route {
    get match() {
        return this.model();
    }
    async model({ match_slug }) {
        this.query = useQuery(this, ()=>[
                ESPORTS_MATCH_QUERY,
                {
                    variables: {
                        slug: match_slug
                    }
                }
            ]);
        await this.query.promise;
        const mod = this.query.data;
        return mod.match?.items[0];
    }
}
