import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { LinkTo } from '@ember/routing';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import arc from '../../modifiers/arc';
import progress from '../../modifiers/progress';
let NewsPostComponent = class NewsPostComponent extends Component {
    @tracked
    searchTerm;
    static{
        template(`
    <LiveNewsTicker />
    <div class='layout-item gutter-big padded-bottom'>
      <div class='post-open game-review'>
        <div class='post-open-header-wrap'>
          <div class='post-open-header grid-limit'>
            <div class='review-rating'>
              <div id='post-open-rate1' class='arc medium' {{arc 8.7 'lg'}}></div>
            </div>
            <LinkTo @route='reviews' class='tag-ornament'>Game reviews</LinkTo>
            <p class='post-open-title big negative'>We reviewed the new Magimons game</p>
            <div class='post-author-info-wrap negative'>
              <a href='/search'>
                <figure class='user-avatar tiny liquid'>
                  <img src='/img/users/04.jpg' alt='user-04' />
                </figure>
              </a>
              <p class='post-author-info small light'>By
                <a href='/search' class='post-author'>Vellatrix</a>
                <span class='separator'>|</span>December 15th, 2018
                <span class='separator'>|</span>
                <a href='#op-comments' class='post-comment-count'>97 Comments</a>
              </p>
            </div>
            <div class='social-links centered'>
              <a href='#' class='bubble-ornament fb'>
                <svg class='facebook-icon'>
                  <use xlink:href='#svg-facebook'></use>
                </svg>
              </a>
              <a href='#' class='bubble-ornament twt'>
                <svg class='twitter-icon'>
                  <use xlink:href='#svg-twitter'></use>
                </svg>
              </a>
              <a href='#' class='bubble-ornament gplus'>
                <svg class='gplus-icon'>
                  <use xlink:href='#svg-gplus'></use>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class='post-open-content v2 grid-limit'>
          <div class='post-open-body'>
            <p class='post-open-text bold'>Magimons is and incredible take on classic RPGs with a new and fresh approach
              that includes a mindblowing soundtrack.</p>
            <p class='post-open-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
              qui officia deserunt mollit anim id est laborum.</p>
            <p class='post-open-text'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
              doloremque laudantium, totam rem riam, eaque ipsa quae ab illo inventore veritatis et quasi architecto
              beatae vitae dicta sunt explicabo. Nemo enim ipsam e quia voluptas sit aspernatur aut odit aut fugit, sed
              quia consequuntur magni dolores eos qui ratitate sequi nesciunt. Neque porro quisquam est, qui dolorem
              ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
              labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
              ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure
              reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum
              fugiat quo voluptas ullamco laboris nisi ut aliquip ex ea commodonulla pariatur?</p>
            <div class='post-open-media-wrap'>
              <div class='post-open-media'>
                <iframe src='https://giphy.com/embed/2ywJQKBSPbX39cAllH' allowFullScreen></iframe>
              </div>
              <p class='post-open-media-info'>In this boss fight GIF you can take a peek of the amazing combat elements
                and magimon special attacks</p>
            </div>
            <p class='post-open-text'>Nemo enim ipsam e quia voluptas sit aspernatur aut odit aut fugit, sed quia
              consequuntur magni dolores eos qui ratitate sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
              quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut
              labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem
              ullam corporis suscipit laboriosam.</p>
            <p class='post-open-subtitle small'>Ordered list</p>
            <p class='post-open-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore na aliqua. enim ad minim veniam, quis nostrud exercitation.</p>
            <div class='bullet-list'>
              <div class='bullet-list-item'>
                <div class='bullet-list-item-icon'>
                  <div class='bubble-ornament small red no-link'>
                    <p class='bubble-ornament-info'>01</p>
                  </div>
                </div>
                <p class='bullet-list-item-text'>Dolorem ipsum quia dolor sit ametoru</p>
              </div>
              <div class='bullet-list-item'>
                <div class='bullet-list-item-icon'>
                  <div class='bubble-ornament small red no-link'>
                    <p class='bubble-ornament-info'>02</p>
                  </div>
                </div>
                <p class='bullet-list-item-text'>Nostrud exercitation ullamco laboris nisi ut aliquip</p>
              </div>
              <div class='bullet-list-item'>
                <div class='bullet-list-item-icon'>
                  <div class='bubble-ornament small red no-link'>
                    <p class='bubble-ornament-info'>03</p>
                  </div>
                </div>
                <p class='bullet-list-item-text'>Dolorem ipsum quia dolor sit</p>
              </div>
              <div class='bullet-list-item'>
                <div class='bullet-list-item-icon'>
                  <div class='bubble-ornament small red no-link'>
                    <p class='bubble-ornament-info'>04</p>
                  </div>
                </div>
                <p class='bullet-list-item-text'>Nostrud exercitation ullamco laboris nisi ut</p>
              </div>
            </div>
            <p class='post-open-text'>Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur,
              adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat
              voluptatem. Nemo enim ipsam e quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni
              dolores eos qui ratitate sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
              consectetur, adipisci velit.</p>
            <p class='post-open-subtitle'>Final Review</p>
            <p class='post-open-text'>Magimons is a great game with an incredible story and compelling characters. The
              graphics are really great, but the online is clunky and lacks excitement.</p>
            <div class='review-block'>
              <div class='review-block-item'>
                <div id='po-pbg-5' class='progress-bar' {{progress 7.5 barText='GAMING GRAPHICS'}}>
                  <div class='bar-progress-wrap bubble tiny static'>
                    <p class='bar-progress-text'></p>
                  </div>
                </div>
                <div id='po-pbg-6' class='progress-bar' {{progress 9.2 barText='OVERALL STORY'}}>
                  <div class='bar-progress-wrap bubble tiny static'>
                    <p class='bar-progress-text'></p>
                  </div>
                </div>
                <div id='po-pbg-7' class='progress-bar' {{progress 5.8 barText='ORIGINALITY'}}>
                  <div class='bar-progress-wrap bubble tiny static'>
                    <p class='bar-progress-text'></p>
                  </div>
                </div>
                <div id='po-pbg-8' class='progress-bar' {{progress 3.4 barText='MULTIPLAYER & ONLINE'}}>
                  <div class='bar-progress-wrap bubble tiny static'>
                    <p class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='review-block-item'>
                <div id='po-arc-1' class='arc big negative' {{arc 8.7 'xl' shadow=false}}></div>
              </div>
            </div>
            <div class='feature-list'>
              <div class='feature-list-block'>
                <p class='feature-list-item'><span class='feature-title'>Game:</span><span class='feature-info'>Magimons
                    Rising</span></p>
                <p class='feature-list-item'><span class='feature-title'>Developer:</span><span
                    class='feature-info'
                  >Broken Pixels</span></p>
                <p class='feature-list-item'><span class='feature-title'>Release Date:</span><span
                    class='feature-info'
                  >October 15th, 2018</span></p>
              </div>
              <div class='feature-list-block'>
                <p class='feature-list-item'><span class='feature-title'>Publisher:</span><span
                    class='feature-info'
                  >Diamond Canada</span></p>
                <p class='feature-list-item'><span class='feature-title'>Reviewed on:</span><span
                    class='feature-info'
                  >Powerstation 4</span></p>
                <p class='feature-list-item'><span class='feature-title'>MSRP:</span><span class='feature-info'>USD
                    59.99</span></p>
              </div>
            </div>
            <div class='post-open-tags'>
              <div class='tag-list'>
                <a href='/search' class='tag-item'>Gaming</a>
                <a href='/search' class='tag-item'>Video Reviews</a>
                <a href='/search' class='tag-item'>Previews</a>
                <a href='/search' class='tag-item'>Movie Reviews</a>
                <a href='/search' class='tag-item'>Movie News</a>
                <a href='/search' class='tag-item'>Critic</a>
                <a href='/search' class='tag-item'>Ratings</a>
                <a href='/search' class='tag-item'>Funtendo</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='grid3-1col centered gutter-big grid-limit'>
        <div class='widget-news'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Related News</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-3col centered'>
            <div class='post-preview gaming-news'>
              <a href='/news/game-article-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/09.jpg' alt='post-09' />
                  </figure>
                </div>
              </a>
              <a href='/news' class='tag-ornament'>Gaming news</a>
              <a href='/news/game-article-slug' class='post-preview-title'>New "Rizen" game is gonna be released in
                summer 2019</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
            <div class='post-preview geeky-news'>
              <a href='post-v4.html'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/02.jpg' alt='post-02' />
                  </figure>
                </div>
              </a>
              <a href='news-v4.html' class='tag-ornament'>Geeky news</a>
              <a href='post-v4.html' class='post-preview-title'>Jessica Tam to star in the new "Charlotte" series</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Vellatrix</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
            <div class='post-preview movie-news'>
              <a href='post-v3.html'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/12.jpg' alt='post-12' />
                  </figure>
                  <div class='rating-ornament'>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon empty'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                    <div class='rating-ornament-item'>
                      <svg class='rating-ornament-icon empty'>
                        <use xlink:href='#svg-star'></use>
                      </svg>
                    </div>
                  </div>
                </div>
              </a>
              <a href='news-v3.html' class='tag-ornament'>Movie news</a>
              <a href='post-v3.html' class='post-preview-title'>We reviewed the "Guardians of the Universe" movie</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
          </div>
        </div>
        <div class='post-comment-form-wrap'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Write a comment</h2>
            <div class='section-title-separator'></div>
          </div>
          <form class='post-comment-form'>
            <div class='form-row'>
              <div class='form-item half blue'>
                <label for='pcf_name' class='rl-label'>Your Name</label>
                <input type='text' id='pcf_name' name='pcf_name' placeholder='Enter your first name here...' />
              </div>
              <div class='form-item half blue'>
                <label for='pcf_email' class='rl-label'>Your Email</label>
                <input type='text' id='pcf_email' name='pcf_email' placeholder='Enter your email here...' />
              </div>
            </div>
            <div class='form-row'>
              <div class='form-item blue'>
                <label for='pcf_comment' class='rl-label'>Your Comment</label>
                <textarea
                  name='pcf_comment'
                  id='pcf_comment'
                  class='violet'
                  placeholder='Write you comment here...'
                ></textarea>
              </div>
            </div>
            <div class='submit-button-wrap right'>
              <button class='submit-button button blue'>
                Post your comment
                <span class='button-ornament'>
                  <svg class='arrow-icon medium'>
                    <use xlink:href='#svg-arrow-medium'></use>
                  </svg>
                  <svg class='cross-icon small'>
                    <use xlink:href='#svg-cross-small'></use>
                  </svg>
                </span>
              </button>
            </div>
          </form>
        </div>
        <div id='op-comments' class='post-comment-thread'>
          <div class='section-title-wrap blue'>
            <h2 class='section-title medium'>Comments (174)</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-comment'>
            <figure class='user-avatar medium liquid'>
              <img src='/img/users/09.jpg' alt='user-09' />
            </figure>
            <p class='post-comment-username'>Elizabeth Valentine</p>
            <p class='post-comment-timestamp'>25 mins ago</p>
            <a href='#' class='report-button'>Report</a>
            <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur.</p>
            <div class='post-comment-actions'>
              <div class='reply-button bubble-ornament blue'>
                <i class='icon-action-undo reply-icon'></i>
              </div>
              <div class='like-button bubble-ornament hoverable cyan'>
                <i class='icon-like like-icon'></i>
              </div>
              <p class='likes-count'>12 Thumbs Up</p>
            </div>
          </div>
          <div class='post-comment'>
            <figure class='user-avatar medium liquid'>
              <img src='/img/users/07.jpg' alt='user-07' />
            </figure>
            <p class='post-comment-username'>Thomas Stevens</p>
            <p class='post-comment-timestamp'>49 mins ago</p>
            <a href='#' class='report-button'>Report</a>
            <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur.</p>
            <div class='post-comment-actions'>
              <div class='reply-button bubble-ornament blue'>
                <i class='icon-action-undo reply-icon'></i>
              </div>
              <div class='like-button bubble-ornament hoverable cyan'>
                <i class='icon-like like-icon'></i>
              </div>
              <p class='likes-count'>36 Thumbs Up</p>
            </div>
            <div class='post-comment'>
              <figure class='user-avatar medium liquid'>
                <img src='/img/users/06.jpg' alt='user-06' />
              </figure>
              <p class='post-comment-username'>Megan Lebeau</p>
              <p class='post-comment-timestamp'>6 mins ago</p>
              <a href='#' class='report-button'>Report</a>
              <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
              <div class='post-comment-actions'>
                <div class='reply-button bubble-ornament blue'>
                  <i class='icon-action-undo reply-icon'></i>
                </div>
                <div class='like-button bubble-ornament hoverable cyan'>
                  <i class='icon-like like-icon'></i>
                </div>
                <p class='likes-count'>6 Thumbs Up</p>
              </div>
            </div>
          </div>
          <div class='post-comment'>
            <figure class='user-avatar medium liquid'>
              <img src='/img/users/01.jpg' alt='user-01' />
            </figure>
            <p class='post-comment-username text-tag staff'>Dexter Danvers</p>
            <p class='post-comment-timestamp'>2 hours ago</p>
            <a href='#' class='report-button'>Report</a>
            <p class='post-comment-text'>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
              esse cillum dolore eu fugiat nulla pariatur.</p>
            <div class='post-comment-actions'>
              <div class='reply-button bubble-ornament blue'>
                <i class='icon-action-undo reply-icon'></i>
              </div>
              <div class='like-button bubble-ornament hoverable cyan'>
                <i class='icon-like like-icon'></i>
              </div>
              <p class='likes-count'>47 Thumbs Up</p>
            </div>
          </div>
        </div>
        <div class='page-navigation blue right'>
          <div class='slider-control big control-previous'>
            <svg class='arrow-icon medium'>
              <use xlink:href='#svg-arrow-medium'></use>
            </svg>
          </div>
          <a href='#' class='page-navigation-item'>1</a>
          <a href='#' class='page-navigation-item active'>2</a>
          <a href='#' class='page-navigation-item'>3</a>
          <a href='#' class='page-navigation-item'>...</a>
          <a href='#' class='page-navigation-item'>8</a>
          <div class='slider-control big control-next'>
            <svg class='arrow-icon medium'>
              <use xlink:href='#svg-arrow-medium'></use>
            </svg>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(NewsPostComponent);
