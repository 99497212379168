import { gql } from 'glimmer-apollo';

export const ESPORTS_PLAYER_QUERY = gql`
  query ($slug: String) {
    player: playerCollection(where: { slug: $slug }) {
      items {
        irlName
        gamertag
        role
        slug
        socialLinks: socialLinksCollection(limit: 10) {
          items {
            url
            platform
            description: accessibilityDescription
          }
        }
        currentTeam {
          name
          slug
          logo {
            url
          }
          recentMatch: matchesCollection(limit: 1) {
            items {
              title
              scheduledDate
              slug
              victor {
                slug
              }
              rounds: roundsCollection(limit: 10) {
                items {
                  victor {
                    slug
                  }
                }
              }
              teams: teamsCollection(limit: 2) {
                items {
                  name
                  slug
                  logo {
                    url
                  }
                }
              }
            }
          }
          game {
            slug
            title
            logo {
              url
            }
          }
        }
        photo {
          url
        }
      }
    }
  }
`;
