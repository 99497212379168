import { template } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import ResultComponent from '../components/search/result';
import SocialComponent from '../components/widget/sidebar/social';
import PopularPostsComponent from '../components/widget/sidebar/popular-posts';
import TagCloudComponent from '../components/widget/sidebar/tag-cloud';
import LiveNewsTicker from '../components/layout/live-news-ticker';
import PaginationComponent from '../components/layout/pagination';
let SearchRouteComponent = class SearchRouteComponent extends Component {
    @tracked
    searchTerm;
    static{
        template(`
    <div class='banner-wrap forum-banner'>
      <div class='banner grid-limit'>
        <h2 class='banner-title'>Search Results</h2>
        <p class='banner-sections'>
          <span class='banner-section'>Search</span>
          <svg class='arrow-icon'>
            <use href='#svg-arrow'></use>
          </svg>
          <span class='banner-section'>eSports Gaming</span>
        </p>
      </div>
    </div>
    <LiveNewsTicker />
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body'>
        <div class='section-title-wrap blue'>
          <h2 class='section-title medium'>Search Results</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='filter-heading'>
          <p class='filter-heading-text'><span class='highlight'>12</span>
            Results found for: "<span class='highlight'>Esports Japan game team</span>"</p>
        </div>
        <div class='post-preview-showcase grid3-1col centered'>
          <ResultComponent />
        </div>
        <PaginationComponent />
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <SocialComponent />
        <PopularPostsComponent />
        <div class='widget-sidebar'>
          <div class='promo-advert'>
            <a href='#'>
              <img class='promo-advert-img' src='/img/banners/promo-ad-1.jpg' alt='promo-ad-1' />
            </a>
          </div>
        </div>
        <TagCloudComponent />
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(SearchRouteComponent);
