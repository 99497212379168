import Route from '@ember/routing/route';
import { useQuery } from 'glimmer-apollo';
import { NEWS_INDEX_QUERY } from 'embroil/gql/queries/news-index';
import { ESPORTS_INDEX_QUERY } from 'embroil/gql/queries/esports-index';
export default class Post extends Route {
  query = useQuery(this, () => [ESPORTS_INDEX_QUERY]);

  async model() {
    await this.query.promise;
    return this.query.data.articles.items;
  }
}
