import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { formatDate } from '../../../helpers/format-date';
export default class TournamentSidebar extends Component {
    get teamCount() {
        return this.args.tournament.teams?.items.length;
    }
    get showVenueInfo() {
        // TODO: fix venue information and component
        return this.args.tournament.isOnlineOnly && false;
    }
    static{
        template(`
    <div class='layout-sidebar layout-item gutter-medium'>
      <div class='widget-sidebar'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Tournament Info</h2>
          <div class='section-title-separator'></div>
        </div>
        <img class='tournament-banner' src={{@tournament.image.url}} alt='tournament-banner-img' />
        <div class='widget-display'>
          <div class='featured-stat-wrap center'>
            <p class='featured-stat-title'>{{@tournament.title}}</p>
            <p class='featured-stat-text'>{{formatDate @tournament.startDate}} to {{formatDate @tournament.endDate}}</p>
          </div>
        </div>
        <div class='widget-display'>
          <div id='ew3-pgb-tp-01' class='progress-bar captioned small'>
            <p class='progress-bar-text small left'>Instance:</p>
            <p class='progress-bar-text small bold right'>Semifinals</p>
          </div>
        </div>
        <div class='widget-match-box'>
          <div class='widget-simple-stats v2 small'>
            <div class='widget-simple-stat'>
              <div class='widget-simple-stat-header'>
                <p class='widget-simple-stat-header-title'>Information</p>
              </div>
              <div class='widget-simple-stat-rows'>
                <div class='widget-simple-stat-row'>
                  <p class='widget-simple-stat-title light'>Date</p>
                  <p class='widget-simple-stat-value'>{{formatDate @tournament.startDate}}
                    to
                    {{formatDate @tournament.endDate}}</p>
                </div>
                <div class='widget-simple-stat-row'>
                  <p class='widget-simple-stat-title light'>Game</p>
                  <p class='widget-simple-stat-value'>{{@tournament.game.title}}</p>
                </div>
                <div class='widget-simple-stat-row'>
                  <p class='widget-simple-stat-title light'>Teams</p>
                  <p class='widget-simple-stat-value'>{{this.teamCount}}</p>
                </div>
                <div class='widget-simple-stat-row'>
                  <p class='widget-simple-stat-title light'>Prize</p>
                  <p class='widget-simple-stat-value'>{{@tournament.prize}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-sidebar'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Featured Player</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='widget-top-player'>
          <img class='top-player-team-img' src='/img/teams/logos/01.png' alt='team-01' />
          <img class='top-player-img' src='{{@tournament.featuredPlayer.photo.url}}' alt='top-player-2' />
          <img
            class='top-player-team-logo top-right'
            src='{{@tournament.featuredPlayer.currentTeam.logo.url}}'
            alt='top-player-team-logo'
          />
          <div class='top-player-info-wrap'>
            <div class='top-player-info'>
              <p class='top-player-flag featured'>{{@tournament.featuredPlayer.irlName}}</p>
              <p class='top-player-name'>{{@tournament.featuredPlayer.gamertag}}</p>
              <p class='top-player-team'>{{@tournament.featuredPlayer.currentTeam.name}}</p>
            </div>
          </div>
          <div class='top-player-stats'>
            <div class='top-player-stat'>
              <p class='top-player-stat-title'>3.35</p>
              <p class='top-player-stat-text'>kda avg</p>
            </div>
            <div class='top-player-stat'>
              <p class='top-player-stat-title'><span class='highlight'>7.5</span>
                /
                <span class='highlight'>3.6</span>
                /
                <span class='highlight'>4.6</span></p>
              <p class='top-player-stat-text'>kda ratio</p>
            </div>
          </div>
        </div>
        <div class='widget-display multiple-items space-around'>
          <div class='arc-rate-wrap half'>
            <div id='w-arc-wr-1' class='arc rate negative'></div>
            <p class='arc-rate-info'>Win Rate</p>
          </div>
          <div class='arc-rate-wrap half'>
            <div id='w-arc-mid-1' class='arc rate negative'></div>
            <p class='arc-rate-info'>{{@tournament.featuredPlayer.role}}</p>
          </div>
        </div>
        <div class='table ranked-stats'>
          <div class='table-row-header'>
            <div class='table-row-header-item left'>
              <p class='table-row-header-title'>Ranked Stats</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>LP</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>W</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>L</p>
            </div>
          </div>
          <div class='table-rows no-color'>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='rank-img' src='/img/teams/awards/rank-icon.png' alt='rank-icon' />
                  <div class='team-info'>
                    <p class='team-name'>Challenger</p>
                    <p class='team-country'>Rank 1</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold'>1278</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold'>285</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold'>194</p>
              </div>
            </div>
          </div>
        </div>
        <div class='table champion-stats'>
          <div class='table-row-header'>
            <div class='table-row-header-item left'>
              <p class='table-row-header-title'>Fav. Champion</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>W</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>L</p>
            </div>
            <div class='table-row-header-item padded'>
              <p class='table-row-header-title'>KDA</p>
            </div>
            <div class='table-row-header-item'>
              <p class='table-row-header-title'>Win %</p>
            </div>
          </div>
          <div class='table-rows no-color'>
            <div class='table-row'>
              <div class='table-row-item'>
                <div class='team-info-wrap'>
                  <img class='user-avatar character small blue' src='/img/characters/04.png' alt='character-04' />
                  <div class='team-info'>
                    <p class='team-name'>Vanstus</p>
                    <p class='team-country'>223 2 CS</p>
                  </div>
                </div>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold light'>68</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold light'>33</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold'>3.76</p>
              </div>
              <div class='table-row-item'>
                <p class='table-text bold'>67%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {{#if this.showVenueInfo}}
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Venue Info</h2>
            <div class='section-title-separator'></div>
          </div>
          <div id='map-wrap-sd-01' class='map-wrap small'></div>
          <div class='widget-display no-border'>
            <div class='featured-stat-wrap center'>
              <p class='featured-stat-title'>Madison Cube Arena</p>
              <p class='featured-stat-text'>New York, USA</p>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-simple-stats v2 small'>
              <div class='widget-simple-stat'>
                <div class='widget-simple-stat-rows'>
                  <div class='widget-simple-stat-row border-top'>
                    <p class='widget-simple-stat-title light'>Address</p>
                    <p class='widget-simple-stat-value'>156 E44th ST. NY</p>
                  </div>
                  <div class='widget-simple-stat-row'>
                    <p class='widget-simple-stat-title light'>State</p>
                    <p class='widget-simple-stat-value'>New York</p>
                  </div>
                  <div class='widget-simple-stat-row'>
                    <p class='widget-simple-stat-title light'>Country</p>
                    <p class='widget-simple-stat-value'>United States</p>
                  </div>
                  <div class='widget-simple-stat-row'>
                    <p class='widget-simple-stat-title light'>Subway</p>
                    <p class='widget-simple-stat-value'>Penn Station</p>
                  </div>
                  <div class='widget-simple-stat-row'>
                    <p class='widget-simple-stat-title light'>Bus Lines</p>
                    <p class='widget-simple-stat-value'>48A, 36, 74B</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Buy Tickets</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-tickets'>
            <div class='ticket-wrap'>
              <div class='ticket-info'>
                <p class='ticket-title'>Heroes Razz Tournament</p>
                <p class='ticket-text'><span class='bold'>April 4th</span> 8:00PM CPT</p>
              </div>
              <div class='ticket-teams'>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='ticket-teams-text'>vs</p>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>Raging Rhinos</p>
                    <p class='team-country'>South Africa</p>
                  </div>
                </div>
              </div>
              <a href='#' class='ticket-buy-button'>Buy Tickets</a>
              <div class='ticket-corner top left'></div>
              <div class='ticket-corner top right'></div>
              <div class='ticket-corner bottom left'></div>
              <div class='ticket-corner bottom right'></div>
            </div>
            <div class='ticket-wrap'>
              <div class='ticket-info'>
                <p class='ticket-title'>Heroes Razz Tournament</p>
                <p class='ticket-text'><span class='bold'>April 6th</span> 9:30PM CPT</p>
              </div>
              <div class='ticket-teams'>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                  <div class='team-info'>
                    <p class='team-name'>Crimson Pack</p>
                    <p class='team-country'>Japan</p>
                  </div>
                </div>
                <p class='ticket-teams-text'>vs</p>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                  <div class='team-info'>
                    <p class='team-name'>Siberian Tigers</p>
                    <p class='team-country'>Russia</p>
                  </div>
                </div>
              </div>
              <p class='ticket-buy-button sold-out'>Sold Out</p>
              <div class='ticket-corner top left'></div>
              <div class='ticket-corner top right'></div>
              <div class='ticket-corner bottom left'></div>
              <div class='ticket-corner bottom right'></div>
            </div>
            <div class='ticket-wrap'>
              <div class='ticket-info'>
                <p class='ticket-title'>Heroes Razz Tournament</p>
                <p class='ticket-text'><span class='bold'>April 10th</span> 10:00PM CPT</p>
              </div>
              <div class='ticket-teams'>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='ticket-teams-text'>vs</p>
                <div class='team-info-wrap centered tiny'>
                  <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                  <div class='team-info'>
                    <p class='team-name'>Los Toreros</p>
                    <p class='team-country'>Spain</p>
                  </div>
                </div>
              </div>
              <a href='#' class='ticket-buy-button'>Buy Tickets</a>
              <div class='ticket-corner top left'></div>
              <div class='ticket-corner top right'></div>
              <div class='ticket-corner bottom left'></div>
              <div class='ticket-corner bottom right'></div>
            </div>
          </div>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
