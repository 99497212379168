import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
import BannerComponent from '../../components/layout/banner/main';
import LiveNewsTicker from '../../components/layout/live-news-ticker';
import PlayerSocialLinks from '../../components/esports/player/player-social-links';
import { LinkTo } from '@ember/routing';
import { pageTitle } from 'ember-page-title';
let EsportsTeamComponent = class EsportsTeamComponent extends Component {
    static{
        template(`
    {{pageTitle @model.name}}
    <BannerComponent @theme='e-sport' @title='{{@model.name}}' />
    <LiveNewsTicker />
    <div class='layout-content-full v2 grid-limit layout-item gutter-big'>
      <div class='widget-item'>
        <div class='section-title-wrap violet no-space'>
          <h2 class='section-title medium'>Team Overview</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='tab-wrap'>
          <div class='tab-body'>
            <div class='tab-item'>
              <div class='widget-team-overview'>
                <div class='team-overview'>
                  <img class='team-logo big' src='{{@model.logo.url}}' alt='{{@model.nmame}}' />
                  <div class='overview-info-wrap'>
                    <div class='overview-info'>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Name</p>
                        <p class='overview-info-item-text'>{{@model.name}}</p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Country</p>
                        <p class='overview-info-item-text'>
                          <!--<img src='/img/flags/flag-us.png' alt='us-flag' />-->
                          {{@model.country}}
                        </p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Established</p>
                        <p class='overview-info-item-text'>{{@model.establishedDate}}</p>
                      </div>
                    </div>
                    <div class='overview-info'>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Games Played</p>
                        <p class='overview-info-item-text'>156 games</p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Games Won</p>
                        <p class='overview-info-item-text'>131 Victories</p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Games Lost</p>
                        <p class='overview-info-item-text'>25 Losses</p>
                      </div>
                    </div>
                    <div class='overview-info'>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Tournaments</p>
                        <p class='overview-info-item-text'>24</p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>First Places</p>
                        <p class='overview-info-item-text'>4</p>
                      </div>
                      <div class='overview-info-item'>
                        <p class='overview-info-item-title'>Prize Earned</p>
                        <p class='overview-info-item-text'>U\$D 912.804</p>
                      </div>
                    </div>
                  </div>
                  <div class='arc-rate-wrap'>
                    <div id='tov-arc-rate-01' class='arc rate negative'></div>
                    <p class='arc-rate-info'>Win ratio</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='widget-item'>
        <div class='section-title-wrap violet'>
          <h2 class='section-title medium'>Team Roster</h2>
          <div class='section-title-separator'></div>
        </div>
        <div class='widget-player-preview'>
          {{#each @model.players.items as |player|}}
            <div class='player-preview'>
              <LinkTo @route='esports.player' @model={{player.slug}} class='button-more-info'>
                <svg class='plus-cc-icon medium'>
                  <use href='#svg-plus-cc'></use>
                </svg>
              </LinkTo>
              <LinkTo @route='esports.player' @model={{player.slug}}>
                <div class='player-preview-img-wrap'>
                  <img class='player-preview-img' src='{{player.photo.url}}' alt='{{player.gamertag}}' />
                </div>
              </LinkTo>
              <div class='player-preview-info'>
                <p class='player-preview-nickname'>{{player.gamertag}}</p>
                <p class='player-preview-name'>{{player.irlName}} </p>
                <p class='player-preview-name'>{{player.role}} </p>
              </div>
              <div class='social-links centered'>
                <PlayerSocialLinks @links={{player.socialLinks.items}} />
              </div>
            </div>
          {{/each}}
        </div>
      </div>
    </div>
    <div class='layout-content-1 layout-item-3-1 grid-limit'>
      <div class='layout-body layout-item gutter-big'>
        <div class='widget-item'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Xenowatch Standings</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='table standings full'>
            <div class='table-row-header'>
              <div class='table-row-header-item position'>
                <p class='table-row-header-title'>Position</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>Wins</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>Losses</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>Kills</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>Deaths</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>Assists</p>
              </div>
              <div class='table-row-header-item padded'>
                <p class='table-row-header-title'>KDA.R</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Overall Record</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Match Record</p>
              </div>
            </div>
            <div class='table-rows'>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>01</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>24</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>620</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>341</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>1243</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>7.8</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>14 - 2</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>28 - 14</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>02</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>22</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>8</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>590</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>368</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>1164</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>7.1</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>12 - 4</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>27 - 13</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>03</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                    <div class='team-info'>
                      <p class='team-name'>Los Toreros</p>
                      <p class='team-country'>Spain</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>21</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>9</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>543</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>389</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>1043</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6.5</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>11 - 5</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>26 - 11</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>04</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                    <div class='team-info'>
                      <p class='team-name'>Siberian Tigers</p>
                      <p class='team-country'>Russia</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>19</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>11</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>501</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>402</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>996</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6.2</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>9 - 7</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>20 - 19</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>05</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                    <div class='team-info'>
                      <p class='team-name'>Crimson Pack</p>
                      <p class='team-country'>Japan</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>18</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>12</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>433</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>435</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>947</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>5.9</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>8 - 8</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>19 - 21</p>
                </div>
              </div>
            </div>
          </div>
          <div class='table standings mobile'>
            <div class='table-row-header'>
              <div class='table-row-header-item position'>
                <p class='table-row-header-title'>Position</p>
              </div>
              <div class='table-row-header-item padded-big'>
                <p class='table-row-header-title'>W/L</p>
              </div>
              <div class='table-row-header-item padded-big'>
                <p class='table-row-header-title'>K/D/A</p>
              </div>
              <div class='table-row-header-item padded-big'>
                <p class='table-row-header-title'>KDA.R</p>
              </div>
            </div>
            <div class='table-rows'>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>01</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>24 / 6</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>620 / 341 / 1243</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>7.8</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>02</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>22 / 8</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>590 / 368 / 1164</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>7.1</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>03</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                    <div class='team-info'>
                      <p class='team-name'>Los Toreros</p>
                      <p class='team-country'>Spain</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>21 / 9</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>543 / 389 / 1043</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6.5</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>04</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                    <div class='team-info'>
                      <p class='team-name'>Siberian Tigers</p>
                      <p class='team-country'>Russia</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>19 / 11</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>501 / 402 / 996</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>6.2</p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item position'>
                  <p class='table-text'>05</p>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                    <div class='team-info'>
                      <p class='team-name'>Crimson Pack</p>
                      <p class='team-country'>Japan</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>18 / 12</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>433 / 435 / 947</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>5.9</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-item'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Latest Results</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='table matches hoverable'>
            <div class='table-row-header'>
              <div class='table-row-header-item left'>
                <p class='table-row-header-title'>Date</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'></p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'></p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'></p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Tournament</p>
              </div>
              <div class='table-row-header-item'>
                <p class='table-row-header-title'>Video</p>
              </div>
            </div>
            <div class='table-rows'>
              <div class='table-row'>
                <div class='table-row-item'>
                  <p class='table-text bold'>02/15/2018</p>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                    <div class='team-info'>
                      <p class='team-name'>Siberian Tigers</p>
                      <p class='team-country'>Russia</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='match-stats'>
                    <p class='match-score small'>3-5</p>
                    <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap inverted'>
                    <img class='team-logo small' src='/img/teams/logos/03.png' alt='logo-03' />
                    <div class='team-info'>
                      <p class='team-name'>Los Toreros</p>
                      <p class='team-country'>Spain</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>Heroestone League 2018</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text-link popup-watch-video-trigger'>
                    <svg class='play-button-icon tiny'>
                      <use href='#svg-play'></use>
                    </svg>
                    <span class='link-text'>Watch replay</span>
                  </p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <p class='table-text bold'>02/14/2018</p>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='match-stats'>
                    <p class='match-score small'>2-0</p>
                    <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap inverted'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>Xenowatch Tournament 2018</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text-link popup-watch-video-trigger'>
                    <svg class='play-button-icon tiny'>
                      <use href='#svg-play'></use>
                    </svg>
                    <span class='link-text'>Watch replay</span>
                  </p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <p class='table-text bold'>02/08/2018</p>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/05.png' alt='logo-05' />
                    <div class='team-info'>
                      <p class='team-name'>Crimson Pack</p>
                      <p class='team-country'>Japan</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='match-stats'>
                    <p class='match-score small'>1-4</p>
                    <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap inverted'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>SC GO League (Week 3 Day 1)</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text-link popup-watch-video-trigger'>
                    <svg class='play-button-icon tiny'>
                      <use href='#svg-play'></use>
                    </svg>
                    <span class='link-text'>Watch replay</span>
                  </p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <p class='table-text bold'>01/29/2018</p>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/01.png' alt='logo-01' />
                    <div class='team-info'>
                      <p class='team-name'>The Lone Wolves</p>
                      <p class='team-country'>United States</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='match-stats'>
                    <p class='match-score small'>15-8</p>
                    <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap inverted'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>SC GO League (Week 0 Day 6)</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text-link popup-watch-video-trigger'>
                    <svg class='play-button-icon tiny'>
                      <use href='#svg-play'></use>
                    </svg>
                    <span class='link-text'>Watch replay</span>
                  </p>
                </div>
              </div>
              <div class='table-row'>
                <div class='table-row-item'>
                  <p class='table-text bold'>01/26/2018</p>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap'>
                    <img class='team-logo small' src='/img/teams/logos/02.png' alt='logo-02' />
                    <div class='team-info'>
                      <p class='team-name'>Raging Rhinos</p>
                      <p class='team-country'>South Africa</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='match-stats'>
                    <p class='match-score small'>3-2</p>
                    <a href='/esports/matches/match-slug' class='match-link'>Full Stats</a>
                  </div>
                </div>
                <div class='table-row-item'>
                  <div class='team-info-wrap inverted'>
                    <img class='team-logo small' src='/img/teams/logos/04.png' alt='logo-04' />
                    <div class='team-info'>
                      <p class='team-name'>Siberian Tigers</p>
                      <p class='team-country'>Russia</p>
                    </div>
                  </div>
                </div>
                <div class='table-row-item'>
                  <p class='table-text bold'>LOH League Qualifiers 2018</p>
                </div>
                <div class='table-row-item'>
                  <p class='table-text-link popup-watch-video-trigger'>
                    <svg class='play-button-icon tiny'>
                      <use href='#svg-play'></use>
                    </svg>
                    <span class='link-text'>Watch replay</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-item'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Double Bar Infographic</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-title-wrap'>
            <div class='widget-title-item'>
              <div class='dropdown-simple-wrap'>
                <div id='bi-dropdown-01-trigger' class='dp-current-option'>
                  <div id='bi-dropdown-01-option-value' class='dp-current-option-value'>
                    <p class='dp-option-text'>Results 2018</p>
                  </div>
                  <svg class='arrow-icon'>
                    <use href='#svg-arrow'></use>
                  </svg>
                </div>
                <div id='bi-dropdown-01' class='dp-options small'>
                  <div class='dp-option'>
                    <p class='dp-option-text'>Results 2018</p>
                  </div>
                  <div class='dp-option'>
                    <p class='dp-option-text'>Results 2017</p>
                  </div>
                </div>
              </div>
            </div>
            <div class='stats-reference'>
              <div class='stat-reference violet'>
                <p class='stat-reference-text'>Won</p>
              </div>
              <div class='stat-reference cyan'>
                <p class='stat-reference-text'>Lost</p>
              </div>
            </div>
          </div>
          <div class='chart-wrap grid3-1col'>
            <canvas class='chart-bars'></canvas>
          </div>
        </div>
        <div class='widget-item'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Related Articles</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='post-preview-showcase grid-3col centered'>
            <div class='post-preview e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/38.jpg' alt='post-38' />
                  </figure>
                </div>
              </a>
              <a href='esports-news.html' class='tag-ornament'>Esports</a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>Pro Soccer 2017 world league kicked
                off today!</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
            <div class='post-preview e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/50.jpg' alt='post-50' />
                  </figure>
                </div>
              </a>
              <a href='esports-news.html' class='tag-ornament'>Esports</a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>Check out the luxury of The Lone
                Wolves Headquarters</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Morgana</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
            <div class='post-preview e-sport'>
              <a href='/esports/news/esports-news-slug'>
                <div class='post-preview-img-wrap'>
                  <figure class='post-preview-img liquid'>
                    <img src='/img/posts/39.jpg' alt='post-39' />
                  </figure>
                </div>
              </a>
              <a href='esports-news.html' class='tag-ornament'>Esports</a>
              <a href='/esports/news/esports-news-slug' class='post-preview-title'>We had an interview with the great
                Emily Daniels</a>
              <div class='post-author-info-wrap'>
                <p class='post-author-info small light'>By
                  <a href='/search' class='post-author'>Faye V.</a><span class='separator'>|</span>December 15th, 2018</p>
              </div>
              <p class='post-preview-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
          </div>
        </div>
        <div class='layout-item centered load-more-wrap'>
          <a href='esports-news.html' class='button violet'>
            More Related news...
            <div class='button-ornament'>
              <svg class='arrow-icon medium'>
                <use href='#svg-arrow-medium'></use>
              </svg>
              <svg class='cross-icon small'>
                <use href='#svg-cross-small'></use>
              </svg>
            </div>
          </a>
        </div>
      </div>
      <div class='layout-sidebar layout-item gutter-medium'>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Latest Match</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Xenowatch league finals</h6>
              <p class='widget-text'>August 28th, 2018</p>
            </div>
            <div class='widget-team-vs'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>The Lone Wolves</p>
                    <p class='team-country'>United States</p>
                  </div>
                </div>
                <p class='team-score'>12</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>Raging Rhinos</p>
                    <p class='team-country'>South Africa</p>
                  </div>
                </div>
                <p class='team-score'>10</p>
              </div>
            </div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>Match Stats</h6>
              <div class='stats-reference'>
                <div class='stat-reference blue'>
                  <p class='stat-reference-text'>TLW</p>
                </div>
                <div class='stat-reference yellow'>
                  <p class='stat-reference-text'>RRH</p>
                </div>
              </div>
            </div>
            <div class='widget-team-stats bottom-spaced'>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Kills</p>
                <div id='sd-pg-1' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-kills-stat-sd1' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-kills-stat-sd1' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Deaths</p>
                <div id='sd-pg-2' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-deaths-stat-sd2' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-deaths-stat-sd2' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
              <div class='progress-stat-wrap'>
                <p class='bar-progress-info'>Total Assists</p>
                <div id='sd-pg-3' class='progress-bar-wrap'>
                  <div class='bar-progress-wrap left'>
                    <p id='tlw-assists-stat-sd3' class='bar-progress-text'></p>
                  </div>
                  <div class='bar-progress-wrap right'>
                    <p id='rrh-assists-stat-sd3' class='bar-progress-text'></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet small-space'>
            <h2 class='section-title medium'>Next Match</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-match-box'>
            <div class='widget-title-wrap'>
              <h6 class='widget-title'>SC GO League (Week 4 day 3)</h6>
              <p class='widget-text'>04/03/2018</p>
            </div>
            <div class='widget-team-vs v2'>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium'>
                  <img class='team-logo' src='/img/teams/logos/01.png' alt='logo-01' />
                  <div class='team-info'>
                    <p class='team-name'>TLW</p>
                    <p class='team-country'>USA</p>
                  </div>
                </div>
              </div>
              <div class='match-stats'>
                <p class='match-score small'>vs</p>
                <p class='match-time'>10:00PM CPT</p>
              </div>
              <div class='widget-team-vs-item'>
                <div class='team-info-wrap medium inverted'>
                  <img class='team-logo' src='/img/teams/logos/02.png' alt='logo-02' />
                  <div class='team-info'>
                    <p class='team-name'>RRH</p>
                    <p class='team-country'>SOA</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='countdown-text-wrap bordered'>
            <p class='text-heading countdown-text-heading'>Starts In:</p>
            <p id='ew1-cd-text' class='countdown-text'></p>
            <p class='countdown-text-info'>Days<span class='countdown-text-info-separator'>:</span>Hours<span
                class='countdown-text-info-separator'
              >:</span>Min<span class='countdown-text-info-separator'>:</span>Secs</p>
            <div id='ew1-pgb-cd' class='progress-bar'></div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Trophies &amp; Awards</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='widget-slider-wrap'>
            <div id='award-slider' class='widget-slider'>
              <div class='widget-slider-items'>
                <div class='widget-slider-item'>
                  <img class='widget-slider-item-img' src='/img/teams/awards/award-gold.png' alt='award-gold' />
                  <p class='widget-slider-item-title'>Xenowatch League Champions</p>
                  <p class='widget-slider-item-text'>December 2018</p>
                </div>
                <div class='widget-slider-item'>
                  <img class='widget-slider-item-img' src='/img/teams/awards/award-silver.png' alt='award-silver' />
                  <p class='widget-slider-item-title'>SC GO League Champions</p>
                  <p class='widget-slider-item-text'>November 2018</p>
                </div>
              </div>
            </div>
            <div id='award-slider-controls' class='widget-slider-controls slider-controls violet'>
              <div class='slider-control control-previous'>
                <svg class='arrow-icon medium'>
                  <use href='#svg-arrow-medium'></use>
                </svg>
              </div>
              <div class='slider-control control-next'>
                <svg class='arrow-icon medium'>
                  <use href='#svg-arrow-medium'></use>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class='widget-sidebar'>
          <div class='section-title-wrap violet'>
            <h2 class='section-title medium'>Team Bio</h2>
            <div class='section-title-separator'></div>
          </div>
          <div class='bio-items'>
            <div class='bio-item'>
              <p class='bio-item-title'>How did it all start?</p>
              <p class='bio-item-text'>Lorem ipsum dolor sit amet, consectetur bere adipisicing elit, sed do eiusmod
                tempor lorem incididunt ut labore et dolore magna.</p>
            </div>
            <div class='bio-item'>
              <p class='bio-item-title'>The team players</p>
              <p class='bio-item-text'>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur
                aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(EsportsTeamComponent);
