import { gql } from 'glimmer-apollo';

export const GET_WEEWOO = gql`
  query {
    weewooCollection {
      weeWoos: edges {
        weeWoo: node {
          name
          id
          nodeId
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

`query ($slug: String) {
  tournamentCollection(where: {slug: $slug}) {
    items {
      slug
      image {
        url
      }
      startDate
      endDate
      title
      isLive
      liveChatUrl
      liveVideoUrl
      prize
      game {
        title
        slug
        subTitle
      }
      featuredPlayer {
        gamertag
        irlName
        slug
        role
        photo {
          url(transform: {width: 90})
        }
        currentTeam {
          name
          logo {
            url
          }
        }
      }
      matchesCollection(limit: 30) {
        items {
          slug
          title
        }
      }
      teams: teamsCollection(limit: 16) {
        items {
          slug
          name
          logo {
            url
          }
        }
      }
    }
  }
}`;
