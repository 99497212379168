import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import RouteTemplate from 'ember-route-template';
let PrivacyPolicyComponent = class PrivacyPolicyComponent extends Component {
    static{
        template(`
    <div class='layout-content-1 layout-item-3-1 search-pad grid-limit'>
      <div class='layout-body layout-item centered'>
        <h1>Terms and Conditions</h1>
        <p><strong>Last updated January 01, 2024</strong></p>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
};
export default RouteTemplate(PrivacyPolicyComponent);
