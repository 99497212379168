import { template } from "@ember/template-compiler";
import { LinkTo } from '@ember/routing';
import { formatDate } from '../../../helpers/format-date';
const eq = (a1, b1)=>a1 === b1;
const articleType = (type1)=>{
    if (type1 === 'ESportsArticle') {
        return 'esports';
    }
    if (type1 === 'GameArticle') {
        return 'news';
    }
};
export default template(`
  <div class='grid3-1col centered gutter-big grid-limit'>
    <div class='widget-news'>
      <div class='section-title-wrap violet'>
        <h2 class='section-title medium'>Related News</h2>
        <div class='section-title-separator'></div>
      </div>
      <div class='post-preview-showcase grid-3col centered'>
        {{#each @articles as |article|}}
          <div
            class='post-preview
              {{if (eq (articleType article.__typename) "esports") "e-sport"}}
              {{if (eq (articleType article.__typename) "news") "gaming-news"}}'
          >
            <LinkTo @route='{{articleType article.__typename}}.post' @model={{article.slug}}>
              <div class='post-preview-img-wrap'>
                <figure class='post-preview-img liquid'>
                  <img src='{{article.coverImage.url}}' alt='{{article.title}}' />
                </figure>
              </div>
            </LinkTo>
            {{#if (eq (articleType article.__typename) 'esports')}}
              <LinkTo @route='esports' class='tag-ornament'>Esports</LinkTo>
            {{else}}
              <LinkTo @route='news.index' class='tag-ornament'>Gaming News</LinkTo>
            {{/if}}
            <LinkTo
              @route='{{articleType article.__typename}}.post'
              @model='{{article.slug}}'
              class='post-preview-title'
            >
              {{article.title}}
            </LinkTo>
            <div class='post-author-info-wrap'>
              <p class='post-author-info small light'>
                By
                <a href='/search' class='post-author'>
                  {{article.author.displayName}}
                </a>
                <span class='separator'>|</span>
                {{formatDate article.publishedDate}}
              </p>
            </div>
            <p class='post-preview-text'>{{article.shortDescription}}</p>
          </div>
        {{/each}}
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
