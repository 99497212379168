import { template } from "@ember/template-compiler";
export default template(`
  <div class='widget-sidebar'>
    <div class='section-title-wrap red'>
      <h2 class='section-title medium'>Latest Reviews</h2>
      <div class='section-title-separator'></div>
    </div>
    <div class='post-preview-showcase grid-1col centered gutter-small'>
      <div class='post-preview tiny game-review'>
        <a href='/reviews/review-article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/16.jpg' alt='post-16' />
            </figure>
            <div class='review-rating'>
              <div id='sidebar-rate-2' class='arc tiny'></div>
            </div>
          </div>
        </a>
        <a href='/reviews/review-article-slug' class='post-preview-title'>We reviewed the new Magimons game</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Vellatrix</a>
            <span class='separator'>|</span>Dec 15th, 2018
          </p>
        </div>
      </div>
      <div class='post-preview tiny game-review'>
        <a href='/reviews/review-article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/25.jpg' alt='post-25' />
            </figure>
            <div class='review-rating'>
              <div id='sidebar-rate-3' class='arc tiny'></div>
            </div>
          </div>
        </a>
        <a href='/reviews/review-article-slug' class='post-preview-title'>"Legend of Kenshii II" is a bit green for now</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Vellatrix</a>
            <span class='separator'>|</span>Dec 15th, 2018
          </p>
        </div>
      </div>
      <div class='post-preview tiny game-review'><a href='/reviews/review-article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/05.jpg' alt='post-05' />
            </figure>
            <div class='review-rating'>
              <div id='sidebar-rate-4' class='arc tiny'></div>
            </div>
          </div>
        </a>
        <a href='/reviews/review-article-slug' class='post-preview-title'>We reviewed the new and exciting fantasy game
          "Olympus"</a>
        <div class='post-author-info-wrap'><p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Morgana</a><span class='separator'>|</span>Dec 15th, 2018</p></div>
      </div>
      <div class='post-preview tiny game-review'><a href='/reviews/review-article-slug'>
          <div class='post-preview-img-wrap'>
            <figure class='post-preview-img liquid'>
              <img src='/img/posts/08.jpg' alt='post-08' />
            </figure>
            <div class='review-rating'>
              <div id='sidebar-rate-5' class='arc tiny'></div>
            </div>
          </div>
        </a>
        <a href='/reviews/review-article-slug' class='post-preview-title'>The new Mecha Cyborg game is breaking barriers</a>
        <div class='post-author-info-wrap'>
          <p class='post-author-info small light'>By
            <a href='/search' class='post-author'>Vellatrix</a>
            <span class='separator'>|</span>Dec 15th,
          </p>
        </div>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
